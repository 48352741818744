<template>
  <div class="payment-processing">
    <div class="payment-processing__loading">
      <loading-spinner />
      {{ getZettleMessage }}
    </div>
    <p>
      Payment Terminal app didn't open?
      <a :href="getZettleIntentURL">Click this link to open the app manually.</a>
    </p>
    <span
      class="cancel"
      @click="cancelPaymentProcessing"
    >Cancel</span>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getZettleIntentURL: "payment/getIntentURL",
      getZettleMessage: "payment/getZettleMessage"
    })
  },
  methods: {
    cancelPaymentProcessing() {
      store.dispatch("payment/cancelZettlePayment");
    }
  }
}
</script>

<style lang="scss">
.payment-processing {
  position: fixed;
  @include flex($dir: column);
  height: 250px;
  padding: 1rem;
  text-align: center;
  z-index: 999;
  right: 0;
  bottom: 0;
  background: white;
  min-width: $tab_width;
  width: inherit;
  box-shadow: $login_form_box_shadow;

  &__loading {
    @include flex($g: 1rem);
    font-weight: bold;
    font-size: 1.2rem;

    .loading-spinner {
      border-width: 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }

  a {
    display: block;
    color: $col_gamma;
  }

  .cancel {
    color: gray;
  }
}
</style>