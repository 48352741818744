<template>
  <div class="checkout-outer">
    <button class="back-nav" @click="$emit('closeCheckout')">
      <Icon :path="mdiArrowLeft" /> Back
    </button>
    <div class="checkout-inner">
      <div class="cart-section" :class="{ expand: ifExpandCartItemsTable }">
        <div class="cart-section_title row">
          <h2 class="cart-items">Cart Items ({{ getCartItemsCount }})</h2>
          <div v-if="getCartItemsCount != 0" class="total">
            <h4>Total</h4>
            <h3>{{ formatMoney(getCartSummary.total) }}</h3>
          </div>
        </div>
        <CartSummary
          v-if="ifExpandCartItemsTable"
          @uncheckDonation="donation = false"
          :checkoutScreen="true"
          :readOnly="!ifExpandCartItemsTable" />
      </div>
      <div class="checkout-section" :class="{ shrink: ifExpandCartItemsTable }">
        <Icon
          v-if="!ifExpandCartItemsTable"
          :path="mdiChevronDown"
          class="toggle-section"
          @click="this.ifExpandCartItemsTable = !this.ifExpandCartItemsTable" />
        <Icon
          v-else
          :path="mdiChevronUp"
          class="toggle-section"
          @click="this.ifExpandCartItemsTable = !this.ifExpandCartItemsTable" />

        <div
          class="on-premises"
          v-if="
            service.allowEatOnPremises &&
            this.channel != 'Delivery' &&
            this.channel != 'TableService'
          ">
          <div class="on-premises__buttons">
            <button
              :disabled="eatOnPremises"
              :class="{ active: eatOnPremises }"
              @click="updateEatOnPremises(true)">
              <span class="icon">
                <Icon :path="mdiFoodForkDrink" />
                <Icon
                  :path="mdiCheckCircle"
                  v-if="eatOnPremises"
                  :size="16"
                  :class="{ active: eatOnPremises }" />
              </span>
              Eat In
            </button>
            <button
              :disabled="!eatOnPremises"
              :class="{ active: !eatOnPremises }"
              @click="updateEatOnPremises(false)">
              Take Out
              <span class="icon">
                <Icon :path="mdiFoodTakeoutBox" />
                <Icon
                  :path="mdiCheckCircle"
                  v-if="!eatOnPremises"
                  :size="16"
                  :class="{ active: !eatOnPremises }" />
              </span>
            </button>
          </div>
        </div>

        <div
          class="promotion-input-wrapper"
          v-if="!getCartSummary?.promotionVouchers?.amount">
          <div class="input-wrapper">
            <label for="promoCode">Voucher</label>
            <input
              type="text"
              id="voucherCode"
              v-model="voucherCode"
              :disabled="getCartLockByPaymentStatus"
              v-on:keyup.enter="$event.target.blur()" />
            <button
              :disabled="
                getCartSummary?.promotionVouchers?.amount > 0 ||
                !this.voucherCode ||
                getCartLockByPaymentStatus
              "
              @click="applyVoucher">
              {{ getVoucherButtonWording }}
            </button>
          </div>
        </div>

        <!--Promotion-->

        <div v-if="loadingPromo" class="loading-promo"><loading-spinner /></div>

        <div
          class="promotions-shortcuts__wrapper"
          v-else-if="
            !loadingPromo &&
            this.promotions?.length > 0 &&
            !getCartSummary?.promotionVouchers?.amount
          ">
          <div
            class="promotions-shortcuts__promotion"
            v-for="promotion in this.promotions"
            :key="promotion.id">
            <span class="promotions-shortcuts__promotion__name">
              {{ promotion.name }}
            </span>

            <button
              class="outlined primary promotions-shortcuts__promotion__voucher"
              v-for="voucher in promotion.vouchers"
              :key="voucher.code"
              :disabled="
                getCartSummary?.promotionVouchers?.amount > 0 ||
                !getQueueIsEnd ||
                getCartLockByPaymentStatus
              "
              @click="quickApplyVoucher(voucher.code)">
              <span class="promotions-shortcuts__promotion__voucher__name">
                {{ voucher.name }}
              </span>
            </button>
          </div>
        </div>

        <p
          class="discount-applied"
          v-if="getCartSummary?.promotionVouchers?.amount > 0">
          <Icon :path="mdiTag" :size="22" />
          Discount Applied -
          {{ formatMoney(getCartSummary?.promotionVouchers?.amount) }}!
          <span @click="removePromotion">remove</span>
        </p>

        <!--Applicable Channels-->

        <div
          class="channel-timing"
          v-if="!loadingChannels && this.applicableChannelCount > 1">
          <button
            class="btn_channel contained"
            :class="{ active: this.channel == 'WalkUp' }"
            :disabled="ifAnyDataLoading"
            @click="
              this.channel = '';
              this.updateChannel(APIORDER_CHANNEL.WALK_UP);
            ">
            <Icon :path="mdiFood" :size="20" />
            <span class="btn_channel__text">Eat Now</span>
          </button>
          <button
            class="btn_channel contained"
            :class="{ active: this.channel == 'Phone' }"
            :disabled="ifAnyDataLoading"
            @click="
              this.channel = '';
              this.updateChannel(APIORDER_CHANNEL.PHONE);
            ">
            <Icon :path="mdiPhone" :size="20" />
            <span class="btn_channel__text">Phone</span>
          </button>
        </div>

        <div class="ready-time" v-if="this.channel == 'WalkUp'">
          <h4><Icon :path="mdiShoppingOutline" :size="16" /> Ready For:</h4>
          <strong>{{ formatTime(this.getOrderReadyEndTime()) }}</strong>
        </div>

        <div class="phone-timing" v-if="this.channel == 'Phone'">
          <label for="order-time"
            >When does the customer want to collect the order?</label
          >
          <DropDown
            id="order-time"
            :options="this.slotDrops"
            v-model="selectedSlot" />
          <span class="phone-timing__warning">
            <Icon :path="mdiAlertDecagram" />
            The customer will need to pay for this order on collection.
          </span>
        </div>

        <div
          v-if="getCartItemsCount != 0"
          class="accordion-item"
          :class="{ 'is-active': optionsOpen }">
          <div class="accordion-item-title">
            <div
              @click="optionsOpen = !optionsOpen"
              class="accordion-item-trigger">
              <h4 class="accordion-item-title-text">
                <Icon :path="mdiFaceMan" :size="16" />Customer details
              </h4>
              <span class="accordion-item-trigger-icon"></span>
            </div>
          </div>
          <transition
            name="accordion-item"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition">
            <div v-if="optionsOpen" class="accordion-item-details">
              <dd>
                <div class="accordion-item-details-inner">
                  <div class="input-wrapper">
                    <label for="name"
                      >Name <ErrorMessage class="error-message" name="name"
                    /></label>
                    <Field
                      name="name"
                      type="text"
                      id="name"
                      v-model="customerName" />
                  </div>

                  <div class="input-wrapper">
                    <label for="email"
                      >Email <ErrorMessage class="error-message" name="email"
                    /></label>
                    <Field
                      name="email"
                      type="email"
                      id="email"
                      v-model="customerEmail" />
                  </div>

                  <div class="input-wrapper">
                    <label for="phone"
                      >Phone <ErrorMessage class="error-message" name="phone"
                    /></label>
                    <Field
                      name="phone"
                      type="tel"
                      id="phone"
                      v-model="customerPhone" />
                  </div>
                </div>
              </dd>

              <div
                class="donation"
                v-if="
                  this.service?.charity?.id > 0 &&
                  (getCartTotal?.charityDonations?.amount ?? 0) <= 0 &&
                  optionsOpen
                ">
                <div class="checkbox">
                  <input
                    v-model="donation"
                    type="checkbox"
                    name="donation"
                    id="donation"
                    @change="updateDonation" />
                </div>
                <label for="donation"
                  >Make a one-time donation of
                  <strong>{{ this.formatMoney(this.donationAmount) }}</strong>
                  to <strong>{{ this.charity.name }}</strong
                  >?
                </label>
              </div>
            </div>
          </transition>
        </div>
        <NoteTextarea @update="updateNote" @blur="storeNote" />
      </div>
      <div
        class="payment-methods pinned-bottom-buttons"
        v-if="getCartItemsCount > 0">
        <button
          v-if="this.getCartConfirmStatus"
          class="btn_confirm"
          @click="this.channel == 'WalkUp' ? checkoutConfirm() : phoneConfirm()"
          :disabled="ifAnyDataLoading || confirmingCart">
          <loading-spinner v-if="confirmingCart" />
          <template v-else>
            <Icon :path="mdiCheckCircleOutline" />
            Confirm
          </template>
        </button>
        <template v-else>
          <button
            v-if="serviceOptions?.quickCheckout"
            class="btn_confirm"
            @click="handlePayNow"
            :disabled="ifAnyDataLoading">
            <span class="flex">
              <Icon :path="mdiCreditCard" />
            </span>
            Pay Now
          </button>
          <button
            class="btn_confirm"
            @click="takePayment()"
            :disabled="ifAnyDataLoading">
            <span class="flex">
              <Icon :path="mdiCreditCard" />
              <Icon :path="mdiCash" />
            </span>
            <template v-if="serviceOptions?.quickCheckout"
              >Other Payments</template
            >
            <template v-else>Take Payment</template>
          </button>
        </template>
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showPayments"
          class="modal"
          :maxWidth="'900'"
          @close="showPayments = false">
          <Payments
            :order="this.getCart"
            :order-is-cart="true"
            @close="showPayments = false"
            @fullyFunded="fullyFunded" />
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters, mapState } from "vuex";
  import { roundMinutes, DateTimeRounding } from "@tucktrucks/core";
  import { LocalTime } from "@js-joda/core";
  import CartSummary from "@/components/CartSummary";
  import DropDown from "@/components/DropDown";
  import ModalDialog from "@/components/ModalDialog";
  import Payments from "@/components/Orders/Payments/Payments";
  import NoteTextarea from "@/components/Walkups/NoteTextarea";
  import { APIORDER_CHANNEL } from "@tucktrucks/platform-base-public";
  import {
    mdiArrowLeft,
    mdiCreditCard,
    mdiCash,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiAlertCircleOutline,
    mdiPhone,
    mdiAlertDecagram,
    mdiFood,
    mdiChevronDown,
    mdiChevronUp,
    mdiTag,
    mdiFoodForkDrink,
    mdiFoodTakeoutBox,
    mdiFaceMan,
    mdiShoppingOutline,
  } from "@mdi/js";

  export default {
    data() {
      return {
        eventId: this.$route.params.eventId,
        serviceId: this.$route.params.serviceId,
        service: {},
        channel: "",
        note: null,
        slots: [],
        slotDrops: [],
        promotions: [],
        loading: false,
        loadingPromo: true,
        loadingChannels: true,
        confirmingCart: false,
        optionsOpen: true,
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        charity: {},
        donation: false,
        donationAmount: 0.5,
        showModal: null,
        voucherCode: "", // The code we send up
        voucher: null, // The voucher details we get back
        cashTaken: "",
        orderReadyTimeRange: null,
        showPayments: false,
        ifExpandCartItemsTable: false,
        job: null,
        intentJob: null,
        selectedSlot: 0,
        takingPayment: false,
        applicableChannelCount: 0,
        eatOnPremises: false,
        APIORDER_CHANNEL,
        mdiArrowLeft,
        mdiCreditCard,
        mdiCash,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiPhone,
        mdiAlertDecagram,
        mdiChevronUp,
        mdiChevronDown,
        mdiTag,
        mdiFood,
        mdiFoodForkDrink,
        mdiFoodTakeoutBox,
        mdiCheckCircle,
        mdiFaceMan,
        mdiShoppingOutline,
      };
    },

    watch: {
      ifCartConfirmed(newValue) {
        if (newValue) {
          this.showModal = null;
          this.$emit("closeCheckout");
          if (this.mobileLayout) {
            this.$emit("swapView");
          }
        }
      },
      customerEmail() {
        this.updateCustomer({ localUpdate: true });
      },
      customerName() {
        this.updateCustomer({ localUpdate: true });
      },
      customerPhone() {
        this.updateCustomer({ localUpdate: true });
      },
    },

    emits: ["swapView", "closeCheckout"],

    props: {
      serviceOptions: {
        type: Object,
      },
      mobileLayout: {
        type: Boolean,
      },
    },

    components: {
      CartSummary,
      DropDown,
      ModalDialog,
      Payments,
      NoteTextarea,
    },

    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getQueueIsEnd: "cart/getQueueIsEnd",
        getCartItems: "cart/getItems",
        getCartItemsCount: "cart/getItemsCount",
        getCartSubTotal: "cart/getSubTotal",
        getCartTotal: "cart/getTotal",
        getCartSummary: "cart/getSummary",
        getCartId: "cart/getCartId",
        getCartKey: "cart/getCartKey",
        getCustomer: "cart/getCustomer",
        getService: "repoServices/getById",
        getCartLockByPaymentStatus: "payment/getLock",
        getZettleIntent: "payment/getIntent",
      }),

      ...mapState({ ifCartConfirmed: (state) => state.cart.cartConfirmed }),

      getCartConfirmStatus() {
        if (this.channel == "Phone") return true;
        if (this.getCartSummary.total <= 0) return true;
        if (!this.getCartSummary.paymentPending) return true;

        return false;
      },

      getCashTaken() {
        var number = parseInt(this.cashTaken);

        if (isNaN(number) || number == null) {
          return 0;
        }

        return number * 0.01;
      },

      getChannelCount() {
        if (this.service?.channels == null) {
          return 0;
        }

        return this.service.channels.length;
      },

      getItemCount() {
        return this.itemsCount;
      },

      getDishesCount() {
        return this.dishesCount;
      },

      getCartTotal() {
        return this.formatMoney(this.getCartSummary.total);
      },

      getCartSubTotal() {
        return this.formatMoney(this.getCartSummary.subTotal);
      },

      getName(id) {
        return store.getters["cart/getName"](id);
      },

      hasOrder() {
        return store.state.cart.cart.hasOrder();
      },

      hasDonation() {
        return this.getCartSummary?.charityDonations?.amount ? true : false;
      },

      getVoucherButtonWording() {
        return this.getCartSummary?.promotionVouchers?.amount > 0
          ? "Applied!"
          : "Apply";
      },

      ifAnyDataLoading() {
        return this.getCartLockByPaymentStatus || !this.getQueueIsEnd;
      },
    },

    methods: {
      updateNote(note) {
        this.note = note;
      },
      storeNote(note) {
        this.note = note;

        if (this.note != null)
          store.state.apiPublic.client.endpoints.cartNotes.update(
            note,
            this.getCartId,
            this.getCartKey
          );
      },

      startTransition(el) {
        el.style.height = el.scrollHeight + "px";
      },

      endTransition(el) {
        el.style.height = "";
      },

      clear() {
        store.dispatch("cart/clear");
      },

      updateDonation() {
        store.dispatch("cart/upsertDonation", {
          charityId: 1,
          amount: this.donation === true ? 0.5 : 0,
        });
      },

      updateEatOnPremises(eatOnPremises) {
        store
          .dispatch("cart/updateEatOnPremises", eatOnPremises)
          .then((result) => {
            this.eatOnPremises = result;
          });
      },

      applyVoucher() {
        // Installed vue-debounce, just needs enabling so this applies auto after x ms
        store
          .dispatch("cart/upsertVoucher", this.voucherCode)
          .catch((error) => {
            window.log.error(error);
          });
      },

      quickApplyVoucher(voucherCode) {
        store.dispatch("cart/upsertVoucher", voucherCode).catch((error) => {
          window.log.error(error);
        });
      },

      async takePayment() {
        this.updateCustomer({ localUpdate: false });

        if (this.note != null)
          await store.state.apiPublic.client.endpoints.cartNotes.update(
            this.note,
            this.getCartId,
            this.getCartKey
          );

        this.showPayments = true;
      },

      updateCustomer({ localUpdate }) {
        store.dispatch("cart/updateCustomer", {
          name: this.customerName,
          email: this.customerEmail,
          phone: this.customerPhone,
          localUpdate,
        });
      },

      async phoneConfirm() {
        this.confirmingCart = true;
        await this.updateSelectedSlot();
        await this.updateCustomerDetails();
        this.confirm();
      },

      async checkoutConfirm() {
        this.confirmingCart = true;
        await this.updateCustomerDetails();
        this.confirm();
      },

      fullyFunded() {
        this.confirm();
      },

      removePromotion() {
        if (
          !this.getCartSummary?.promotionVouchers?.id &&
          this.getCartSummary?.promotionVouchers?.id !== 0
        )
          return;

        store.dispatch(
          "cart/deleteVoucher",
          this.getCartSummary?.promotionVouchers.id
        );
      },

      async confirm() {
        await store.dispatch("cart/confirm");
        this.confirmingCart = false;
      },

      async updateCustomerDetails() {
        let customer = {
          name: this.customerName,
          email: this.customerEmail,
          phone: this.customerPhone,
          optedInMarketing: this.optedInMarketing,
        };

        return store.dispatch("cart/updateCustomer", customer);
      },

      async updateSelectedSlot() {
        if (!this.slots) return;

        let slot = this.slots[this.selectedSlot].range.timeEnd;

        return store.dispatch("cart/updateSelectedSlot", slot);
      },

      async updateChannel(channel) {
        return store.dispatch("cart/updateChannel", channel).then(() => {
          this.channel = channel;
        });
      },

      getOrderReadyEndTime() {
        try {
          return roundMinutes(
            LocalTime.parse(this.orderReadyTimeRange.timeEnd),
            10,
            DateTimeRounding.Forwards
          );
        } catch (ex) {
          return null;
        }
      },

      handlePayNow() {
        this.updateCustomer({ localUpdate: false });
        this.takingPayment = true;

        // By setting current intent, we hide the payments and show the zettle integration control
        store.dispatch("payment/createCartZettlePayment", {
          amount: this.getCartSummary.total,
          id: this.getCartId,
          key: this.getCartKey,
        });
      },

      getApplicableChannelCount() {
        if (this.service?.channels == null) {
          this.loadingChannels = false;
          return 0;
        }

        const applicableChannels = this.service.channels.filter(
          (channel) =>
            channel.name == APIORDER_CHANNEL.WALK_UP ||
            channel.name == APIORDER_CHANNEL.PHONE
        );

        this.applicableChannelCount = applicableChannels.length;
        this.loadingChannels = false;
      },
    },

    async mounted() {
      this.donationA = this.hasDonation;

      //this.charity = await store.getters["repoCharities/getById"](1);

      // Get customer data so won't lose customer details after modifying orders
      this.customerEmail = this.getCustomer?.email;
      this.customerName = this.getCustomer?.name;
      this.customerPhone = this.getCustomer?.phone;

      this.job = window.setIntervalAndExecute(() => {
        const cartId = this.getCartId;

        if (!cartId || cartId == 0) {
          return;
        }

        // ---------------------------------
        // What the hell is this nonsense.
        this.channel = this.getCart.summary.channel
          .replace("-", "")
          .replace(" ", "");
        // ---------------------------------

        this.eatOnPremises = this.getCart.summary.eatOnPremises;

        this.getService(this.serviceId)
          .then((service) => {
            this.service = service;
          })
          .then(() => {
            this.getApplicableChannelCount();
          });

        store.state.apiPublic.client.endpoints.services
          .getSlots(this.serviceId, cartId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((slots) => {
            this.slots = slots;
            this.slotDrops = this.slots?.map((slot, index) => {
              return { id: index, name: slot.range.timeEnd };
            });
          });

        store.state.apiPrivate.client.endpoints.services
          .getPromotions(this.serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((data) => {
            this.promotions = data;
            this.loadingPromo = false;
          });

        store.state.apiPublic.client.endpoints.services
          .getNextTimeforCart(this.serviceId, cartId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            window.log.warn(
              `Could not determine ready time using service ${this.serviceId} for cart ${cartId}.`
            );

            this.orderReadyTimeRange = null;
          })
          .then((time) => {
            this.orderReadyTimeRange = time;
          });
      }, 20000);
    },

    unmounted() {
      if (this.job != null) {
        window.clearInterval(this.job);
      }
    },
  };
</script>

<style lang="scss">
  .modal-container {
    padding: 0;

    height: 95%;
    max-height: 840px;
  }

  .back-nav {
    @include button-base($p: 0.5rem, $h: 3rem, $w: fit-content);
    border: 0;
    color: gray;

    &:hover,
    &:visited {
      background: transparent;
    }
  }

  .checkout-outer {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    background: white;
  }

  .checkout-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: calc(100% - $topbar_height);
    flex: 1;

    > * {
      flex: 1;
    }

    .cart-section {
      display: flex;
      flex-direction: column;
      flex: 1;

      &.expand {
        flex: 2;
      }

      &_title {
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: white;
        h2 {
          margin: 0.5rem 0;
          font-size: 1rem;
        }
      }

      .cart-summary {
        max-height: unset;
      }
    }

    .checkout-section {
      box-shadow: $login_form_box_shadow;
      height: calc(100% - 50px);
      max-height: calc(100% - 50px);
      transition: 0.5s;
      overflow: auto;
      position: relative;
      flex: 20;
      padding-bottom: calc($button_height + 1rem);
      @include flex($dir: column, $jc: flex-start, $wrap: nowrap);

      > * {
        width: 100%;
      }

      &.shrink {
        max-height: 20%;
        min-height: 200px;
        overflow: auto;
        flex: 1;
      }

      .toggle-section {
        position: sticky;
        top: 0;
        width: 100%;
        height: 40px;
        width: 100%;
        background: white;
        z-index: 2;
        flex-shrink: 0;
      }

      .item-note {
        padding: 0.5rem;

        textarea {
          height: 150px;
        }

        .characters-left {
          bottom: 1.25rem;
          right: 0.75rem;
        }
      }
    }

    .payment-methods {
      flex: 0;
      background: white;
      padding-bottom: 0.5rem;
      position: relative;
      z-index: 3;
    }

    .accordion-item-details-inner {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 1rem 0;
    }

    table {
      padding: 0;
      margin-top: 0;

      tr {
        width: 100%;
        border-bottom: 1px solid $table_border_color;

        &:last-child {
          border-bottom: 0;
        }

        td {
          &.cart-item-name {
            width: 200px;
          }
          &.cart_price {
            width: 90px;
          }
          &:first-child {
            padding-left: 1rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }

    .input-wrapper {
      flex-direction: row;
      align-items: center;
      display: flex;
      gap: 0.5rem;

      label {
        width: 70px;
        font-size: 0.875rem;
        flex-shrink: 0;
      }

      input {
        margin: 0;
      }
    }

    .on-premises {
      display: block;
      padding: 0.5rem;

      &__buttons {
        display: flex;
        width: 100%;

        button {
          @include outlined-button($p: 0.5rem, $h: 3rem, $g: 0.25rem);
          flex-grow: 1;

          span.icon {
            position: relative;
            display: block;
            .active {
              position: absolute;
              color: $col_alpha-lighter-2;
              border-radius: 50%;
              background: white;
              bottom: -15%;
              right: -15%;
            }
          }

          &:first-child {
            border-radius: 32px 0 0 32px;
            border-right: 0 !important;
            .icon {
              .active {
                left: -25%;
                right: unset;
              }
            }
          }

          &:last-child {
            border-radius: 0 32px 32px 0;
          }
        }
      }
    }

    .promotion-input-wrapper {
      display: block;
      padding: 0 0.5em;

      .input-wrapper {
        padding-top: 0.75rem;
        border-top: 1px solid $col_disabled;
      }

      .error {
        color: $col_beta-darker;
        width: 100%;
        font-size: 0.875rem;
      }

      button {
        @include contained-button($h: 2rem, $fs: 0.875rem);
      }
    }
  }

  .channel-timing {
    display: flex;
    padding: 0.5rem;
    width: 100%;
    align-content: center;
    gap: 0.5rem;

    .btn_channel {
      flex-grow: 1;
      color: $col_beta;
      @include outlined-button(
        $brWid: 2px,
        $brCol: $col_disabled_gray,
        $p: 0.5rem,
        $h: 3rem
      );
      background-color: white;

      &.active {
        color: $col_black;
        border-color: $col_beta;
      }

      &:disabled {
        border: 0 !important;
      }
    }
  }

  .phone-timing {
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    label {
      font-size: 0.7em;
      color: $col_faded;
    }

    &__warning {
      border: 1px solid #f9ca24;
      background: #f6e58d;
      border-radius: 8px;
      padding: 0.5em;
      font-size: 0.9em;

      .mdi-icon {
        float: left;
        margin: 0.6em;
      }
    }
  }

  .donation {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem 1rem;
    gap: 0.5rem;

    p {
      margin-bottom: 0;
    }
    input[type="checkbox"] {
      margin-right: 1rem;
    }
  }

  .error-message {
    color: rgb(192, 3, 3);

    &.block {
      display: block;
    }
  }

  button.take-payment {
    .loading-spinner {
      border: 0.5em solid #aeaeae;
      border-top-color: rgba(255, 255, 255, 0);
    }
  }

  .service-selection {
    display: flex;
    flex-direction: row;

    &_item {
      padding: 1rem;
      background: $col_white;
      cursor: pointer;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      text-align: center;

      &.active {
        background: $col_beta;
      }
    }
  }

  // Accordion styles

  .accordion-item-trigger {
    //padding: 0.75rem 1.25rem;
    cursor: pointer;
  }

  .accordion-item-title {
    position: relative;

    .accordion-item-title-text {
      font-size: 1rem;
      margin: 0.25rem 0;
      padding-right: 1.25rem;
      padding-left: 0.5rem;
      @include flex($jc: flex-start);
    }
  }

  .accordion-item-trigger {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
  }

  .accordion-item-trigger-icon {
    $size: 8px;
    display: block;
    position: absolute;
    top: 0;
    right: 1.25rem;
    bottom: 0;
    margin: auto;
    width: $size;
    height: $size;
    border-right: 2px solid #363636;
    border-bottom: 2px solid #363636;
    transform: translateY($size * 0.25) rotate(45deg);
    transition: transform 0.2s ease;

    .is-active & {
      transform: translateY($size * 0.25) rotate(225deg);
    }
  }

  .accordion-item-details {
    overflow: hidden;
    margin: 0;

    dd {
      margin: 0;
    }
  }

  .accordion-item-enter-active,
  .accordion-item-leave-active {
    transition: height 0.2s ease;
  }
  .accordion-item-enter-from,
  .accordion-item-leave-to {
    height: 0 !important;
  }

  .ready-time {
    background: $col_beta-lightest;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    font-size: 1.2rem;
    @include flex($jc: space-between);

    h4 {
      @include flex($jc: flex-start);
      width: fit-content;
      margin: unset;
    }

    strong {
      float: right;
    }
  }

  .promotions-shortcuts {
    &__wrapper {
      @include flex($dir: column, $jc: space-between);
      width: 100%;
      margin: 0.5rem 0;
    }

    &__promotion {
      @include flex($jc: unset);
      width: 100%;
      padding: 0 0.5rem;

      &__name {
        font-size: 0.9rem;
        flex-grow: 1;
      }

      &__voucher {
        @include outlined-button(
          $fs: 1.3rem,
          $brWid: 1px,
          $p: 0.5rem,
          $h: 2.5rem,
          $w: auto,
          $col: $col_beta-darker
        );
        flex-wrap: wrap;
        font-weight: 600;

        &__name {
          font-size: 0.785rem;
        }
      }
    }
  }

  .discount-applied {
    margin-bottom: 1rem;
    color: $col_alpha-lighter-2;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    svg {
      color: $col_alpha-lighter-2;
    }
    span {
      text-decoration: underline;
      text-transform: capitalize;
      color: $col_gray;
      font-weight: normal;
    }
  }

  .modal-cash {
    @media screen and (max-width: $vertical_tablet_width) {
      .counter {
        flex-direction: column-reverse;
      }
    }
  }

  .cart-item-amount {
    width: 100px;
  }

  .loading-promo {
    height: 100px;
    @include flex;
  }
</style>
