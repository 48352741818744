<template>
  <div v-if="getCartItemsCount" class="cart-summary-mini">
    <span v-for="item in getCartSummary.items" :key="item.id">
      <span class="inline-cart-item-listing">
        <span class="amount">{{ item.amount }}</span>
        <span class="name">{{ item.name }}</span>
        <span v-if="item.componentAlterations?.length">
          <span class="cart-item-component-alterations">
            <span
              v-for="(component, index) in item.componentAlterations"
              :key="`${item.id}.${component.id}`"
              >{{ renderComponentAlterationLabel(component)
              }}<span v-if="index != item.componentAlterations.length - 1"
                >,</span
              ></span
            ></span
          ></span
        ><span class="comma">,</span></span
      ></span
    >
  </div>

  <!-- <div v-if="getCartItemsCount != 0" class="total">
    <h4>Total</h4>
    <h3>{{ formatMoney(getCartSummary.total) }}</h3>
  </div> -->
</template>

<script>
  import store from "@/store";
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: {
      serviceId: {
        type: Number,
        default: null,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      paymentScreen: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getCartItems: "cart/getItems",
        getCartItemsCount: "cart/getItemsCount",
        getCartAlterationName: "cart/getAlterationName",
        getCartAlterationNames: "cart/getAlterationNames",
        getCartSummary: "cart/getSummary",
        getRepoItemById: "repoItems/getById",
      }),
      ...mapActions({
        getCartAllergens: "cart/getAllergens",
      }),
    },
    methods: {
      getServiceChargeMessage() {
        const cartSummary = this.getCartSummary;
        const serviceChargePercent = cartSummary?.serviceCharge?.percent;
        const serviceChargeMinimum = cartSummary?.serviceCharge?.minimum;

        if (serviceChargePercent == null || serviceChargeMinimum == null) {
          return "Loading...";
        }

        return `A service charge applies to this order. This is charged at a rate of ${serviceChargePercent}%, or ${this.formatMoney(
          serviceChargeMinimum
        )}, whichever is higher.`;
      },
      modifyAmount(item, delta) {
        store.dispatch("cart/modifyItemAmount", { item, delta });
      },
      removeDonation(donation) {
        store.dispatch("cart/upsertDonation", { id: donation.id, amount: 0 });
        this.donation = false;
      },
      removeVoucher(voucher) {
        store.dispatch("cart/upsertVoucher", { id: voucher.id, amount: 0 });
      },
      renderComponentAlterationLabel(component) {
        const { levelDelta, levelAbsolute, name } = component;

        let plusOrMinus = "+";
        let amount = "";

        if (levelDelta < 0 && levelAbsolute > 0) plusOrMinus = "-";
        if (levelDelta < 0 && levelAbsolute === 0) plusOrMinus = "No";
        if (levelDelta < -1 || levelDelta > 1) {
          let str = levelDelta.toString().substring(0);
          amount = "x " + str;
        }

        return plusOrMinus === "No"
          ? `${plusOrMinus} ${name}`
          : `${plusOrMinus} ${name} ${amount}`;
      },
    },
  };
</script>

<style lang="scss">
  .cart-summary-mini {
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    flex: 1;
  }

  .total.mini {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.5rem 0 0.5rem;
    gap: 1rem;
    flex-grow: 0;

    h3 {
      margin: 0.25rem 1rem 0 0.25rem;
      font-weight: 400;
      color: #999;

      span {
        padding-left: 1rem;
        font-weight: bold;
        color: $col_softblack;
      }
    }
  }

  .inline-cart-item-listing {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    color: #999;

    .amount {
      color: $col_alpha-lighter-2;
      font-weight: 400;
      min-width: 20px;
      display: inline-block;
      text-align: center;
      height: 20px;
      line-height: 20px;
      border-radius: $button_radius;
      background-color: $col_alpha-lightest-2;
      margin-right: 0.5rem;
    }

    .name {
      color: #000;
      font-weight: 400;
    }
  }

  .comma {
    font-weight: 400;
    color: #000;
  }
</style>
