<template>
  <div class="modal-mask" @click.prevent="close">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        @click.stop=""
        :style="cssProps"
        :class="{ verticalLayout: verticalLayout }">
        <slot></slot>
        <div class="close-modal" @click.stop="close">
          <Icon :path="mdiCloseCircleOutline" title="Close" :size="36" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mdiCloseCircleOutline } from "@mdi/js";

  export default {
    data() {
      return {
        mdiCloseCircleOutline,
      };
    },
    props: {
      maxWidth: {
        type: String,
      },
      verticalLayout: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["close"],
    methods: {
      close() {
        this.$emit("close");
      },
    },
    computed: {
      cssProps() {
        return {
          "--max-width": this.maxWidth + "px",
        };
      },
    },
  };
</script>

<style lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: table;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    position: relative;
    max-width: var(--max-width);
    max-height: 98vh;
    overflow-y: scroll;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: "Stolzl", sans-serif;
    padding-bottom: 4rem;
  }

  .close-modal {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    cursor: pointer;

    @media screen and (max-width: $general_mobile_width) {
      right: 8px !important;
    }
  }
</style>
