<template>
  <Checkout
    v-if="showCheckout"
    @closeCheckout="showCheckout = false"
    :serviceOptions="service?.service?.options"
    :mobileLayout="mobileLayout"
    @swapView="$emit('swapView')" />

  <div v-if="loading" class="loading-spinner_container">
    <LoadingSpinner />
  </div>

  <template v-if="!loading">
    <Transition name="fade">
      <div v-if="!showWalkUpOrderView" class="walkup-menu">
        <PanelActions
          :actions="[
            {
              actionName: 'Section Shortcuts',
              function: () => (showShortcut = !showShortcut),
              className: showShortcut ? 'active' : 'hide',
            },
            {
              actionName: 'Item Image',
              function: () => (showImage = !showImage),
              className: showImage ? 'active' : 'hide',
            },
            {
              actionName: 'Item Description',
              function: () => (showDescription = !showDescription),
              className: showDescription ? 'active' : 'hide',
            },
          ]"
          :showOrderView="showWalkUpOrderView"
          @swapWalkUpView="$emit('swapWalkUpView')" />

        <!-- section shortcuts -->
        <div v-if="!showCheckout && showShortcut" class="section-shortcuts">
          <a
            v-for="section in displayedMenuSections"
            :key="section.id"
            @click="scrollTo(section)"
            class="block-select"
            :class="{ active: Number(activeSectionId) === section.id }">
            {{ section.name }}</a
          >
        </div>

        <div class="tab-menu-container" :class="{ expanded: tabsExpanded }">
          <Menu
            :showImage="showImage"
            :showDescription="showDescription"
            :getCartItemsCount="getCartItemsCount"
            :availableSections="displayedMenuSections"
            @selectItem="selectItem" />

          <CartDisplay
            v-if="tabsExpanded == true || getCartItemsCount != 0"
            :tabsExpanded="tabsExpanded"
            :serviceOptions="service?.service?.options"
            @checkoutScreen="this.showCheckout = true" />
        </div>
      </div>

      <div v-else class="walkup-orders">
        <OrderListFilter
          :showOrderView="showWalkUpOrderView"
          :ifByPlacedOrder="ifByPlacedOrder"
          @swapTicketsOrderBy="ifByPlacedOrder = !ifByPlacedOrder"
          @swapWalkUpView="$emit('swapWalkUpView')" />

        <OrdersStatusBar
          :productionTime="productionTime"
          :timelineOrderCount="walkupProducingCount + walkupPlacedCount"
          :walkupProducingCount="walkupProducingCount" />

        <TicketGroupsByStatus
          v-if="!ifByPlacedOrder"
          :clubPointRewardLevel="service?.service?.clubPointRewardLevel"
          :showGroupTitle="false"
          :loading="loading"
          :timeline="timeline"
          :ifWalkUpOrderView="true"
          :statusTimeline="timelineOrder"
          :updatingStatus="updatingStatus"
          :allowEatOnPremises="service?.service?.allowEatOnPremises"
          status="Placed" />

        <TicketGroupsByPlacedOrder
          v-else
          :clubPointRewardLevel="service?.service?.clubPointRewardLevel"
          :loading="loading"
          :timeline="timeline"
          :showGroupTitle="false"
          :updatingStatus="updatingStatus"
          :statusTimeline="timelineOrderByPlacedOrder"
          status="Placed" />
      </div>
    </Transition>
  </template>

  <transition name="fade">
    <AddItemDialog
      :serviceId="serviceId"
      :selectedItemProp="selectedItem"
      :selectedSection="selectedSection"
      :maxWidth="'500'"
      v-if="showModal"
      @close="showModal = false"
      class="modal customise-item" />
  </transition>
</template>

<script>
  import AddItemDialog from "@/components/AddItemDialog.vue";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import CartDisplay from "@/components/CartDisplay.vue";
  import Checkout from "@/components/Checkout";
  import Enumerable from "linq";
  import { mdiMinusCircle, mdiTimelapse } from "@mdi/js";
  import PanelActions from "@/components/Walkups/PanelActions.vue";
  import Menu from "@/components/Walkups/Menu.vue";
  import OrderListFilter from "@/components/Walkups/OrderListFilter.vue";
  import TicketGroupsByPlacedOrder from "@/components/Walkups/TicketGroupsByPlacedOrder.vue";
  import TicketGroupsByStatus from "@/components/TicketGroupsByStatus.vue";
  import OrdersStatusBar from "@/components/Walkups/OrdersStatusBar.vue";

  export default {
    data() {
      return {
        loading: false,
        showModal: false,
        showCheckout: false,
        serviceId: parseInt(this.$route.params.serviceId),
        selectedItem: null,
        service: null,
        menu: null,
        availableSections: null,
        blockedItems: [],
        showDescription: false,
        showShortcut: true,
        showImage: false,
        ifByPlacedOrder: true,

        activeSectionId: null,

        mdiMinusCircle,
        mdiTimelapse,
      };
    },

    props: {
      tabsExpanded: {
        type: Boolean,
      },
      mobileLayout: {
        type: Boolean,
      },
      status: {
        type: String,
      },
      timeline: {
        type: Object,
      },
      timelineOrder: {
        type: Array,
      },
      timelineOrderByPlacedOrder: {
        type: Array,
      },
      updatingStatus: {
        type: Boolean,
      },
      viewMode: { type: String },
      allowEatOnPremises: {
        type: Boolean,
        default: false,
      },
      productionTime: {
        type: String,
      },
      walkupProducingCount: {
        type: Number,
      },
      walkupPlacedCount: {
        type: Number,
      },
      showWalkUpOrderView: {
        type: Boolean,
      },
    },

    emits: ["swapView", "swapWalkUpView"],

    components: {
      AddItemDialog,
      CartDisplay,
      Checkout,
      PanelActions,
      Menu,
      OrderListFilter,
      TicketGroupsByPlacedOrder,
      TicketGroupsByStatus,
      OrdersStatusBar,
    },

    watch: {
      showWalkUpOrderView(newValue) {
        if (newValue) this.ifByPlacedOrder = true;
      },
    },

    computed: {
      ...mapGetters({
        getCartItemsCount: "cart/getItemsCount",
        selectedStatus: "ticketSelect/selectedStatus",
        selectedTickets: "ticketSelect/selectedTickets",
        selectedGroups: "ticketSelect/selectedGroups",
        displayedMenuSectionIds: "filters/getMenuSectionIds",
        ifShowAllMenuSections: "filters/getIfShowAllMenuSections",
      }),

      displayedMenuSections() {
        return this.availableSections;

        // if (this.ifShowAllMenuSections) return this.availableSections;
        // return this.availableSections?.filter((s) =>
        //   this.displayedMenuSectionIds.includes(s.id)
        // );
      },
    },

    methods: {
      scrollTo(section) {
        const id = `section-${section.id}`;
        this.activeSectionId = section.id;

        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      },

      selectItem(item, section) {
        if (item.outOfStock) {
          return;
        }

        this.selectedItem = item;
        this.selectedSection = section;
        this.showModal = true;
        store.dispatch("ticketSelect/reset");
      },

      getSections() {
        if (this.service?.menu?.sections == null) {
          return [];
        }

        return Enumerable.from(this.service.menu.sections)
          .where((x) => x.availableVendor)
          .toArray();
      },

      getItems(section) {
        return section.itemData;
      },

      async getItemsStock() {
        await store.state.apiPrivate.client.endpoints.kitchenStockItems
          .getAll(this.serviceId)
          .then((response) => {
            if (response.status === 200) {
              this.blockedItems = response.data.data.filter(
                (item) => item.stockControl !== "Available"
              );
            }

            if (response.status === 500) {
              this.errorText = "Something went wrong, Please contact Support.";
            }
          });
      },

      getStockStatus(itemId) {
        const itemIndex = this.blockedItems.map(({ id }) => id).indexOf(itemId);
        if (itemIndex >= 0) {
          const camelCaseText = this.blockedItems[itemIndex].stockControl;

          // UI: Convert upper camelcase to sentense
          const text = camelCaseText.replace(/([A-Z])/g, " $1");

          return {
            ...this.blockedItems[itemIndex],
            text: text,
          };
        } else {
          return false;
        }
      },

      async loadCart() {
        this.loading = true;

        // get service
        this.service = await store.getters["repoServices/getByIdWithQuery"](
          this.serviceId,
          {
            includeMenu: true,
            includeItems: true,
            includeComponents: true,
            includeServiceComponents: true,
            includeServiceItems: true,
            includeEvent: true,
            includeVendor: true,
            methods: 12,
          }
        );

        if (!this.service) return (this.loading = false);

        const {
          event,
          items,
          components,
          vendor,
          serviceItems,
          serviceComponents,
        } = this.service;

        // create document title
        document.title = vendor.name + ": " + this.formatDate(event.date);

        if (serviceItems.length > 0) {
          // upsert serviceItems into repo
          for (const serviceItem of serviceItems) {
            await store.dispatch("repoServiceItems/upsert", serviceItem);
          }
        }

        if (items.length > 0) {
          // Upsert items into repo
          for (const item of items) {
            await store.dispatch("repoItems/upsert", item);

            // upsert itemComponents
            // if (item.components.length > 0) {
            //   for (const itemComponent of item.components) {
            //     await store.dispatch(
            //       "repoItemComponents/upsert",
            //       itemComponent
            //     );
            //   }
            // }
          }
        }
        if (serviceComponents.length > 0) {
          // upsert serviceComponents into repo
          for (const serviceComponent of serviceComponents) {
            await store.dispatch(
              "repoServiceComponents/upsert",
              serviceComponent
            );
          }
        }

        if (components.length > 0) {
          // Upsert Components into repo
          for (const component of components) {
            await store.dispatch("repoComponents/upsert", component);
          }
        }

        // get available menu sections
        const availableSections = this.getSections();

        this.availableSections = availableSections;

        // grab the item data now, then we have it
        if (availableSections != null) {
          for (const section of availableSections) {
            if (section?.items != null) {
              const menuSectionItems = [];

              for (const itemId of section.items) {
                // get item from items
                const item = items.find(({ id }) => id === itemId);

                // check if item is service blocked
                const serviceBlockedItemIds = serviceItems
                  .filter(({ isServiceBlocked }) => isServiceBlocked)
                  .map(({ itemId }) => itemId);

                item.isServiceBlocked = serviceBlockedItemIds?.includes(
                  item.id
                );

                // getting all service blocked component Ids to check if component is service blocked
                const serviceBlockedComponentIds = serviceComponents
                  .filter(({ isServiceBlocked }) => isServiceBlocked)
                  .map(({ componentId }) => componentId);

                for (const [index, itemComponent] of Object.entries(
                  item.components
                )) {
                  const componentBasicInfo = components.find(
                    ({ id }) => id === itemComponent.componentId
                  );

                  const isComponentServiceBlocked =
                    serviceBlockedComponentIds?.includes(
                      itemComponent.componentId
                    );

                  const isComponentOutOfStock = componentBasicInfo.outOfStock;

                  const absolute =
                    isComponentServiceBlocked || isComponentOutOfStock
                      ? 0
                      : itemComponent.levels.default;

                  const componentWithFullData = {
                    ...itemComponent,
                    ...componentBasicInfo,
                    absolute,
                    delta: 0,
                    isServiceBlocked: isComponentServiceBlocked,
                  };

                  // check if any required components are service blocked or out of stock
                  if (
                    itemComponent.levels.default > 0 &&
                    (isComponentServiceBlocked || isComponentOutOfStock)
                  ) {
                    item.outOfStock = true;
                  }

                  item.components[index] = componentWithFullData;
                }
                //item.dietaries = resolveDietary(item, item.components);

                menuSectionItems.push(item);
                section.itemData = menuSectionItems;
              }
            }
          }
        }

        this.selectedItem = null;

        this.loading = false;
      },

      hideCheckout() {
        this.showCheckout = false;
      },
    },

    async mounted() {
      await this.loadCart();
      await this.getItemsStock();
      this.getSections();
    },
  };
</script>

<style lang="scss">
  .description-toggle {
    padding: 0.75rem;
    font-size: 0.875rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    cursor: pointer;
    justify-content: flex-end;
    text-decoration: underline;
    color: $col_gray;
    font-weight: 600;
  }

  .tab-menu-container {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $mobileLayout_breakpoint_width) {
      .tabs-expanded & {
        flex-direction: row;
        .menu-sections {
          border-right: 1px solid $col_disabled_gray;
          height: 100%;
          max-height: unset;
        }
      }
    }

    &.expanded {
      flex-direction: row;

      .menu-sections,
      .cart-display {
        flex: 1;
        height: auto;
      }
    }
  }

  .loading-spinner_container {
    margin-top: 5rem;
    width: 100%;
    text-align: center;
  }

  .section-anchor {
    font-size: 0;
    height: 0;
    position: relative;
    top: 0;
  }

  .walkup-menu,
  .walkup-orders {
    padding: 0.05rem 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: #fff;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
  }

  .walkup-orders {
    .ticket-group-container {
      border: none !important;
    }
  }

  .section-shortcuts {
    width: 100%;
    background: #fff;
    z-index: 3;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0.75rem;
    min-height: 3.5em;

    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    h2 {
      float: left;
      margin: 5px;
      margin-right: 25px;
    }

    a {
      display: inline-block;
      background-color: $col_beta-lightest;
      border-radius: 5px;
      margin: 0;
      cursor: pointer;
      font-size: 0.9rem;
      text-decoration: none;
      color: black;
      transition: background-color 0.15s;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: $col_beta-lighter;
      }
    }

    @media screen and (max-width: $tablet_min_width) {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      flex-shrink: 0;
      line-height: 1rem;

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  .menu-section_heading {
    margin: 0;
    padding: 0.8rem 0.8rem 0.5rem;
    border-bottom: 2px solid #ff7b7d;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .customise-item {
    .modal-container {
      max-width: 630px;
      padding: 1.5rem 0 0;
    }
  }
</style>
