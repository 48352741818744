<template>
  <button
    :class="[{ active }, className, { show }]"
    @click="onclick"
    :disabled="active">
    <span class="tip">{{ tipText }}</span>
    <span class="material-icons material-icons-round"
      ><Icon :path="iconCode"
    /></span>
  </button>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      show: {
        type: Boolean,
        default: false,
      },
      className: {
        type: String,
        default: "",
      },
      onclick: {
        type: Function,
        default: () => {},
      },
      tipText: {
        type: String,
        default: "",
      },
      iconCode: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style lang="scss" scoped>
  button {
    padding: 0 !important;
    border: 0 !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: transparent;
    color: rgb(190, 190, 190) !important;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;

    span.material-icons {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      cursor: pointer;
    }

    span.tip {
      position: absolute;
      top: 40px;
      width: max-content;
      padding: 0.12rem 0.35rem 0.1rem;
      background: rgba(0, 0, 0, 0.6) !important;
      border-radius: 4px;
      font-size: 0.7rem;
      color: #fff !important;
      font-weight: normal;
      visibility: hidden;
      opacity: 0;
    }

    &:not(.active) {
      span.material-icons {
        border: 1px solid rgb(241, 241, 241);
      }
    }

    &:disabled.active {
      background: none !important;
    }

    &.active {
      span.material-icons {
        backdrop-filter: brightness(0.94);
      }
    }

    &.show {
      span.tip {
        animation: show 2.5s 0.2s backwards;
      }
    }

    @keyframes show {
      99% {
        opacity: 1;
        visibility: visible;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:hover:not(.active) {
      background: none;
      box-shadow: 0 0 4px #ddd;
    }

    &.in-stock {
      span.material-icons:hover {
        color: #32b98b;
        background: #fff;
      }
      &.active,
      &.active {
        color: #32b98b !important;
        cursor: not-allowed;
        span.material-icons:hover {
          background: none;
          cursor: not-allowed;
        }
      }
    }

    &.out-of-stock {
      span.material-icons:hover {
        color: $col_delta-lighter;
        background: #fff;
      }
      &.active {
        color: $col_delta-lighter !important;
        cursor: not-allowed;
        span.material-icons:hover {
          background: none;
          cursor: not-allowed;
        }
      }
    }
  }
</style>
