<template>
  <table class="table">
    <tr v-for="item in items" :key="item.id">
      <td>{{ item.name }}</td>
      <td>×{{ item.amountAdded }}</td>
      <td>{{ this.formatMoney(item.revenue) }}</td>
    </tr>
    <tr v-if="items != null && items.length">
      <th>Total</th>
      <th>{{ totalCount }}</th>
      <th>{{ this.formatMoney(totalRevenue) }}</th>
    </tr>
  </table>
</template>

<script>
  export default {
    data() {
      return {
        serviceId: this.$route.params.serviceId,
        items: [],
        totalCount: 0,
        totalRevenue: 0,
      };
    },

    components: {},

    methods: {
      updateServiceItems() {
        this.$store.state.apiPrivate.client.endpoints.serviceSummaryComponents
          .get(this.serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject("Failed to download service summary data.");
          })
          .then((data) => {
            this.items = data.sort((a, b) => (a.revenue < b.revenue ? 1 : -1));

            this.totalCount = data.reduce(
              (partialSum, item) => partialSum + item.amountAdded,
              0
            );
            this.totalRevenue = data.reduce(
              (partialSum, item) => partialSum + item.revenue,
              0
            );
          });
      },
    },

    mounted() {
      this.updateServiceItems();
    },
  };
</script>
