<template>
  <div class="due-time row align-center" :class="{ highlight: ifHighlight }">
    <Icon :path="mdiShoppingOutline" :size="20" />
    <span class="label">Due time</span>
    {{ this.getDueFor(order) }}
  </div>
</template>
<script>
  import { mdiShoppingOutline } from "@mdi/js";

  export default {
    data() {
      return {
        mdiShoppingOutline,
      };
    },
    props: {
      order: {
        type: Object,
      },
      ifHighlight: {
        type: Boolean,
      },
    },
    methods: {
      // [⌚] Format timings for final display. This controls how different channels are approached, as a single unified variable doesn't always make sense.
      getDueFor(order) {
        if (order.channel == "Delivery") {
          return this.formatTime(
            order.timings.advertised.collectionTime,
            "HH:mm"
          );
        }

        return this.formatTime(order.timings.selectedSlot, "HH:mm");

        // if (order.channel == "WalkUp") {
        //   return this.formatTime(
        //     order.timings.advertised.preparationStartTime,
        //     "HH:mm"
        //   );
        // }

        // if (order.channel == "Delivery") {
        //   return this.formatTime(
        //     order.timings.advertised.collectionTime,
        //     "HH:mm"
        //   );
        // }

        // return this.formatTime(order.timings.selectedSlot, "HH:mm");
      },
    },
  };
</script>
