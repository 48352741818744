<template>
  <div class="popup-modal" v-if="isVisible">
    <div class="order-confirm">
      <h2>
        Confirm changing {{ this.orders.length }} order{{
          this.orders.length > 1 ? "s" : ""
        }}
        to {{ newStatus }}
      </h2>
      <span class="order-confirm__requires-payment" v-if="requiresAction">
        <Icon :path="mdiAlertDecagram" />
        {{ requiresAction }} order{{
          requiresAction.length > 1 ? "s" : ""
        }}
        require{{ requiresAction.length > 1 ? "" : "s" }} further payment before
        these orders can be changed to {{ newStatus }}.
      </span>
      <AgGridVue
        v-if="!selectedRequiresPayment"
        class="ag-theme-alpine order-confirm__list"
        @grid-ready="onGridReady"
        :columnDefs="columnDefs"
        :rowData="orders"
        rowSelection="single"
        @rowSelected="select($event)">
      </AgGridVue>
      <div class="order-confirm__payment" v-if="selectedRequiresPayment">
        <div class="order-confirm__payment__header">
          <span><strong>Order:</strong> {{ selectedOrder.id }}</span>
          <span><strong>#:</strong> {{ selectedOrder.number }}</span>
          <span><strong>Channel:</strong> {{ selectedOrder.channel }}</span>
          <span
            ><strong>Time:</strong>
            {{ selectedOrder.timings.selectedSlot }}</span
          >
          <button @click="this.selectedIndex = -1">Cancel</button>
        </div>
        <Payments
          :order="selectedOrder"
          :orderIsCart="selectedOrder != null && selectedOrder.id != null" />
      </div>
      <div class="order-confirm__actions">
        <span><button class="cancel" @click="cancel">Cancel</button></span>
        <span class="space"></span>
        <span
          ><button class="confirm" @click="confirm" :disabled="requiresAction">
            Confirm
          </button></span
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  //import { Logger } from "@tucktrucks/core";
  import { AgGridVue } from "ag-grid-vue3";
  import { mdiAlertDecagram } from "@mdi/js";
  import Payments from "@/components/Orders/Payments/Payments.vue";

  export default {
    components: { Payments, AgGridVue },

    data() {
      return {
        selectedIndex: -1,
        isVisible: false,
        columnDefs: [
          {
            field: "number",
            headerName: "#",
            width: 60,
          },
          {
            field: "channel",
            headerName: "Channel",
            width: 150,
          },
          {
            field: "customer",
            headerName: "Customer",
            width: 200,
            cellRenderer: (rowData) => {
              return rowData.value?.name == null ? "Guest" : rowData.value.name;
            },
          },
          {
            field: "timings",
            headerName: "Time",
            width: 150,
            cellRenderer: (rowData) => {
              return rowData.value?.selectedSlot == null
                ? "--:--"
                : rowData.value.selectedSlot;
            },
          },
          {
            field: "paymentPending",
            headerName: "Outstanding",
            width: 150,
            cellRenderer: (rowData) => {
              return this.formatMoney(rowData.value);
            },
            cellStyle: (params) => {
              if (params.value) {
                return {
                  color: "#9e5557",
                  backgroundColor: "#fceaea",
                  fontWeight: 900,
                };
              }
              return null;
            },
          },
        ],
        mdiAlertDecagram,

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
      };
    },

    props: {
      orders: {
        type: Array,
      },
    },

    emits: ["confirmed", "cancelled"],

    computed: {
      ...mapGetters({
        getOrder: "repoOrders/getById",
      }),

      requiresAction() {
        if (this.newStatus !== "Completed") {
          return false;
        }

        return this.orders.filter((x) => x.paymentPending).length;
      },

      selectedOrder() {
        if (this.selectedIndex < 0) {
          return null;
        }

        return this.orders[this.selectedIndex];
      },

      selectedRequiresPayment() {
        return (
          this.newStatus == "Completed" &&
          this.selectedOrder != null &&
          this.selectedOrder.paymentPending
        );
      },
    },

    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        // window.setTimeout(() => {
        //   this.gridColumnApi?.autoSizeAllColumns();
        // }, 50);
      },

      show(newStatus) {
        this.newStatus = newStatus;

        // If there are no orders, reject the premise immediately
        if (this.orders.length == 0) {
          // Does not show the modal
          return new Promise((resolve) => {
            resolve(false);
          });
        }
        // If this is a single order which is fully funded, automatically resolve.
        else if (this.orders.length == 1) {
          if (!this.orders[0].paymentPending) {
            // Does not show the modal
            return new Promise((resolve) => {
              resolve(true);
            });
          }
        }

        // Show the modal
        this.isVisible = true;

        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
        });
      },

      select(event) {
        this.selectedIndex = event.rowIndex;
      },

      confirm() {
        this.isVisible = false;
        this.resolvePromise(true);
      },

      cancel() {
        this.isVisible = false;
        this.resolvePromise(false);
        // ^ This cancels the modal without an error, to throw use
        // this.rejectPromise(new Error('Something went wrong'))
      },
    },
  };
</script>

<style lang="scss">
  .popup-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 20;

    .order-confirm {
      background: #fff;
      border-radius: 5px;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 2rem 1rem;

      display: flex;
      flex-direction: column;
      gap: 0.5em;
      width: 800px;

      h2 {
        margin: 0;
      }

      &__requires-payment {
        border: 1px solid #f9ca24;
        background: #f6e58d;
        border-radius: 8px;
        padding: 0.5em;
        font-size: 0.9em;

        .mdi-icon {
          float: left;
          margin-right: 0.6em;
        }
      }

      &__list {
        width: 100%;
        height: 320px;

        button {
          background: $col_beta;
          color: $col_white;

          vertical-align: middle;
          margin: 0.5em;
          padding: 0.1em 0.5em;
          border-radius: 8px;
        }
      }

      &__payment {
        background: #f7f7f7;
        padding: 0.5em;

        &__header {
          display: flex;
          align-items: center;
          gap: 1em;

          > span {
            flex-grow: 1;
          }

          button {
            background: $col_beta;
            color: $col_white;
            padding: 0.1em 0.6em;
            border-radius: 8px;
            border: 0;
          }

          margin-bottom: 0.5em;
        }
      }

      &__actions {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 2px solid $col_beta;
        display: flex;

        .space {
          flex-grow: 1;
        }

        button {
          padding: 0.66em 1em;
          border: 0;
          border-radius: 8px;
        }

        .cancel {
          background: #ccc;
        }
        .cancel:hover {
          background: #eee;
        }
        .cancel:active {
          background: #aaa;
        }

        .confirm {
          background: $col_beta;
        }
        .confirm:hover {
          background: $col_beta-lighter;
        }
        .confirm:active {
          background: $col_beta-darker;
        }
      }
    }
  }
</style>
