<template>
  <ContextDisplay :iconPath="mdiMenu">
    <template v-slot:dropdown>
      <ul class="app-menu">
        <li class="app-menu_item" @click="$emit('logout')">
          <Icon :path="mdiAccountCircle" />
          Sign out
        </li>
        <li class="app-menu_item" @click="$emit('toggleFullscreen')">
          <Icon :path="mdiOverscan" class="user-icon" title="Fullscreen" />
          Toggle fullscreen mode
        </li>
      </ul>
    </template>
  </ContextDisplay>
</template>

<script>
  import { mdiAccountCircle, mdiMenu, mdiOverscan } from "@mdi/js";
  import ContextDisplay from "@/components/TopBar/ContextDisplay.vue";

  export default {
    data() {
      return {
        openDropdown: false,
        mdiAccountCircle,
        mdiMenu,
        mdiOverscan,
      };
    },
    emits: ["logout", "toggleFullscreen"],

    components: { ContextDisplay },
  };
</script>

<style lang="scss">
  .app-menu {
    @include flex($dir: column, $g: 1rem, $ai: flex-start, $jc: flex-start);
    &_item {
      cursor: pointer;
      width: 100%;
      @include flex($ai: flex-start, $jc: flex-start, $wrap: nowrap);
    }
  }
</style>
