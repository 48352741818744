<template>
  <div class="order-refund">
    <div class="tabs">
      <div
        class="tab-btn"
        @click="changeTab(1)"
        :class="[activeTab === 1 ? 'active' : '']">
        Cancel Order
      </div>
      <div v-if="order.status === 'Refunded'" class="tab-btn disabled">
        Order Refunded
      </div>
      <div
        v-else
        class="tab-btn"
        @click="changeTab(2)"
        :class="[activeTab === 2 ? 'active' : '']">
        Refund Order
      </div>
    </div>

    <div v-if="activeTab === 1" class="tab-content">
      <CancellationProcess
        :order="order"
        @close="close"
        v-if="allowStatusChange('Cancellable')" />
      <p v-else class="not-eligible">This order is not cancellable.</p>
    </div>

    <div v-if="activeTab === 2" class="tab-content">
      <RefundProcess
        :order="order"
        @close="close"
        v-if="allowStatusChange('Refundable')" />
      <p v-else class="not-eligible">This order is not refundable.</p>
    </div>
  </div>
</template>

<script lang="js">
  import RefundProcess from "@/components/Orders/Refunds/RefundProcess";
  import CancellationProcess from "@/components/Orders/Refunds/CancellationProcess";

  export default {
    components: {
      RefundProcess,
      CancellationProcess
    },

    data() {
      return {
        activeTab: 1,
      };
    },

    props: {
      order: {
        type: Object,
      },
    },

    methods: {
      allowStatusChange(/*status*/) {
        return true;
        //return this.order.refundMode === status;
      },

      changeTab(tabNumber) {
        this.activeTab = tabNumber;
      },

      close() {
        this.$emit("close");
      },
    }
  }
</script>

<style lang="scss" scoped>
  .order-refund {
    max-width: 600px;
    width: 100%;
    margin: 1rem auto;

    .tab-content {
      position: static;
    }

    .tab-btn {
      cursor: default;
      background-color: #fff;
      border-bottom: 2px solid #ddd;
      color: #b0b0b0;
      cursor: pointer;

      &.active {
        color: $col_black;
        border-bottom-color: $col_beta;
      }

      &:hover:not(.active) {
        border-bottom: 2px solid #ddd;
        color: $col_black;
        background-color: #fff;
      }
    }

    p.not-eligible {
      text-align: center;
      color: $col_disabled_text;
      font-style: italic;
      font-size: 1rem;
      margin: 2rem 0 0;
    }
  }
</style>
