<template>
  <div class="mask error" @click.prevent="" v-if="this.networkError()">
    <div class="message">
      <img
        class="logo"
        alt="Tuck Trucks Logo"
        height="16"
        width="137"
        :src="this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')" />

      <div class="message_content">
        <h1>
          <Icon :path="mdiCloudRemove" :size="30" />
          Internet connection lost.
        </h1>

        <button @click="this.retry()" :disabled="this.isRetrying">
          <LoadingSpinner v-if="this.isRetrying" />
          <span v-else>Retry</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import { mdiCloudRemove } from "@mdi/js";
  import { useToast } from "vue-toastification";

  export default {
    data() {
      return {
        isRetrying: false,
        mdiCloudRemove,
      };
    },

    computed: {
      ...mapGetters({
        networkError: "network/networkError",
        networkLostAt: "network/networkLostAt",
      }),
    },

    methods: {
      retry() {
        this.isRetrying = true;

        store.state.apiPublic.client
          .isApiAvailable()
          .then((response) => {
            useToast().success("Connection recovered!");

            store.dispatch("network/onSuccess", response);
          })
          .catch((error) => {
            useToast().error("Connection still not available.");

            store.dispatch("network/onError", error);
          })
          .finally(() => {
            window.setTimeout(() => {
              this.isRetrying = false;
            }, 2000);
          });
      },
    },
  };
</script>

<style lang="scss">
  .error {
    &.mask {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1000000;
      height: $topbar_height;
      background-color: #e1363822;
    }

    .message {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 4rem;
      background: $col_beta;
      @include flex($g: 2rem, $wrap: nowrap);
      padding: 0 1rem;

      @media screen and (max-width: $mobile_max_width) {
        .logo {
          display: none;
        }
      }

      &_content {
        @include flex($jc: flex-end, $g: 2rem, $wrap: nowrap);
        flex: 1;
      }

      .mdi-icon {
        svg {
          color: #fff;
        }
      }

      h1 {
        font-size: 1.4rem;
        line-height: 1;
        color: #fff;
        margin: 0;
        padding: 0;
        font-weight: 900;
        @include flex($wrap: nowrap);

        @media screen and (max-width: $tablet_min_width) {
          font-size: 1.3rem;
        }
      }

      button {
        @include contained-button(
          $p: 0.5rem 2rem,
          $h: 2.5rem,
          $bg: white,
          $col: $col_beta
        );
        font-weight: 900;

        &:hover {
          background: $col_delta-lighter !important;
          color: #fff !important;
        }
      }
    }
  }
</style>
