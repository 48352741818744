<template>
  <div class="payment-card">
    <div v-if="getZettleIntent == null">
      <h4>Connect to the card reader and add a card payment to this order</h4>

      <div class="payment-card__controls payment-controls">
        <div class="payment-card__totals">
          <button
            class="full-amount"
            :disabled="amount >= this.fundingDue"
            @click="amount = this.fundingDue">
            Full Amount
          </button>

          <h1 class="massive beta">
            <small>Payment Amount:</small>
            {{ formatMoney(amount) }}
          </h1>

          <p v-if="amount < 0.01">Zettle requires a minimum payment of £0.01</p>

          <button
            class="add"
            @click="takePayment()"
            :disabled="
              amount < 0.01 || getCartLockByPaymentStatus || !getQueueIsEnd
            ">
            Take Payment
          </button>
        </div>

        <PaymentPicker v-model="amount" :maxValue="this.fundingDue" />
      </div>
    </div>
    <IntegrationPaymentZettle
      :orderIsCart="orderIsCart"
      :intent="getZettleIntent"
      :url="getZettleIntentURL"
      :order="getCart"
      v-else />
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import PaymentPicker from "@/components/Orders/Payments/PaymentPicker.vue";
  import IntegrationPaymentZettle from "@/components/Integrations/IntegrationPaymentZettle.vue";

  export default {
    components: {
      PaymentPicker,
      IntegrationPaymentZettle,
    },

    data() {
      return {
        amount: this.fundingDue,
        currentIntent: null,
        currentIntentUrl: null,
      };
    },

    props: {
      order: {
        type: Object,
      },
      orderIsCart: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["paymentCreated"],

    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getQueueIsEnd: "cart/getQueueIsEnd",
        getCartLockByPaymentStatus: "payment/getLock",
        getZettleIntent: "payment/getIntent",
        getZettleIntentURL: "payment/getIntentURL",
      }),

      fundingDue() {
        return this.orderIsCart
          ? this.order.summary.paymentPending
          : this.order.paymentPending;
      },
    },

    watch: {
      order: {
        handler: function () {
          if (this.amount > this.fundingDue) {
            this.amount = this.fundingDue;
          }
        },
        deep: true,
        immediate: true,
      },
      fundingDue: {
        handler: function () {
          if (this.amount == null) {
            this.amount = this.fundingDue;
          }
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      takePayment() {
        // By setting current intent, we hide the payments and show the zettle integration control
        // this.currentIntent = { loading: true };

        const createZettleIntent = this.orderIsCart
          ? "payment/createCartZettlePayment"
          : "payment/createOrderZettlePayment";
        const id = this.orderIsCart ? this.order.cartId : this.order.id;
        const key = this.orderIsCart ? this.order.cartKey : this.order.key;

        store.dispatch(createZettleIntent, { amount: this.amount, id, key });
      },
    },

    mounted() {
      this.amount = this.fundingDue;
    },

    unmounted() {},
  };
</script>

<style lang="scss">
  .payment-card {
    border-top: 2px solid $col_beta;

    h4 {
      width: 100%;
      text-align: center;
    }

    &__controls {
      display: flex;
      gap: 1em;
    }

    &__totals {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      .space {
        flex-grow: 1;
      }

      button {
        background: $col_beta;
        border: 0;
        border-radius: 8px;
      }

      button.full-amount {
        @include outlined-button($h: 2.5rem, $col: $col_beta);
      }

      button.add {
        width: 66%;
        font-size: 1.4em;
        @include contained-button;
      }

      h1.massive {
        font-size: 4rem;
      }
    }
  }
</style>
