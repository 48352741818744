<template>
  <div class="cart-section_table">
    <div v-if="getCartItemsCount == 0" class="empty-cart-message">
      <h3 class="faded">Your Basket is empty.</h3>
    </div>

    <div v-if="getCartItemsCount">
      <table
        class="cart-summary"
        :class="{ 'checkout-screen': checkoutScreen }">
        <tr
          v-for="item in getCartSummary.items"
          :key="item.id"
          class="item-row">
          <td class="cart-item-name">
            {{ item.name }}

            <div v-if="item.componentAlterations?.length">
              <div class="cart-item-component-alterations">
                <span
                  v-for="component of item.componentAlterations"
                  :key="`${item.id}.${component.id}`">
                  {{ renderComponentAlterationLabel(component) }}
                </span>
              </div>
            </div>
          </td>
          <td class="cart-item-amount-cell">
            <div class="cart-item-amount">
              <span
                v-if="readOnly === false"
                @click="modifyAmount(item, -1)"
                class="cart-item-amount_alter">
                <Icon :path="mdiMinusCircleOutline" :size="22" title="Remove" />
              </span>
              <span class="cart-item-amount_amount"
                >×<span>{{ item.amount }}</span></span
              >
              <span
                v-if="readOnly === false"
                @click="modifyAmount(item, 1)"
                class="cart-item-amount_alter">
                <Icon :path="mdiPlusCircleOutline" :size="22" title="Add" />
              </span>
            </div>
          </td>
          <td class="cart_price">
            {{ this.formatMoney(item.itemPriceConfigured) }}
          </td>
        </tr>
        <tr v-show="getCartSummary.includeServiceCharge">
          <td colspan="2">
            <h4>Subtotal</h4>
          </td>
          <td class="cart_price">
            <h4>{{ formatMoney(getCartSummary.subTotal) }}</h4>
          </td>
        </tr>
        <tr v-show="getCartSummary.includeServiceCharge">
          <td colspan="2" class="extras">
            Service Charge
            <span class="service-charge-info"
              ><Icon
                :path="mdiInformation"
                :size="18"
                :tooltip="getServiceChargeMessage()"
            /></span>
          </td>
          <td class="cart_price extras">
            {{ formatMoney(getCartSummary.serviceCharge?.amount) }}
          </td>
        </tr>

        <transition name="fade">
          <tr v-if="getCartSummary.charityDonations?.amount > 0">
            <td class="extras">
              {{ getCartSummary.charityDonations?.name }}
            </td>
            <td class="cart-item-amount">
              <span
                v-if="!paymentScreen"
                @click="
                  removeDonation(getCartSummary.charityDonations);
                  $emit('uncheckDonation');
                "
                class="cart-item-amount_alter"
                ><Icon :path="mdiMinusCircleOutline" :size="22" title="Remove"
              /></span>
            </td>
            <td class="cart_price extras">
              {{ formatMoney(getCartSummary.charityDonations?.amount) }}
            </td>
          </tr>
        </transition>

        <transition name="fade">
          <tr v-if="getCartSummary.promotionVouchers?.amount > 0">
            <td class="extras reduction">
              <div class="promotion">
                <Icon :path="mdiTag" :size="22" />
                <span class="promotion__name">
                  {{ getCartSummary.promotionVouchers?.name }}</span
                >
              </div>
            </td>
            <td class="cart-item-amount">
              <span
                v-if="!paymentScreen"
                @click="removeVoucher(getCartSummary.promotionVouchers)"
                class="cart-item-amount_alter"
                ><Icon :path="mdiMinusCircleOutline" :size="22" title="Remove"
              /></span>
            </td>
            <td class="cart_price extras reduction">
              {{ formatMoney(-getCartSummary.promotionVouchers?.amount) }}
            </td>
          </tr>
        </transition>
      </table>
    </div>
    <!-- <div v-if="getCartItemsCount != 0" class="total">
      <h4>Total</h4>
      <h3>{{ formatMoney(getCartSummary.total) }}</h3>
    </div> -->
  </div>
</template>

<script>
  import store from "@/store";
  import { mapActions, mapGetters } from "vuex";
  import {
    mdiMinusCircleOutline,
    mdiPlusCircleOutline,
    mdiInformation,
    mdiTag,
  } from "@mdi/js";

  export default {
    data() {
      return {
        eventId: this.$route.params.eventId,
        serviceId: this.$route.params.serviceId,
        mdiMinusCircleOutline,
        mdiPlusCircleOutline,
        mdiInformation,
        mdiTag,
      };
    },
    props: {
      // serviceId: {
      //   type: Number,
      //   default: null,
      // },
      readOnly: {
        type: Boolean,
        default: false,
      },
      paymentScreen: {
        type: Boolean,
        default: false,
      },
      checkoutScreen: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getCartLockByPaymentStatus: "payment/getLock",
        getCartItems: "cart/getItems",
        getCartItemsCount: "cart/getItemsCount",
        getCartAlterationName: "cart/getAlterationName",
        getCartAlterationNames: "cart/getAlterationNames",
        getCartSummary: "cart/getSummary",
        getRepoItemById: "repoItems/getById",
      }),
      ...mapActions({
        getCartAllergens: "cart/getAllergens",
      }),
    },
    methods: {
      getServiceChargeMessage() {
        const cartSummary = this.getCartSummary;
        const serviceChargePercent = cartSummary?.serviceCharge?.percent;
        const serviceChargeMinimum = cartSummary?.serviceCharge?.minimum;

        if (serviceChargePercent == null || serviceChargeMinimum == null) {
          return "Loading...";
        }

        return "This fee is 5% of your basket after promotions or discounts are applied. The minimum service charge for each online order is set at £0.60.";
      },
      modifyAmount(item, delta) {
        if (this.getCartLockByPaymentStatus) return;
        store.dispatch("cart/modifyItemAmount", { item, delta });
      },
      removeDonation(donation) {
        store.dispatch("cart/upsertDonation", { id: donation.id, amount: 0 });
        this.donation = false;
      },
      removeVoucher(voucher) {
        store.dispatch("cart/deleteVoucher", voucher.id);
      },
      renderComponentAlterationLabel(component) {
        const { levelDelta, levelAbsolute, name } = component;

        let plusOrMinus = "+";
        let amount = "";

        if (levelDelta < 0 && levelAbsolute > 0) plusOrMinus = "-";
        if (levelDelta < 0 && levelAbsolute === 0) plusOrMinus = "No";
        if (levelDelta < -1 || levelDelta > 1) {
          let str = levelDelta.toString().substring(0);
          amount = "x " + str;
        }

        return plusOrMinus === "No"
          ? `${plusOrMinus} ${name}`
          : `${plusOrMinus} ${name} ${amount}`;
      },
    },
  };
</script>

<style lang="scss">
  .cart-section {
    overflow: auto;
    display: flex;
    flex-direction: column;

    h2.cart-items {
      font-size: 1rem;
      margin: 0.25rem 0 0;
      padding-left: 15px;
    }
    .total {
      flex: 1;
    }

    &_table {
      overflow: auto;
      flex: 1;
    }
  }
  .cart-summary {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.5rem;
    overflow-y: auto;
    display: block;
    border-bottom: 1px solid #eee;
    margin-top: 0.5rem;

    &.checkout-screen {
      height: auto;
    }

    .item-row {
      border-bottom: 1px solid $table_border_color;
      width: inherit;
    }

    .cart_price {
      text-align: right;
      padding-right: 0;
      width: inherit;
    }

    .extras {
      color: $col_faded-darker;

      .service-charge-info {
        margin-left: 5px;
        vertical-align: middle;
      }
    }
    .cart-item-amount_alter {
      display: inline-block;
      cursor: pointer;
      color: $col_beta-darker;
      width: 36px;
      line-height: 1;

      &.disabled {
        color: lightgray;
        pointer-events: none;
      }
    }
  }

  .total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;

    h3 {
      margin: 0.25rem 0;
    }

    h4 {
      margin: 0.25rem 1rem 0 0.25rem;
      font-weight: 400;
      color: #999;
    }
  }

  .empty-cart-message {
    padding: 5rem 0;
    text-align: center;
  }

  .cart-item-name {
    padding: 8px 0;
    flex-grow: 3 !important;
    width: inherit;
  }

  .cart-item-amount {
    display: flex;
    align-items: center;
    height: 3rem;
    align-items: center;
    justify-content: center;
  }

  .cart-item-amount_amount {
    width: 50px;
    text-align: center;
    font-weight: 300;
    color: #999;
    span {
      font-weight: 400;
      width: 45px;
      color: #000;
      padding-left: 5px;
    }
  }

  td.cart-item-amount-cell {
    .cart-item-amount_alter {
      display: inline-block;
      cursor: pointer;
      color: $col_beta-darker;
      width: 36px;
      line-height: 1;
    }
  }

  .mdi-icon {
    display: flex;
    justify-content: center;
  }

  .btn_checkout {
    width: 100%;
    background: $col_beta;
    padding: 1rem;
    border: none;
    cursor: pointer;
    font-size: 1.15rem;

    &:hover {
      background: $col_beta-darker;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .reduction {
    color: $col_alpha !important;
  }

  .promotion {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    padding: 0.25rem 0;
    align-items: flex-start;

    .mdi-icon {
      display: inline-block;
    }
    &__name {
      width: 120px;
      line-height: 1.1rem;
    }
  }

  .tabs-expanded table.cart-summary tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    td {
      flex-grow: 1;
    }
    @media screen and (max-width: 1200px) and (min-width: 425px) {
      td {
        display: block;
      }
      td.cart-item-name {
        width: 100%;
        font-size: 1.1rem;
        line-height: 1.4em;
        padding-bottom: 0;
      }
      td.cart-item-amount-cell {
        display: inline-block;
        width: 100px;
        font-size: 0.85rem;
        font-weight: 400;
        color: #777;
      }
      td.cart_price {
        display: inline-block;
        width: calc(100% - 100px);
        font-size: 0.85rem;
        font-weight: 400;
        color: #777;
      }
    }
  }
</style>
