<template>
  <div class="orders-filter">
    <ViewSwitchButton
      :showOrderView="showOrderView"
      @click="$emit('swapWalkUpView')" />
    <p class="label">Sort By</p>
    <button
      type="button"
      :class="{ active: ifByPlacedOrder }"
      @click="$emit('swapTicketsOrderBy')">
      <Icon :path="mdiClockOutline" :size="16" />Placed Time
    </button>
    <button
      type="button"
      :class="{ active: !ifByPlacedOrder }"
      @click="$emit('swapTicketsOrderBy')">
      <Icon :path="mdiShoppingOutline" :size="16" />Due Time
    </button>
  </div>
</template>

<script>
  import { mdiClockOutline, mdiShoppingOutline } from "@mdi/js";
  import ViewSwitchButton from "@/components/Walkups/ViewSwitchButton";
  export default {
    data() {
      return { mdiShoppingOutline, mdiClockOutline };
    },
    props: {
      ifByPlacedOrder: {
        type: Boolean,
      },
      showOrderView: {
        type: Boolean,
      },
    },
    emits: ["swapWalkUpView", "swapTicketsOrderBy"],
    components: { ViewSwitchButton },
  };
</script>
<style lang="scss">
  .orders-filter {
    @include walk-up-panel-actions;
  }
</style>
