<template>
  <div class="delayed-bar__outer">
    <div class="delayed-bar">
      <p class="delayed-bar__status">
        <Icon :path="mdiClockPlusOutline" :size="16" />
        Delayed by
        <span>{{ delayedTime }}</span>
      </p>
      <button type="button" @click="Undelayed" :disabled="updatingStatus">
        Remove
      </button>
    </div>
  </div>
</template>

<script>
  import { mdiClockPlusOutline } from "@mdi/js";
  import store from "@/store";

  export default {
    data() {
      return { updatingStatus: false, mdiClockPlusOutline };
    },
    props: { duration: { type: String }, orderId: { type: Number } },
    computed: {
      delayedTime() {
        if (this.duration == null) {
          return null;
        }

        const times = this.duration.split(":").map((d) => Number(d));
        const days = times[0] != 0 ? times[0] + "d" : "";
        const hours = times[1] != 0 ? times[1] + "h" : "";
        const mins = times[2] != 0 ? times[2] + "m" : "";
        const seconds = times[3] != 0 ? times[3] + "s" : "";
        return days + hours + mins + seconds;
      },
    },

    methods: {
      async Undelayed() {
        this.updatingStatus = true;

        const payload = {
          ids: [this.orderId],
          delayedBy: "0:00:00:00",
        };

        try {
          const response =
            await store.state.apiPrivate.client.endpoints.orderDelays.update(
              payload
            );
          if (response.status === 200) {
            this.updatingStatus = false;
          }
        } catch (error) {
          this.updatingStatus = false;
          const errorMsg = `Failed to undelay order ${this.orderId}`;
          if (error.status >= 400 && error.status < 500) {
            window.log.info(errorMsg, error);
          } else {
            window.log.error(errorMsg, error);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delayed-bar {
    @include flex($jc: flex-start);
    font-size: 0.875rem !important;
    color: $col_delta-lighter;
    max-width: 400px;
    width: 90%;

    &__outer {
      width: 100%;
      background: $col_beta-lightest;
      padding: 0 5%;
    }

    &__status {
      @include flex($jc: flex-start, $g: 0.25rem, $wrap: nowrap);
      margin: 0;
      span {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    button {
      @include button-base(
        $h: 2rem,
        $fs: 0.875rem,
        $p: 0.25rem 0.5rem,
        $w: fit-content
      );
      border: 0;
      color: gray;
      text-decoration: underline;
    }
  }
</style>
