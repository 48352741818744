<template>
  <transition name="fade" mode="out-in">
    <div class="dropdown" v-show="openDropdown">
      <div class="dropdown__content">
        <slot></slot>
      </div>

      <div class="area-close-component" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      width: { type: String },
      openDropdown: { type: Boolean },
    },
    emits: ["close"],
  };
</script>

<style lang="scss">
  .dropdown {
    &__content {
      width: v-bind("width");
      min-width: 150px;
      position: absolute;
      border-radius: $card_radius;
      box-shadow: $second_layer_box_shadow;
      background: white;
      padding: 1rem;
      top: 2.5rem;
      right: 0;
      z-index: 2;
      @media screen and (max-width: $general_mobile_width) {
        left: 0;
        right: 0;
        top: 4rem;
        position: fixed;
        width: 100%;
        border-radius: 0;
      }
    }

    h4,
    ul {
      margin: 0;
    }

    h4 {
      font-weight: 600;
      padding-left: 15px;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;

      span.name {
        margin-top: 3px;
      }
    }

    input {
      margin: 0 1rem;
      display: block;
      width: calc(100% - 2rem);
      border-radius: 0;
      margin-bottom: 0.5rem;
      border: 0;
      border-bottom: $table_border;
    }
    .area-close-component {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      background-color: #000;
      opacity: 0.4;
    }
  }
</style>
