import {
  mdiWalk,
  mdiBicycle,
  mdiCursorDefaultClick,
  mdiCursorDefaultClickOutline,
  mdiPhone,
  mdiTablePicnic,
  mdiBeer,
  mdiHamburger,
  mdiSoySauce,
  mdiFrenchFries,
  mdiSilverwareFork,
  mdiSilverwareVariant,
  mdiSale,
  mdiStar,
  mdiPizza,
  mdiCupcake,
  mdiCoffee,
  mdiTea,
  mdiIceCream,
  mdiGlassWine,
  mdiFoodCroissant,
  mdiGlassCocktail,
  mdiGlassMugVariant,
  mdiLiquor,
  mdiBottleWine,
  mdiDogSide,
} from "@mdi/js";

export const getSectionIcon = (sectionName) => {
  const name = sectionName.toLowerCase();

  if (name.includes("frie")) return mdiFrenchFries;
  if (name.includes("drink")) return mdiBeer;
  if (name.includes("main")) return mdiSilverwareVariant;
  if (name.includes("burger")) return mdiHamburger;
  if (name.includes("special")) return mdiStar;
  if (name.includes("deal")) return mdiSale;
  if (name.includes("pizza")) return mdiPizza;
  if (name.includes("dessert")) return mdiCupcake;
  if (name.includes("tea")) return mdiTea;
  if (name.includes("ice cream")) return mdiIceCream;
  if (name.includes("wine")) return mdiGlassWine;
  if (name.includes("cocktail")) return mdiGlassCocktail;
  if (name.includes("pastries")) return mdiFoodCroissant;
  if (name.includes("liqueur")) return mdiLiquor;
  if (name.includes("spirit")) return mdiBottleWine;
  if (name.includes("dog treat")) return mdiDogSide;
  if (name.includes("sauce") || name.includes("dip")) return mdiSoySauce;
  if (name.includes("coffee") || name.includes("cafe")) return mdiCoffee;
  if (name.includes("side") || name.includes("starter"))
    return mdiSilverwareFork;
  if (name.includes("beer") || name.includes("cider") || name.includes("ale"))
    return mdiGlassMugVariant;
};

export const getChannelIcon = (channelName) => {
  switch (channelName) {
    case "WalkUp":
      return mdiWalk;
    case "Delivery":
      return mdiBicycle;
    case "TableService":
      return mdiTablePicnic;
    case "ClickCollect":
      return mdiCursorDefaultClickOutline;
    case "ClickCollectAsap":
      return mdiCursorDefaultClick;
    case "Phone":
      return mdiPhone;
    default:
      break;
  }
};
