<template>
  <ServiceTab v-if="accessApproved" />
  <form v-else>
    <div class="access-code-container">
      <label for="access-code"><h3>Vendor Access Code</h3></label>
      <p v-if="showError" class="error-msg">{{ errorText }}</p>
      <div class="row code-inputs">
        <input
          v-for="(v, index) in values"
          v-bind:key="`pin` + index"
          type="number"
          min="0"
          max="9"
          :ref="iRefs[index]"
          :autoFocus="autoFocus && !loading && index === autoFocusIndex"
          :data-id="index"
          :value="v"
          v-on:input="onValueChange"
          v-on:focus="onFocus"
          v-on:keydown="onKeyDown"
          class="access-code-digit"
          :class="{ focus: index === autoFocusIndex }"
          pattern="[0-9]*"
          required
          aria-label="access code" />
      </div>
    </div>
  </form>
</template>

<script>
  const KEY_CODE = {
    backspace: 8,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    a: 65,
    z: 90,
  };

  import store from "@/store";
  import ServiceTab from "@/components/ServiceTab.vue";

  export default {
    data() {
      const { fields, values } = this;
      let vals;
      let autoFocusIndex = 0;
      if (values && values.length) {
        vals = [];
        for (let i = 0; i < fields; i++) {
          vals.push(values[i] || "");
        }
        autoFocusIndex = values.length >= fields ? 0 : values.length;
      } else {
        vals = new Array(fields).fill("");
      }

      this.iRefs = [];
      for (let i = 0; i < fields; i++) {
        this.iRefs.push(`input_${i}`);
      }

      return {
        accessApproved: false,
        showError: false,
        values: vals,
        autoFocusIndex,
        errorText: null,
      };
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      fields: {
        type: Number,
        default: 6,
      },
      autoFocus: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onFocus(e) {
        e.target.select(e);
      },
      onValueChange(e) {
        const index = parseInt(e.target.dataset.id);
        const { fields } = this;
        const value = e.target.value;

        if (isNaN(value)) {
          this.autoFocusIndex = index;
          this.showError = true;
          this.errorText = "Please enter an number";
          return;
        } else {
          e.target.value = e.target.value.replace(/[^\d]/gi, "");
          this.autoFocusIndex = index + 1;
          this.showError = false;
          this.errorText = null;
        }

        let next;

        let { values } = this;
        values = [...values];

        // if more than one digits were entered too fast,
        // then split the figures to the next input field
        if (value.length > 1) {
          let nextIndex = value.length + index - 1;
          if (nextIndex >= fields) {
            nextIndex = fields - 1;
          }
          next = this.iRefs[nextIndex];
          const split = value.split("");
          split.forEach((item, i) => {
            const cursor = index + i;
            if (cursor < fields) {
              values[cursor] = item;
            }
          });
          this.values = values;
        } else {
          next = this.iRefs[index + 1];
          values[index] = value;
          this.values = values;
        }

        if (next) {
          const element = this.$refs[next][0];
          element.focus();
          element.select();
        }

        this.triggerChange(values);
      },
      onKeyDown(e) {
        const index = parseInt(e.target.dataset.id);
        const prevIndex = index - 1;
        const nextIndex = index + 1;
        const prev = this.iRefs[prevIndex];
        const next = this.iRefs[nextIndex];

        switch (e.keyCode) {
          case KEY_CODE.backspace: {
            e.preventDefault();
            const values = [...this.values];
            if (this.values[index]) {
              values[index] = "";
              this.values = values;
              this.triggerChange(values);
            } else if (prev) {
              values[prevIndex] = "";
              this.$refs[prev][0].focus();
              this.values = values;
              this.triggerChange(values);
            }
            break;
          }
          case KEY_CODE.left:
            e.preventDefault();
            if (prev) {
              this.$refs[prev][0].focus();
            }
            break;
          case KEY_CODE.right:
            e.preventDefault();
            if (next) {
              this.$refs[next][0].focus();
            }
            break;
          case KEY_CODE.up:
          case KEY_CODE.down:
            e.preventDefault();
            break;
          default:
            if (e.keyCode >= KEY_CODE.a || e.keyCode <= KEY_CODE.z) {
              this.showError = true;
              this.errorText = "Please enter an number";
            }
            break;
        }
      },

      triggerChange(values = this.values) {
        const selectedId = store.getters["authentication/selected"]?.outlet?.id;
        if (selectedId == null || selectedId <= 0) {
          return;
        }

        const { fields } = this;
        const val = values.join("");

        if (val.length >= fields) {
          store.state.apiPrivate.client.endpoints.outlets
            .validateAccessCode(val, selectedId)
            .then((response) => {
              if (response.status == "200") {
                this.accessApproved = true;
              } else {
                this.showError = true;
                this.errorText = "Incorrect Access Code";
              }
            });
        }
      },
    },
    components: {
      ServiceTab,
    },
    mounted() {
      if (this.accessApproved) {
        return;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .access-code-container {
    padding: 1rem;
  }

  .code-inputs {
    gap: 0.5rem;
    justify-content: space-between;
    width: 100%;
  }

  input.access-code-digit {
    padding: 1rem 0rem;
    font-size: 1.5rem;
    width: inherit;
    text-align: center;
    border: 2px solid #999;
    color: #585858;
    border-radius: $input_radius;
    &::-webkit-inner-spin-button {
      display: none;
    }
    &.focus,
    &[autofocus="true"] {
      background: #ffecec;
    }
  }
  .error-msg {
    color: $col_error;
  }
</style>
