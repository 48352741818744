<template>
  <div class="modal-mask" @click.prevent="$emit('close')">
    <div class="modal-wrapper add-item-modal-wrapper">
      <div
        class="modal-container"
        @click.stop=""
        ref="modal-container"
        :class="{ tense: ifModalTense }">
        <div class="close-modal" @click.stop="$emit('close')">
          <Icon :path="mdiClose" :size="24" />
        </div>

        <div v-if="loading" class="loading-spinner_container">
          <LoadingSpinner />
        </div>

        <div v-else class="content-wrapper" ref="modal-content">
          <div class="header" ref="modal-header">
            <h2 class="item-name">{{ selectedItem.name }}</h2>
          </div>

          <div class="customise-content-wrapper" ref="modal-body">
            <p class="note mb-1">
              {{ selectedItem.description }}
            </p>

            <div v-if="comesWith?.length" class="mb-1 customise-content">
              <h3>Customise item</h3>
              <ItemComponentsList
                :componentList="comesWith"
                @modifyComponentAmount="modifyComponentAmount" />
            </div>

            <div v-if="extras?.length" class="mb-1 customise-content">
              <h3>Want some extra love?</h3>
              <ItemComponentsList
                :componentList="extras"
                :extras="true"
                @modifyComponentAmount="modifyComponentAmount" />
            </div>
          </div>

          <div class="add-items-wrapper" ref="modal-footer">
            <div class="column g-small pinned-details">
              <AllergensDisplay
                :allergensProp="this.allAllergens"
                :grayedOutAllergensProp="this.grayedOutAllergens" />

              <DietaryDisplay
                :dietariesProp="this.dietaries"
                :matchedDietariesProp="this.selectedItem.dietaryType" />
            </div>

            <NoteTextarea v-if="showNoteTextarea" @update="updateNote" />

            <div class="modal-actions">
              <button @click="showNoteTextarea = !showNoteTextarea">
                <Icon :path="mdiPencil" />
                Note
                <Icon :path="mdiChevronUp" :size="12" v-if="showNoteTextarea" />
                <Icon :path="mdiChevronDown" :size="12" v-else />
              </button>
              <div class="add-items-counter flex-center">
                <button
                  @click="decreaseItemCount"
                  :class="{ limit: lowerLimitReached }">
                  <Icon :path="mdiMinus" title="Remove" />
                </button>
                <p class="count">{{ addItemCount }}</p>
                <button
                  @click="increaseItemCount"
                  :class="{ limit: upperLimitReached }">
                  <Icon :path="mdiPlus" title="Add" />
                </button>
              </div>

              <button
                @click="
                  addItem(
                    selectedItem,
                    selectedSection,
                    addItemCount,
                    localComponentsState
                  )
                "
                class="add-to-cart center"
                :disabled="
                  loading ||
                  getCartLockByPaymentStatus ||
                  !getCart.cartKey ||
                  getCartConfirming
                ">
                <LoadingSpinner v-if="loading || getCartLockByPaymentStatus" />
                <span v-else-if="getCartConfirming">Confirming cart....</span>
                <span v-else-if="!getCart.cartKey"
                  >New cart geting ready....</span
                >
                <span v-else>Add for {{ formatMoney(getPrice) }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { itemHash } from "@tucktrucks/platform-base-public";
  import ItemComponentsList from "@/components/ItemComponentsList";
  import AllergensDisplay from "@/components/AllergensDisplay.vue";
  import DietaryDisplay from "@/components/DietaryDisplay.vue";
  import { resolveAllergens } from "@/helpers/resolveAllergens";
  import { resolveDietary } from "@/helpers/resolveDietary";
  import {
    mdiClose,
    mdiMinus,
    mdiPlus,
    mdiPencil,
    mdiChevronUp,
    mdiChevronDown,
  } from "@mdi/js";
  import NoteTextarea from "@/components/Walkups/NoteTextarea.vue";

  export default {
    components: {
      AllergensDisplay,
      DietaryDisplay,
      ItemComponentsList,
      NoteTextarea,
    },
    data() {
      return {
        loading: false,
        lowerLimit: 1,
        upperLimit: 10,
        lowerLimitReached: false,
        upperLimitReached: false,
        addItemCount: 1,
        selectedComponentAlterations: [],
        item: [],
        selectedItem: this.selectedItemProp,
        selectedItemComponents: [],
        localComponentsState: [],
        allAllergens: [],
        grayedOutAllergens: [],
        dietaries: [],
        allergens: [],
        note: null,
        modalBodyMaxHeight: null,
        ifModalTense: false,
        showNoteTextarea: false,
        mdiClose,
        mdiMinus,
        mdiPlus,
        mdiPencil,
        mdiChevronUp,
        mdiChevronDown,
      };
    },
    props: {
      serviceId: {
        type: Number,
      },
      selectedItemProp: {
        type: Object,
      },
      selectedSection: {
        type: Object,
      },
      allDietaries: {
        type: Array,
      },
      maxWidth: {
        type: String,
        default: "500",
      },
    },

    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getCartLockByPaymentStatus: "payment/getLock",
        getCartCreated: "cart/getCartCreatedStatus",
        getCartConfirming: "cart/getCartConfirming",
      }),

      // Locally defined sub-groups (of localComponentsState): comesWith and extras.
      // This distinction only exists for front end display purposes.
      // localComponentsState is the source of local component state.
      comesWith() {
        return this.localComponentsState.filter((x) => {
          // Included by default, AND is either removable or able to add more
          return x.levels.default > 0 && x.levels.minimum != x.levels.maximum;
        });
      },
      extras() {
        return this.localComponentsState.filter((x) => {
          // zero by default, min at least 1
          return x.levels.default === 0 && x.levels.maximum > 0;
        });
      },

      getPrice() {
        var price = this.selectedItem?.price ?? 0;

        // This is only additive for now, as we don't yet support discounts for removing components.
        for (const component of this.localComponentsState) {
          if (component.absolute > component.levels.default) {
            price +=
              (component.absolute - component.levels.default) *
              component.priceToAdd; //component.level
          }
        }

        price *= this.addItemCount;

        return price;
      },
    },

    methods: {
      updateNote(note) {
        this.note = note;
      },

      modifyComponentAmount({ id, delta, absolute }) {
        // check absolute isn't less than 0
        const a = absolute < 0 ? 0 : absolute;

        // update local component state array
        this.localComponentsState.forEach((comp) => {
          if (comp.id === id) {
            comp.delta = delta;
            comp.absolute = a;
          }
        });

        this.updateAllergens();
        this.updateDietary();
      },

      updateAllergens() {
        this.allergens = resolveAllergens(
          this.selectedItem,
          this.localComponentsState
        );

        this.allAllergens = this.allergens.all;
        this.grayedOutAllergens = this.allergens.grayedOut;
      },

      updateDietary() {
        this.dietaries = resolveDietary(
          this.selectedItem,
          this.localComponentsState
        );

        this.grayedOutDietaries = this.dietaries.grayedOut;
      },

      async addItem(item, section, amount, componentAlterations) {
        this.loading = true;

        const payload = itemHash(item, section, amount, componentAlterations);
        payload.note = this.note;

        await this.$store.dispatch("cart/upsertItem", payload);

        this.$emit("close");
      },

      decreaseItemCount() {
        this.addItemCount--;
        this.lowerLimitReached = false;
        if (this.addItemCount === 0) {
          this.addItemCount = this.lowerLimit;
          this.lowerLimitReached = true;
        }
      },
      increaseItemCount() {
        this.addItemCount++;
        this.upperLimitReached = false;
        if (this.addItemCount === this.upperLimit + 1) {
          this.addItemCount = this.upperLimit;
          this.upperLimitReached = true;
        }
      },
      async getStockStatus(componentId) {
        const response =
          await this.$store.state.apiPrivate.client.endpoints.kitchenStockComponents.getbyId(
            this.serviceId,
            componentId
          );
        if (response.status === 200) {
          return response.data.data.stockControl;
        }
        if (response.status === 500) {
          this.errorText = "Something went wrong, Please contact Support.";
        }
      },
      getModalMaxBodyHeight() {
        // release the previous height limitation
        this.modalBodyMaxHeight = null;
        this.ifModalTense = false;

        // get the new max height for the new window size
        setTimeout(() => {
          // Check window inner Height
          const windowHeight = window.innerHeight;
          const modalTopAndBottomPadding = windowHeight / 5;

          const modalHeaderHeight = this.$refs["modal-header"]?.clientHeight;
          const modalFooterHeight = this.$refs["modal-footer"]?.clientHeight;
          const modalNaturalHeight =
            this.$refs["modal-content"]?.offsetHeight +
            modalTopAndBottomPadding;

          if (modalNaturalHeight > windowHeight) {
            this.modalBodyMaxHeight =
              windowHeight -
              modalHeaderHeight -
              modalFooterHeight -
              modalTopAndBottomPadding +
              "px";
            this.ifModalTense = true;
          }
        }, 1);
      },
    },
    async created() {
      this.loading = true;

      try {
        const itemComponents = [];

        if (this.selectedItem.components.length > 0) {
          this.selectedItem.components.forEach((itemComponent) => {
            itemComponents.push(itemComponent);
          });
        }

        for (const itemComponent of itemComponents) {
          const { outOfStock, isServiceBlocked, levels } = itemComponent;

          itemComponent.delta = 0;
          itemComponent.absolute =
            outOfStock || isServiceBlocked ? 0 : levels.default;

          this.localComponentsState.push(itemComponent);
        }
      } catch (error) {
        window.log.error(error);
      } finally {
        this.updateAllergens();
        this.updateDietary();
        this.loading = false;
      }
    },

    mounted() {
      this.getModalMaxBodyHeight();
      window.addEventListener("resize", this.getModalMaxBodyHeight);
    },
    unmounted() {
      window.removeEventListener("resize", this.getModalMaxBodyHeight);
    },
  };
</script>

<style lang="scss">
  .item-name {
    margin-right: 30px;
    margin: 0;

    @media screen and (max-width: $general_mobile_width) {
      font-size: 1.2rem;
      line-height: 1em;
    }
  }

  p.item-description {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .add-to-cart {
    @include contained-button;
    max-width: 90%;
    margin: 1rem auto 1rem;

    &:hover {
      background: $col_beta_darker;
    }
  }

  .add-items-counter {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    margin: 1rem 0;

    @media screen and (max-width: $general_mobile_width) {
      margin: 0.5rem 0;
    }

    @media (orientation: landscape) {
      margin: 0.5rem 0;
    }

    p.count {
      width: 75px;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .component-alterations-list_item {
    display: flex;
    justify-content: space-between;
    padding: 0.35rem 1.5rem 0.35rem 0;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px dotted #ddd;
    }

    input {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    .outOfStock {
      cursor: default;
      opacity: 0.33;

      * {
        cursor: default;
      }
    }
  }

  .outOfStock label {
    cursor: not-allowed;
    color: #aaa;
  }

  .component-alterations-list_price {
    color: $col_faded;
    margin-top: 0;
  }

  .component-alterations-scroll {
    // overflow-y: auto;
    height: auto;
  }

  .limit {
    color: $col_faded;
  }

  .allergens-list {
    padding: 0;
  }

  .add-item-modal-wrapper {
    height: calc(100vh - $topbar_height);
    overflow: auto;
    display: flex !important;
    margin: auto;
    align-items: center;

    .modal-container {
      max-height: 90%;
      height: fit-content;
      width: 100%;

      &.tense {
        max-height: 80%;
        position: relative;
        height: 100%;
        top: -5%;
      }

      .header {
        height: fit-content;
        flex-shrink: 0;
      }

      .add-items-wrapper {
        height: fit-content;
        flex-shrink: 0;
      }

      .customise-content-wrapper {
        flex: 1;
      }

      .modal-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0.5rem auto 1rem;
        gap: 0.5rem;

        .add-items-counter {
          margin: 0;
          button {
            padding: 1rem;
            width: 3.5rem;
            height: 3.5rem;
          }
        }

        button.add-to-cart {
          height: 3.5rem;
          min-width: 200px;
          padding: 0.5rem 1rem;
          margin: 0;
          flex: 1 0 fit-content;
        }

        @media (orientation: landscape) and (max-width: $vertical_tablet_width) {
          gap: 1rem;
        }

        @media screen and (max-width: $mobile_max_width) {
          margin: 0.5rem auto 0;
          flex-direction: column;
          width: 100%;

          button.add-to-cart {
            width: 100%;
          }
        }

        @media (width <= $tablet_min_width) {
          position: fixed;
          bottom: 0;
          border-radius: 0;
          justify-content: flex-end;
          max-width: unset !important;

          &.tense {
            top: 0;
            height: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: 100%;

      > *:not(.add-items-wrapper) {
        padding: 0 1.5rem;
        @media (width <= $general_mobile_width) {
          padding: 0 0.75rem;
        }
      }

      .customise-content-wrapper {
        flex-grow: 1;
      }

      .add-items-wrapper {
        height: fit-content;
      }
    }

    @media screen and (max-width: $general_mobile_width) {
      padding: 0;

      .close-modal {
        top: 1rem;
        right: 1rem;
      }

      .tense {
        .note {
          margin-bottom: 0.25rem;
        }
      }
    }

    @media (orientation: landscape) {
      p.note {
        margin-bottom: 0.25rem;
      }
    }

    .customise-content-wrapper {
      overflow: auto;

      @media screen and (max-width: $general_mobile_width) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    .customise-content {
      h3 {
        font-size: 1.1rem;
        background: #f4f4f4;
        font-weight: 900;
        padding: 0.25rem 0.5rem;
        margin: 0;

        @media screen and (max-width: $general_mobile_width) {
          font-size: 0.875rem;
          font-weight: 600;
          padding: 0.3rem 0.5rem 0.2rem;
        }
      }

      ul.component-alterations-list {
        padding-top: 0;
        margin-top: 0;
      }
    }

    .info-button {
      top: 0;

      .material-design-icon {
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  p.outOfStock {
    color: $col_delta-lightest;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin: 0;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;

    span.material-icons {
      font-size: 0.7rem;
    }
  }

  .loading-spinner_container {
    margin-top: 5rem;
    width: 100%;
    text-align: center;
    min-height: 200px;
  }

  .fixed-controls {
    position: sticky;
    background: white;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }

  .fixed-ingredients {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 0.8rem;

    @media screen and (max-width: $general_mobile_width) {
      flex-direction: column;

      .tense & {
        gap: 0;
        margin-bottom: 0.5rem;
      }
    }

    &_title {
      font-weight: bold;
      margin-top: 0;
    }

    &_list {
      margin-top: 0;
    }

    li {
      font-size: 0.8rem;
      display: inline;
      line-height: 1.1em;
    }

    li:not(:last-child)::after {
      content: ", ";
      display: inline-block;
      padding-right: 0.25rem;
    }
  }

  .pinned-details {
    margin: 0 1.5rem;

    @media screen and (max-width: $general_mobile_width) {
      margin: 0 1rem;
    }
  }

  .tense {
    .allergen-dietary-section {
      max-height: 50px;
      overflow-y: auto;

      @media (orientation: landscape) {
        max-height: 30px;
      }
    }
  }
</style>
