<template>
  <div class="item-name-wrapper" :id="item.name + orderId">
    <p class="name">
      <span class="name-label" :class="{ disabled: disabled }">
        <span class="amount">{{ item.amount }}×</span>
        {{ item.name }}
        <span class="number"
          ><Icon :path="mdiPound" :size="10" />{{ item.number }}</span
        >
      </span>

      <Icon
        :path="mdiNoteAlertOutline"
        :size="16"
        class="note"
        v-if="item.note"
        :class="{ clickable: !item.note }"
        @click="ifShowNote = !ifShowNote" />

      <Icon
        v-if="this.item.status == 'HandedOver'"
        :path="mdiCheckboxMarked"
        :size="16"
        class="item-status"
        :class="{ disabled: disabled }"
        @click="if (!disabled) cycle();" />
      <Icon
        v-else-if="this.item.status == 'Ready'"
        :path="mdiCheckboxIntermediateVariant"
        :size="16"
        class="item-status"
        :class="{ disabled: disabled }"
        @click="if (!disabled) cycle();" />
      <Icon
        v-else
        :path="mdiCheckboxBlankOutline"
        :size="16"
        class="item-status"
        :class="{ disabled: disabled }"
        @click="if (!disabled) cycle();" />
    </p>

    <div
      v-if="item.note != null && item.note != '' && ifShowNote"
      class="note-detail">
      <!-- <h5>Please note:</h5> -->
      <p>{{ item.note }}</p>
      <!-- <button type="button" class="small close" @click="ifShowNote = false">
        Close
      </button> -->
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import {
    mdiNoteAlertOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxIntermediateVariant,
    mdiCheckboxMarked,
    mdiPound,
  } from "@mdi/js";

  export default {
    data() {
      return {
        ifShowNote: false,
        mdiNoteAlertOutline,
        mdiCheckboxBlankOutline,
        mdiCheckboxIntermediateVariant,
        mdiCheckboxMarked,
        mdiPound,
      };
    },

    props: {
      item: { default: {} },
      orderId: { default: null },
      disabled: { default: false },
    },

    emits: ["change"],

    methods: {
      showNote() {},

      cycle() {
        let status = this.item.status;

        switch (status) {
          default:
          case "NotDone":
            status = "Ready";
            break;
          case "Ready":
            status = "HandedOver";
            break;
          case "HandedOver":
            status = "NotDone";
            break;
        }

        store.state.apiPrivate.client.endpoints.orderItemStatus
          .update(status, this.item.id)
          .then(() => {
            this.$emit("change", { item: this.item, status: status });
          });
      },
    },
  };
</script>

<style lang="scss">
  .item-name-wrapper {
    .name {
      margin-left: 1.75rem;
      position: relative;
      margin-top: unset;
      margin-bottom: unset;
      @include flex($jc: flex-end);

      .disabled {
        filter: grayscale(1);
      }

      .name-label {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        .number {
          display: flex;
          flex-direction: row;
          margin-left: 1rem;
          font-size: 0.8rem;
          color: #999;

          .mdi-icon {
            position: relative;
            top: -3px;
          }
        }
      }

      &.clickable {
        cursor: pointer;
      }

      .note {
        color: $col_alpha;
        background: $col_alpha-lightest-2;
        border-radius: 50%;
        padding: 0.25rem;
      }

      .item-status {
        color: $col_beta;
        padding: 0.25rem;
      }
    }
    .note-detail {
      padding: 0.5rem;
      width: 100%;
      max-height: 200px;
      background: $col_alpha-lightest-2;

      h5 {
        margin: unset;
        color: $col_alpha;
      }

      p {
        word-break: break-all;
      }

      button.close {
        background: white !important;
        border: none !important;
      }
    }
  }
</style>
