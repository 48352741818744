<template>
  <button
    class="btn_checkout"
    @click="$emit('checkoutScreen')"
  >
    checkout<Icon :path="mdiChevronRight" />
  </button>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';

export default {
  data() {
    return {
      mdiChevronRight
    }
  },
  emits: ['checkoutScreen']
}
</script>

<style lang="scss" scoped>
.btn_checkout {
  min-width: 120px;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border: none;
  cursor: pointer;
  font-size: 1.15rem;
  color: white;
  background-color: $col_beta;

  &:hover {
    background: $col_beta-darker;
  }
}
</style>