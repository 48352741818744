<template>
  <div v-if="!loading && statusTimeline.length == 0" class="no-orders tight">
    <p>No walk-up orders to be produced</p>
  </div>

  <div v-if="!loading && statusTimeline.length > 0" class="ticket-groups">
    <div
      v-for="group in statusTimeline"
      :key="group.key() + status"
      class="ticket-group-container_outer"
      :class="status">
      <div
        class="ticket-group-container"
        :class="{
          active: group
            .getSource()
            .every((entry) => this.selectedTickets.includes(entry.order.id)),
        }">
        <div class="ticket-container">
          <OrderTicket
            v-for="entry in group"
            :clubPointRewardLevel="clubPointRewardLevel"
            :key="entry.order.id"
            :entry="entry"
            :group="group.getSource()"
            :groupkey="group.key() + status"
            :viewMode="viewMode"
            :viewSize="'normal'"
            :updatingStatus="updatingStatus"
            :ifWalkUpOrderView="true"
            :ifByPlacedAt="true"
            :allowEatOnPremises="this.allowEatOnPremises"
            @fireOrderStatusModal="showOrderStatus(entry.order)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderTicket from "@/components/OrderTicket.vue";
  import { mapGetters } from "vuex";
  import store from "@/store";

  export default {
    components: {
      OrderTicket,
    },
    computed: {
      ...mapGetters({
        selectedStatus: "ticketSelect/selectedStatus",
        selectedTickets: "ticketSelect/selectedTickets",
        selectedGroups: "ticketSelect/selectedGroups",
      }),
    },
    props: {
      statusTimeline: {
        type: Object,
      },
      status: {
        type: String,
      },
      loading: {
        type: Boolean,
      },
      timeline: {
        type: Object,
      },
      updatingStatus: {
        type: Boolean,
      },
      viewMode: { type: String },
      allowEatOnPremises: {
        type: Boolean,
        default: false,
      },
      showGroupTitle: {
        type: Boolean,
        default: true,
      },
      clubPointRewardLevel: { type: Number },
    },
    methods: {
      handleTicketSelection(payload) {
        store.dispatch("ticketSelect/handleTicketSelection", payload);
      },
      handleGroupSelection(payload) {
        store.dispatch("ticketSelect/handleGroupSelection", payload);
      },
    },
  };
</script>

<style lang="scss">
  .no-orders {
    @include no-orders;
  }
  .ticket-group-container_outer {
    @include ticket-group;
  }
</style>
