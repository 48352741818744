<template>
  <div class="sub-header-item">
    <div
      class="channel"
      :class="getChannelClass(order)"
      @click="$emit('click')">
      {{ getChannelLetter(order) }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      order: {
        type: Object,
      },
    },
    emits: ["click"],
    methods: {
      getChannelClass(order) {
        switch (order.channel) {
          case "ClickCollect":
            return "c";
          case "ClickCollectAsap":
            return "ca";
          case "WalkUp":
            return "w";
          case "Delivery":
            return "d";
          case "TableService":
            return "t";
          case "Phone":
            return order.paymentPending ? "pU" : "pP";
          default:
            return "u";
        }
      },

      getChannelLetter(order) {
        switch (order.channel) {
          case "ClickCollect":
            return "C";
          case "ClickCollectAsap":
            return "C";
          case "WalkUp":
            return "W";
          case "Delivery":
            return "D";
          case "Phone":
            return "P";
          case "TableService":
            return "T";
          default:
            return "?";
        }
      },
    },
  };
</script>

<style lang="scss">
  .channel {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 2.6rem;
    font-weight: bold;
    border-radius: 50%;
    color: #fff;
    text-align: center;

    &.c {
      background: $col_alpha;
    }

    &.ca {
      background: $col_delta;
    }

    &.w {
      background: $col_beta-darker;
    }

    &.d {
      background: $col_gamma;
    }

    &.t {
      background: $col_beta;
    }

    &.pU {
      background-image: url("/images/star-20.svg");
    }
    &.pP {
      background: #9c88ff;
    }
  }
</style>
