<template>
  <div>
    <div class="menubar">
      <router-link class="logo-link" :to="logoRoutePath">
        <img
          v-if="!mobileLayout"
          class="logo"
          alt="Tuck Trucks Logo"
          height="16"
          width="137"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')
          " />
        <img
          v-else
          class="logo"
          alt="Tuck Trucks Logo"
          height="20"
          width="20"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-icon-small.svg')
          " />
      </router-link>

      <ServicePicker v-if="!mobileLayout" />

      <div class="controls-container">
        <FilterBar :service="service" :key="$route.params.serviceId" />

        <button
          v-if="
            selected?.service?.date == this.today && disableWalkups === false
          "
          @click="
            $emit('expandTabs');
            $emit('selectTab', 2);
            $emit('swapToTabView');
          "
          class="new-order-btn">
          <Icon :path="mdiBasketPlusOutline" :size="20" />
        </button>

        <button
          class="mute-toggle-btn"
          @click="toggleMute"
          aria-label="toggle bell">
          <Icon :path="mdiBellRing" v-if="settingsNotificationSounds" />
          <Icon :path="mdiBellOff" v-else />
        </button>

        <span @click="$emit('swapView')" v-if="mobileLayout">
          <Icon
            :path="mdiSwapHorizontalBold"
            :size="32"
            title="Swap View"
            class="swap-view"
            :class="{ 'mobile-layout': mobileLayout }" />
        </span>

        <MenuToggle
          @logout="logout"
          @toggleFullscreen="$emit('toggleFullscreen')" />
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import ServicePicker from "@/components/ServicePicker/ServicePicker.vue";
  import { mapGetters } from "vuex";
  import { LocalDate } from "@js-joda/core";
  import {
    mdiBellRing,
    mdiBellOff,
    mdiSwapHorizontalBold,
    mdiMenu,
    mdiOverscan,
    mdiAccountCircle,
    mdiBasketPlusOutline,
    mdiSilverware,
  } from "@mdi/js";
  import MenuToggle from "./MenuToggle.vue";
  import FilterBar from "@/components/ViewControls/FilterBar";

  export default {
    components: {
      ServicePicker,
      MenuToggle,
      FilterBar,
    },

    data() {
      return {
        showModal: false,
        productionTime: "50 min",
        service: null,

        mdiBellRing,
        mdiBellOff,
        mdiSwapHorizontalBold,
        mdiMenu,
        mdiOverscan,
        mdiAccountCircle,
        mdiBasketPlusOutline,
        mdiSilverware,
      };
    },

    computed: {
      ...mapGetters({
        settingsNotificationSounds: "settings/getNotificationSounds",
        selected: "authentication/selected",
      }),
      today() {
        return LocalDate.now();
      },

      logoRoutePath() {
        const serviceId = this.selected.serviceId;
        if (serviceId) {
          return {
            name: "/service/id",
            params: { serviceId },
          };
        }
        return { name: "/services" };
      },
    },

    methods: {
      logout() {
        store.dispatch("authentication/logout");
      },
      toggleMute() {
        store.dispatch("settings/toggleNotificationSounds");
      },
      async getService() {
        const serviceId = parseInt(this.$route.params.serviceId);

        if (!serviceId) return (this.loading = false);

        const data = await store.getters["repoServices/getByIdWithQuery"](
          serviceId,
          {
            includeMenu: true,
            includeVendor: true,
            methods: 12,
          }
        );

        this.service = data.service;
        const menu = await store.getters["repoMenus/getById"](
          data.service.menuId
        );
        this.service["menu"] = menu;
      },
    },

    emits: [
      "swapView",
      "expandTabs",
      "selectTab",
      "swapToTabView",
      "toggleFullscreen",
    ],

    props: {
      mobileLayout: {
        type: Boolean,
      },
      disableWalkups: {
        type: Boolean,
        default: false,
      },
      totalProductionTime: { type: String },
    },

    mounted() {
      this.getService();
    },
  };
</script>

<style lang="scss" scoped>
  .menubar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include flex($jc: space-between, $wrap: nowrap);
    z-index: 10;
    padding-right: 0.5rem;
    height: $topbar_height;
    color: $col_softblack;
    background: $col_offwhite;
    box-shadow: 0 2px 5px #ddd;
    @media screen and (max-width: $general_mobile_width) {
      padding-left: 0;
      padding-right: 1rem;
    }
  }

  .logo-link {
    margin-top: 7px;
  }

  img.logo {
    height: 16px;
    padding: 0;
    margin: 1rem;
    width: auto;
  }

  img.logo-icon {
    height: 24px;
    padding: 0;
    margin: 1rem;
    display: none;
    position: relative;
    top: -5px;
    @media screen and (max-width: 425px) {
      display: block;
    }
  }

  .controls-container {
    @include flex($wrap: nowrap, $g: 1rem);

    @media screen and (max-width: $general_mobile_width) {
      gap: 0.5rem;
    }

    button {
      border: 0;
      border-radius: $button_radius;
      box-shadow: none;
      @include button-base(
        $p: 0.25rem,
        $h: 2.5rem,
        $w: fit-content,
        $fs: 0.875rem
      );
    }

    .new-order-btn {
      @include contained-button($p: 0.25rem 1rem, $h: 2.5rem, $fs: 0.75rem);
    }

    .walk-up-order-btn {
      @include contained-button(
        $p: 0.25rem 1rem,
        $h: 2.5rem,
        $fs: 0.75rem,
        $bg: white
      );
      width: 120px;
      color: black;
    }
  }

  .menu {
    display: block;
    cursor: pointer;
  }

  .menubar .swap-view {
    cursor: pointer;
    display: flex;
  }

  .fullscreen-btn {
    color: $col_beta;
    margin-right: 0.75rem;
    cursor: pointer;

    &:selected {
      background: transparent;
    }

    &:hover {
      background: transparent;
      color: $col_beta-lighter;
    }
  }

  .mute-toggle-btn {
    @include button-base;
  }

  .app-menu {
    width: 80%;

    &_item {
      cursor: pointer;
      @include flex;
    }
  }

  .app-menu_item-inner {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    text-decoration: underline;

    .user-icon {
      margin: 0.25rem 0.5rem 0 0;
      color: $col_beta-darker;
    }
  }
</style>
