<template>
  <div class="order-customer">
    <div class="order-cell" v-if="order?.customer?.id > 0">
      <label>Club Points</label>
      <ClubAccount
        :clubPointRewardLevel="clubPointRewardLevel"
        :orderCounts="order.customerOrdersOutlet" />
    </div>

    <OrderCell label="Customer" :value="order?.customer?.name ?? 'Guest'" />
    <div class="order-cell" v-if="order?.customer?.phone">
      <label>Phone Number:</label>
      <span>
        <a :href="`tel:${order?.customer?.phone}`">
          {{ order?.customer?.phone }}
        </a>
      </span>
    </div>
    <div class="order-cell">
      <label for="email">Email</label>
      <span class="email">
        <input type="email" placeholder="Email" v-model="customerEmail" />
        <button @click="sendReceipt" :disabled="checkIfDisabled">
          Send Receipt
        </button>
      </span>
    </div>
  </div>
</template>

<script lang="js">
    import { useToast } from "vue-toastification";
    import store from "@/store";
    import OrderCell from "@/components/Orders/OrderCell";
    import { mdiProgressStarFourPoints } from "@mdi/js";
    import ClubAccount from "@/components/Club/ClubAccount.vue";

    export default {
      components: {
      OrderCell,
      ClubAccount
  },

      data() {
        return {
          mdiProgressStarFourPoints,
          customerEmail: "",
          note: '',
        };
      },

      props: {
        order: {
          type: Object,
        },
        clubPointRewardLevel: { type: Number },
      },

      computed: {
        checkIfDisabled() {
          if (
            (this.customerEmail == null) || (this.customerEmail.length < 1)
          ) {
            return true;
          } else {
            const validator = require("email-validator");
            return !validator.validate(this.customerEmail);
          }
        },


      },

      methods: {
        sendReceipt() {
          this.sendingReceiptText = "Sending...";

          store.state.apiPrivate.client.endpoints.orders
            .sendCustomerReceipt(this.customerEmail, this.order.id)
            .then((response) => {
              if (response.status >= 200 && response.status <= 204) {
                this.sendingReceiptText = "Resend";

                const toast = useToast();
                toast.success(
                  "VAT Receipt has been sent to the customer at the email address provided."
                );
                this.$emit("close");
                return;
              }

              this.sendingReceiptText = "Send Receipt";
              return Promise.reject("Failed to send email.");
            });
        },
      },

      mounted() {
        this.customerEmail = this.order?.customer?.email;
      }
    }
</script>

<style lang="scss">
  .order-customer {
    display: flex;
    flex-wrap: wrap;

    input[type="email"] {
      height: 30px;
      padding: 0;
      width: 230px;
      font-size: 1rem;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $col_faded;
      font-weight: 400;

      @media screen and (max-width: $general_mobile_width) {
        margin-bottom: 10px;
        text-align: center;
      }
    }

    button {
      margin-left: 10px;
      @include contained-button($h: 3rem, $p: 0.5rem, $fs: 0.875rem);
      &:hover {
        background: $col_beta_darker;
      }
    }
  }

  .order-cell {
    width: 33%;
    .email {
      @include flex;
    }

    .club {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
