<template>
  <div class="tabs">
    <div
      class="tab-btn"
      v-on:click="changeTab(1)"
      v-bind:class="[activeCartTab === 1 ? 'active' : '']">
      Items
    </div>
    <div
      class="tab-btn"
      v-on:click="changeTab(2)"
      v-bind:class="[activeCartTab === 2 ? 'active' : '']">
      Components
    </div>
  </div>

  <div v-if="activeCartTab === 1" class="cart-tab-content">
    <StockControlItems />
  </div>

  <div v-if="activeCartTab === 2" class="cart-tab-content">
    <StockControlComponents />
  </div>
</template>

<script>
  import StockControlItems from "@/components/StockControl/StockControlItems.vue";
  import StockControlComponents from "@/components/StockControl/StockControlComponents.vue";
  import store from "@/store";

  export default {
    data() {
      return {
        activeCartTab: 1,
        serviceId: parseInt(this.$route.params.serviceId),
        showModal: false,
        stockItems: [],
      };
    },

    components: {
      StockControlItems,
      StockControlComponents,
    },

    methods: {
      async getList() {
        this.stockItems = [];
        const response =
          await store.state.apiPrivate.client.endpoints.kitchenStockItems.getAll(
            this.serviceId
          );
        response.data.data.forEach((item) => {
          this.stockItems.push(item);
        });
      },

      async updateItem(item) {
        await store.state.apiPrivate.client.endpoints.items.updateinStock(
          item.outOfStock,
          item.id
        );
        this.getList();
      },

      editRow() {
        this.showModal = true;
      },

      changeTab(tabNumber) {
        window.localStorage.setItem("t4", tabNumber);
        this.activeCartTab = tabNumber;
      },
    },

    mounted() {
      try {
        this.activeCartTab = parseInt(window.localStorage.getItem("t4"));
      } catch {
        // empty
      }

      if (this.activeCartTab == null || isNaN(this.activeCartTab)) {
        this.activeCartTab = 1;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .warning {
    font-weight: bold;
  }

  .warning-1 {
    background: #fff5a6;
  }

  .warning-2 {
    background: #ffc4c4;
  }

  .product-name {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      background: #eee;
    }
  }

  .edit-table {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
  }

  .editable-value {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .item-row {
    &.out-of-stock {
      background: #f9ebeb;
    }
  }
  .item-name {
    width: 100%;
    font-size: 1rem;

    &.out-of-stock {
      color: rgb(146, 22, 49);
    }
  }

  /* Toggle switch styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle {
    position: absolute;
    cursor: pointer;
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .toggle:before {
    position: absolute;
    content: "";
    border-radius: 5px;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .toggle {
    background-color: $col_beta-darker;
  }

  input:focus + .toggle {
    box-shadow: 0 0 1px black;
  }

  input:checked + .toggle:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
</style>
