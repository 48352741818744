<template>
  <div class="group-select" :class="{ 'non-related': ifGroupNonRelated }">
    <div class="checkbox">
      <LoadingSpinner v-if="loading" />
      <input
        v-else
        :checked="checked"
        :disabled="disabled"
        @click="$emit('click')"
        type="checkbox"
        name=""
        id="" />
    </div>
    <h4>
      <span class="count">{{ groupLength }}</span>
      {{ groupType }}
      <span>{{ time }}</span>
    </h4>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      time: { type: String },
      groupType: { type: String },
      loading: { type: Boolean },
      checked: { type: Boolean },
      disabled: { type: Boolean },
      groupLength: { type: Number },
      group: {},
    },

    emits: ["click"],

    computed: {
      ...mapGetters({
        displayedMenuSectionItems: "filters/getMenuSectionItems",
        ifShowAllMenuSections: "filters/getIfShowAllMenuSections",
      }),

      ifGroupNonRelated() {
        if (this.ifShowAllMenuSections) return false;

        const groupItems = this.group.map(({ order }) => order.items).flat();
        const displayedItems = groupItems.filter(({ item }) =>
          this.displayedMenuSectionItems.includes(item.id)
        );
        return displayedItems.length === 0;
      },
    },
  };
</script>
<style lang="scss">
  .group-select {
    .count {
      font-size: 0.875rem;
      position: relative;
      top: -2px;
      padding-right: 0.15rem;
      padding-left: 0.5rem;
    }

    &.non-related {
      h4 {
        color: $col_faded;
      }

      input[type="checkbox"] {
        border-color: $col_disabled;
      }
    }
  }
</style>
