<template>
  <button type="button" class="walkup-view-swap__btn" @click="$emit('click')">
    <template v-if="showOrderView">
      <Icon :path="mdiCartPlus" :size="16" />Place Order
    </template>
    <template v-else>
      <Icon :path="mdiClipboardTextOutline" :size="16" />
      View Orders
    </template>
  </button>
</template>

<script>
  import { mdiCartPlus, mdiClipboardTextOutline } from "@mdi/js";
  export default {
    data() {
      return { mdiCartPlus, mdiClipboardTextOutline };
    },
    props: {
      showOrderView: {
        type: Boolean,
      },
    },
    emits: ["click"],
  };
</script>

<style lang="scss" scoped>
  .walkup-view-swap__btn {
    @include button-base($p: 0.25rem, $h: 2.5rem, $fs: 0.785rem, $w: auto);
    width: calc(25% - 0.5rem) !important;
    border: 0;
    border-radius: 0;
    flex-shrink: 0;
    border-right: 1px solid lightgray !important;
    margin-right: 0.25rem;
    text-align: left;
  }
</style>
