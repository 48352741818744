<template>
  <div class="order-note">
    <textarea
      v-model="note"
      placeholder="Order Note"
      :disabled="(!ifEditing && order.note) || ifUpdating"
      @input="(e) => updateNote(e.target.value)"
      rows="6">
    </textarea>
    <div class="actions">
      <button
        class="send"
        :disabled="ifUpdating"
        @click="ifEditing = !ifEditing"
        v-if="!ifEditing && order.note">
        <Icon :path="mdiPencil" :size="12" />Edit
      </button>
      <button
        class="send"
        @click="sendNote"
        :disabled="note === order.note || ifUpdating">
        <template v-if="ifUpdating">Updating Note...</template>
        <template v-else>
          <Icon :path="mdiCheckBold" :size="12" /> Send Note</template
        >
      </button>
    </div>
  </div>
</template>

<script>
  import { mdiCheckBold, mdiPencil } from "@mdi/js";
  import { useToast } from "vue-toastification";
  import store from "@/store";

  export default {
    data() {
      return {
        mdiPencil,
        mdiCheckBold,
        note: null,
        ifEditing: false,
        ifUpdating: false,
      };
    },
    props: {
      order: {
        type: Object,
      },
    },
    methods: {
      updateNote(note) {
        this.note = note;
      },
      sendNote() {
        this.ifEditing = false;
        this.ifUpdating = true;

        store.state.apiPrivate.client.endpoints.orderNotes
          .update(this.note, this.order.id)
          .then(() => {
            const toast = useToast();
            toast.success("Updated note on order.");
          })
          .catch((err) => {
            window.log.error("Could not update order note.", err);

            const toast = useToast();
            toast.error("Could not update order note.");
          })
          .finally(() => {
            this.ifUpdating = false;
          });
      },
    },
    mounted() {
      this.note = this.order?.note;
    },
  };
</script>

<style lang="scss">
  .order-note {
    width: 100%;
    @include flex($ai: flex-start);
    padding: 1rem 0;

    textarea {
      flex: 1;
      min-width: 300px;
      height: 100px;
      width: 100%;
      padding: 0.5rem;
      border: 1px solid $col_gray;
      border-radius: $button_radius;
      font-size: 1rem;
    }

    .actions {
      @include flex($ai: flex-start, $g: 0.25rem);
      button {
        flex-shrink: 0;
        height: 4rem;
        padding: 1rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }
</style>
