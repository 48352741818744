<template>
  <div class="note-bar__outer">
    <div class="note-bar">
      <p class="note-bar__status" @click="ifShowNote = !ifShowNote">
        <Icon :path="mdiNoteAlertOutline" :size="16" />
        {{ this.singleLine }}
      </p>
      <div class="note-detail" v-if="ifShowNote">
        <p>{{ order.note }}</p>
        <!-- <button type="button" class="small close" @click="ifShowNote = false">
          Close
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mdiNoteAlertOutline } from "@mdi/js";

  export default {
    data() {
      return { mdiNoteAlertOutline, ifShowNote: false };
    },
    props: { order: { default: {} } },

    computed: {
      singleLine() {
        return this.ifShowNote
          ? "Order Note"
          : this.order.note.replace(/\s+/g, " ");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .note-bar {
    font-size: 0.875rem !important;
    color: $col_alpha;
    max-width: 400px;

    h5 {
      margin: unset;
    }

    .note-detail {
      width: 100%;
      border-top: 1px solid $col_alpha;
      button.close {
        background: white !important;
        border: none;
        width: 100%;
        margin-bottom: 0.5rem;
      }

      p {
        word-break: break-all;
      }
    }

    &__outer {
      width: 100%;
      background: $col_alpha-lightest-2;
      padding: 0 5%;
    }

    &__status {
      @include flex($jc: flex-start, $g: 0.25rem, $wrap: nowrap);
      margin: 0;
      cursor: pointer;
      font-weight: 600;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        padding: 0.5rem 0;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
</style>
