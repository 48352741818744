<template>
  <div class="cancellation-process-container">
    <Form @submit="cancel">
      <div class="input-wrapper">
        <div>
          <h4>Reason for cancellation</h4>
          <DropDown
            :options="vendorCancellationReasons"
            :selectedIndex="0"
            @changed="setReason"
            :required="true" />
        </div>
        <div>
          <p v-if="this.order.method == 'FoodAppWeb'">
            By clicking 'submit' you are confirming you want to cancel this
            order (#{{ this.order.id }}). An email will be sent to notify the
            customer.
          </p>
          <p v-else>
            By clicking 'submit' you are confirming you want to cancel this
            order (#{{ this.order.id }}).
          </p>
          <br />
          <button type="submit" class="btn_checkout" :disabled="loading">
            <LoadingSpinner v-if="loading" />
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
  import ApiOrder from "@tucktrucks/platform-base-public";
  import store from "@/store";
  import DropDown from "@/components/DropDown.vue";
  import { ApiOrderCancellation } from "@tucktrucks/platform-base-private";
  import { useToast } from "vue-toastification";

  export default {
    props: {
      order: {
        type: ApiOrder,
      },
    },
    emits: ["close"],
    data() {
      return {
        loading: false,
        selectedReason: null,
        vendorCancellationReasons: [
          { id: 1, name: "Kitchen Issue", value: "KitchenIssue" },
          { id: 2, name: "Stock Issue", value: "StockIssue" },
        ],
      };
    },
    components: {
      DropDown,
    },
    methods: {
      async cancel() {
        store.state.apiPrivate.client.endpoints.orders
          .cancel(
            new ApiOrderCancellation({
              type: "ServiceCancellation",
              reason: this.selectedReason.value,
            }),
            this.order.id
          )
          .then((response) => {
            if (response.status >= 200 && response.status <= 204) {
              this.$emit("close");
              const toast = useToast();
              toast.success("Order Cancelled");
            } else {
              const toast = useToast();
              toast.error("Problem cancelling order");
              this.$emit("close");
            }
          });
      },
      setReason(reason) {
        this.selectedReason = reason;
      },
    },
    mounted() {
      this.selectedReason = this.vendorCancellationReasons[0];
    },
  };
</script>

<style lang="scss" scoped>
  .cancellation-process-container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    position: relative;
    //height: 8rem;
    margin: 0 auto;
  }
</style>
