<template>
  <Icon :path="mdiChevronUp" v-if="ifExpand" />
  <Icon :path="mdiChevronDown" v-else />
</template>

<script>
  import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
  export default {
    data() {
      return { mdiChevronDown, mdiChevronUp };
    },
    props: {
      ifExpand: { type: Boolean },
    },
  };
</script>
