<template>
  <div class="order-header">
    <div class="order-header__row">
      <OrderCell
        class="order-header__cell"
        label="Order Number"
        :value="order.number" />
      <OrderCell
        class="order-header__cell"
        label="Channel"
        :value="getChannelText" />
      <OrderCell
        class="order-header__cell"
        label="Order ID"
        :value="order.id" />
    </div>
    <div class="order-header__row">
      <OrderCell
        class="order-header__cell"
        label="On Premises?"
        :value="order?.eatOnPremises ? 'Eat In' : 'Take Out'" />
      <OrderCell
        class="order-header__cell"
        label="Order Placed"
        :value="getPlacedAt" />
      <OrderCell
        class="order-header__cell"
        label="Collection Time"
        :value="getCollectionTime" />
    </div>

    <p class="order-header__payment-warning" v-if="order.paymentPending">
      <Icon :path="mdiAlertDecagram" />
      This order uses the {{ formatChannel(order.channel) }} channel, and has
      not yet been funded fully. The customer needs to pay an additional
      <b>{{ formatMoney(order.paymentPending) }}</b> before collecting the
      order.
    </p>
  </div>
</template>

<script lang="js">
  import { LocalDateTime, LocalTime, DateTimeFormatter } from "@js-joda/core";
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import { mdiAlertDecagram } from "@mdi/js";
  import OrderCell from "@/components/Orders/OrderCell";
  import Icon from "@/components/Icon.vue";

  export default {
      components: {
        OrderCell,
        Icon
      },

      data() {
        return {
          mdiAlertDecagram
        };
      },

      props: {
        order: {
          type: Object,
        },
      },

      computed: {
        getPlacedAt() {
          return LocalDateTime.parse(this.order?.timings.placedAt).format(
            DateTimeFormatter.ofPattern("dd/MM HH:mm")
          );
        },

        getCollectionTime() {
          return LocalTime.parse(this.order?.timings.selectedSlot).format(
            DateTimeFormatter.ofPattern("HH:mm")
          );
        },

        getChannelText( ){
          const orderChannel = this.order?.channel;
          return orderChannel !== 'TableService' ? formatChannel(orderChannel) : formatChannel(orderChannel) + ' #' + this.order?.tableNumber
        }
      },

      methods: {
        formatChannel
      }
    }
</script>

<style lang="scss">
  .order-header {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-grow: 1;

      .order-cell {
        width: 33%;
      }
    }

    &__payment-warning {
      border: 1px solid #f9ca24;
      background: #f6e58d;
      border-radius: 8px;
      padding: 0.5em;
      font-size: 0.9em;

      .mdi-icon {
        float: left;
        margin: 0.6em;
      }
    }
  }
</style>
