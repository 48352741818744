<template>
  <div>
    Hey future you, the entire kitchen app needs rewriting, but start by just
    unwiring the OrderTicket from "entry" and wire to a view model containing
    the order. We're currently basing all this around a random
    IGroupedEnumerable object, which probably isn't really needed any more. I
    believe we can remove our dependency on npm <code>LINQ</code> entirely.
    <div
      v-for="ticket in tickets"
      :key="ticket.id"
      class="ticket-group-container">
      <div class="ticket-container">
        <OrderTicket
          :clubPointRewardLevel="clubPointRewardLevel"
          :key="ticket.id"
          :viewMode="viewMode"
          :viewSize="'normal'"
          :updatingStatus="updatingStatus"
          :allowEatOnPremises="this.allowEatOnPremises" />
      </div>
    </div>
  </div>
</template>

<script>
  import OrderTicket from "@/components/OrderTicket.vue";

  export default {
    data() {
      return {};
    },

    props: {
      tickets: {
        type: Array,
      },
    },

    components: { OrderTicket },

    computed: {},
  };
</script>
