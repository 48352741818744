<template>
  <div class="tabs">
    <div
      class="tab-btn"
      v-on:click="changeTab(1)"
      v-bind:class="[activeCartTab === 1 ? 'active' : '']">
      Revenue
    </div>
    <div
      class="tab-btn"
      v-on:click="changeTab(2)"
      v-bind:class="[activeCartTab === 2 ? 'active' : '']">
      Items
    </div>
    <div
      class="tab-btn"
      v-on:click="changeTab(3)"
      v-bind:class="[activeCartTab === 3 ? 'active' : '']">
      Alterations
    </div>
    <div
      class="tab-btn"
      v-on:click="changeTab(4)"
      v-bind:class="[activeCartTab === 4 ? 'active' : '']">
      Settings
    </div>
  </div>

  <div v-if="activeCartTab === 1" class="cart-tab-content">
    <ServiceSummaryRevenue />
  </div>

  <div v-if="activeCartTab === 2" class="cart-tab-content">
    <ServiceSummaryItems />
  </div>

  <div v-if="activeCartTab === 3" class="cart-tab-content">
    <ServiceSummaryAlterations />
  </div>

  <div v-if="activeCartTab === 4" class="cart-tab-content">
    <ServiceSettings />
  </div>
</template>

<script>
  import ServiceSettings from "@/components/ServiceSettings";
  import ServiceSummaryRevenue from "@/components/ServiceSummary/ServiceSummaryRevenue";
  import ServiceSummaryItems from "@/components/ServiceSummary/ServiceSummaryItems";
  import ServiceSummaryAlterations from "@/components/ServiceSummary/ServiceSummaryAlterations";

  export default {
    data() {
      return {
        activeCartTab: 1,
        serviceId: parseInt(this.$route.params.serviceId),
      };
    },
    methods: {
      changeTab(tabNumber) {
        window.localStorage.setItem("t1", tabNumber);

        this.activeCartTab = tabNumber;
      },
    },
    components: {
      ServiceSettings,
      ServiceSummaryRevenue,
      ServiceSummaryItems,
      ServiceSummaryAlterations,
    },
    mounted() {
      try {
        this.activeCartTab = parseInt(window.localStorage.getItem("t1"));
      } catch {
        // empty
      }

      if (this.activeCartTab == null || isNaN(this.activeCartTab)) {
        this.activeCartTab = 1;
      }
    },
  };
</script>
