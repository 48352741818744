<template>
  <h4 class="filter-title">
    <span class="title-icon">
      <Icon :path="mdiFilterOutline" />
      Filter data
    </span>
    <button
      class="show-all"
      :class="{ on: this.hasFilter(this.status.ALL) }"
      @click="this.toggleFilter(this.status.ALL)">
      Show all
    </button>
  </h4>

  <div class="order-filters">
    <button
      :class="{ on: this.hasFilter(this.status.PLACED) }"
      @click="this.toggleFilter(this.status.PLACED)">
      Placed
    </button>
    <button
      :class="{ on: this.hasFilter(this.status.COMPLETED) }"
      @click="this.toggleFilter(this.status.COMPLETED)">
      Completed
    </button>
    <button
      :class="{ on: this.hasFilter(this.status.CANCELLED) }"
      @click="this.toggleFilter(this.status.CANCELLED)">
      Cancelled
    </button>
    <button
      :class="{ on: this.hasFilter(this.status.REFUNDED) }"
      @click="this.toggleFilter(this.status.REFUNDED)">
      Refunded
    </button>
  </div>

  <div class="orders-table">
    <AgGridVue
      :key="ordersKey"
      style="width: 100%; height: 100%"
      class="ag-theme-alpine orders"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      :rowData="getKitchenOrders"
      @rowClicked="showItems">
    </AgGridVue>
  </div>

  <teleport to="body">
    <transition name="fade">
      <ModalDialog
        v-if="showItemsModal"
        class="modal"
        :maxWidth="'900'"
        @close="showItemsModal = false">
        <OrderDetails
          :order="this.selectedOrder"
          @close="
            ordersKey++;
            showItemsModal = false;
          " />
      </ModalDialog>
    </transition>
  </teleport>
</template>

<script>
  import ModalDialog from "@/components/ModalDialog";
  import OrderDetails from "@/components/Orders/OrderDetails";
  import store from "@/store";
  import { mdiClose, mdiFilterOutline, mdiFood, mdiCashRefund } from "@mdi/js";
  import { AgGridVue } from "ag-grid-vue3";

  export default {
    components: {
      ModalDialog,
      OrderDetails,
      AgGridVue,
    },
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        activeCartTab: 1,
        showItemsModal: false,
        selectedOrder: null,
        statusFilters: [],
        status: {
          ALL: "All",
          PLACED: "Placed",
          COMPLETED: "Completed",
          CANCELLED: "Cancelled",
          REFUNDED: "Refunded",
          CREATED: "Created",
        },
        mdiClose,
        mdiFilterOutline,
        mdiFood,
        mdiCashRefund,
        columnDefs: null,
        rowData: this.getKitchenOrders,
        defaultColDef: null,
        ordersKey: 0,
      };
    },
    computed: {
      getKitchenOrders() {
        let rows = [];

        const orders = (store.state.kitchenOrders.orders || []).filter(
          (item) => {
            return (
              item.status != this.status.CREATED &&
              (this.hasFilter(this.status.ALL) || this.hasFilter(item.status))
            );
          }
        );

        for (const order of orders) {
          rows.push({
            ...order,
            number: order.number,
            name: order.customer.name || "Guest",
            placed: this.formatDateTime(order.timings.placedAt, "HH:mm"),
          });
        }

        return rows;
      },
    },

    watch: {
      "$route.params.serviceId": {
        handler: function (newId) {
          this.serviceId = parseInt(newId);
          store.dispatch("kitchenOrders/stopTask");
          store.dispatch("kitchenOrders/startTask", newId);
        },
      },
    },

    methods: {
      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.hideOverlay();
      },

      hasFilter(filter) {
        return this.statusFilters.indexOf(filter) !== -1;
      },

      toggleFilter(filter) {
        var index = this.statusFilters.indexOf(filter);
        if (index !== -1) {
          // Don't allow us to NOT choose a filter.
          // i.e. always must have 1 filter selected.
          if (this.statusFilters.length == 1) {
            return;
          }

          this.statusFilters.splice(index, 1);
        } else {
          // If 'ALL' is already selected and we are adding another,
          // we must deselect ALL.
          var allIndex = this.statusFilters.indexOf(this.status.ALL);
          if (allIndex !== -1) {
            this.statusFilters.splice(allIndex, 1);
          }
          // If we are adding the ALL one back, we need to remove everything else.
          else if (filter == this.status.ALL) {
            this.statusFilters = [this.status.ALL];
            return;
          }

          this.statusFilters.push(filter);
        }
      },

      // TODO: Change to openModal(id)
      showItems(order) {
        this.selectedOrder = order.data;
        this.showItemsModal = true;
      },

      setGridOptions() {
        this.defaultColDef = {
          suppressMenu: true,
          suppressMovable: true,
        };

        this.columnDefs = [
          {
            field: "number",
            headerName: "#",
            width: 50,
            flex: 1,
            sortable: true,
          },

          {
            field: "name",
            headerName: "Name",
            width: 120,
            flex: 2,
            filter: true,
            resizable: true,
            sortable: true,
            suppressSizeToFit: true,
            floatingFilter: true,
          },
          {
            field: "placed",
            headerName: "Placed",
            width: 80,
            filter: true,
            resizable: true,
            sortable: true,
            suppressSizeToFit: true,
            floatingFilter: true,
          },
          {
            field: "status",
            headerName: "Status",
            width: 100,
            flex: 2,
            filter: true,
            resizable: true,
            sortable: true,
            suppressSizeToFit: true,
            floatingFilter: true,
            cellClass: (rowData) => rowData.value.toLowerCase(),
            cellRenderer: (rowData) => {
              if (rowData.value === "ReadyForCollection")
                return "Ready to Collect";

              return rowData.value;
            },
          },
        ];
      },
    },

    async mounted() {
      this.statusFilters.push(this.status.ALL);
      store.dispatch("kitchenOrders/startTask", this.serviceId);
      this.setGridOptions();
      this.loading = false;
    },

    unmounted() {
      store.dispatch("kitchenOrders/stopTask");
    },
  };
</script>

<style lang="scss" scoped>
  h4.filter-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.5rem 0;
    span.title-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    button.show-all {
      border: none;
      border-radius: 5px;
      padding: 0.5rem 0;
      width: auto;
      color: #666;
      text-decoration: underline;
      font-size: 0.875rem;
      margin-right: 0.5rem;
      &:hover {
        background: none;
        color: #000;
      }
    }
  }

  .order-tab-card {
    margin: 0.25rem;
    padding: 0.25rem;
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    button {
      border: 0;
      width: 50%;
      font-size: 0.9rem;
      padding: 0.5rem;
    }
  }

  .tab-btn {
    max-width: 20%;
    font-size: 0.9rem;
  }

  .order-filters {
    margin: 0 0.5rem 0.5rem;
    display: grid;
    gap: 0.15rem;
    grid-template-columns: repeat(4, 1fr);

    button {
      @include outlined-button($fs: 0.85rem, $p: 0.5rem 0.25rem, $h: 2.5rem);

      &.on {
        color: $col_beta !important;
      }
    }
  }

  .orders-table {
    flex: 1;
  }
</style>

<style lang="scss">
  .orders.ag-theme-alpine {
    .ag-root-wrapper {
      border: none;
    }

    .ag-row {
      cursor: pointer;
      &:hover {
        background: $col_beta-lightest;
      }
    }

    .ag-header-cell,
    .ag-header-group-cell,
    .ag-cell {
      padding: 0 10px;
    }

    .ag-cell {
      &[col-id="status"] {
        font-weight: bold;

        &.placed {
          color: $col_gamma;
        }
        &.completed {
          color: $col_alpha;
        }
        &.readyforcollection {
          color: $col_beta-darker;
        }
        &.refunded {
          color: $col_delta-darker;
        }
        &.cancelled {
          color: $col_faded-darker;
        }
      }
    }
  }
</style>
