<template>
  <div class="order-payment">
    <PaymentsList
      :order="order"
      :order-is-cart="orderIsCart"
      @payments="updatePayments($event)" />

    <div v-if="this.fundingDue">
      <div class="order-payment__details">
        <span class="space"></span>

        <OrderCell label="Funding Due" :value="formatMoney(this.fundingDue)" />

        <button
          class="payment-option"
          @click="setPaymentMethod('card')"
          :class="{ active: paymentMethod == 'card' }"
          :disabled="paymentMethod == 'card' || getCartLockByPaymentStatus">
          <Icon :path="mdiCreditCard" />
          Card
        </button>
        <button
          class="payment-option"
          @click="setPaymentMethod('card-manual')"
          :class="{ active: paymentMethod == 'card-manual' }"
          :disabled="
            paymentMethod == 'card-manual' || getCartLockByPaymentStatus
          ">
          <Icon :path="mdiCreditCard" />
          Manual Card
        </button>
        <button
          class="payment-option"
          @click="setPaymentMethod('cash')"
          :class="{ active: paymentMethod == 'cash' }"
          :disabled="paymentMethod == 'cash' || getCartLockByPaymentStatus">
          <Icon :path="mdiCash" />
          Cash
        </button>

        <span class="space"></span>
      </div>

      <PaymentCard
        :order="order"
        :order-is-cart="orderIsCart"
        @payment-created="paymentCreated"
        v-if="paymentMethod == 'card'" />
      <PaymentCardManual
        :order="order"
        :order-is-cart="orderIsCart"
        @payment-created="paymentCreated"
        v-if="paymentMethod == 'card-manual'" />
      <PaymentCash
        :order="order"
        :order-is-cart="orderIsCart"
        @payment-created="paymentCreated"
        v-if="paymentMethod == 'cash'" />
    </div>
  </div>
</template>

<script>
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import { mdiCreditCard, mdiCash } from "@mdi/js";
  import OrderCell from "@/components/Orders/OrderCell.vue";
  import PaymentsList from "./PaymentsList.vue";
  import PaymentCard from "./PaymentCard.vue";
  import PaymentCardManual from "./PaymentCardManual.vue";
  import PaymentCash from "./PaymentCash.vue";
  import { useToast } from "vue-toastification";
  import { mapGetters } from "vuex";

  export default {
    components: {
      OrderCell,
      PaymentsList,
      PaymentCard,
      PaymentCardManual,
      PaymentCash,
    },

    data() {
      return {
        payments: [],
        paymentMethod: null,
        mdiCreditCard,
        mdiCash,
      };
    },

    props: {
      order: {
        type: Object,
      },
      orderIsCart: {
        type: Boolean,
        default: true,
      },
    },

    emits: ["fully-funded", "close"],

    computed: {
      ...mapGetters({
        getCartLockByPaymentStatus: "payment/getLock",
      }),

      fundingDue() {
        return this.orderIsCart
          ? this.order.summary.paymentPending
          : this.order.paymentPending;
      },
    },

    methods: {
      formatChannel,

      setPaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
      },

      updatePayments(payments) {
        this.payments = payments;
      },

      paymentCreated(event) {
        if (!event.paymentPending) {
          useToast().info(
            `${this.orderIsCart ? "Cart" : "Order"} is now funded.`
          );

          this.$emit("fully-funded");
          this.$emit("close");
        }
      },
    },
    mounted() {
      this.setPaymentMethod("card");
    },
  };
</script>

<style lang="scss">
  .order-payment {
    padding: 0.5em;

    &__title {
      margin: 0.5em;
      width: 100%;
      text-align: center;
    }

    &__details {
      display: flex;
      align-items: center;
      gap: 0.5em;

      > * {
        flex-grow: 0;
      }

      > .space {
        flex-grow: 1;
      }

      button.payment-option {
        @include button-base($h: 2rem);
        border: none;
        color: $col_beta_darker;

        &:disabled {
          color: gray;
          background-color: transparent !important;
        }
      }
    }
  }
  .payment-controls {
    margin-top: 1rem;
    @include flex;
    & > * {
      max-width: 400px;
    }
  }
</style>
