<template>
  <div class="integration integration-payment-zettle">
    <div class="integration__header">
      <h3>Zettle Payment Integration</h3>
      <div class="loading" v-if="intent.loading">
        <LoadingSpinner />
      </div>
      <img
        src="/images/logos/zettle.svg"
        width="128"
        height="57"
        alt="Zettle Logo" />
    </div>
    <div class="integration__info" v-if="!intent.loading">
      <OrderCell label="Order ID" :value="order.id" />
      <OrderCell label="Intent ID" :value="intent.id" />
      <OrderCell label="Amount" :value="formatMoney(intent.intendedAmount)" />
      <OrderCell label="Status" :value="intent.status" />
    </div>
    <div>
      <p>
        <strong>
          This window will update when the payment is accepted or rejected. You
          can close the window and the payment will be processed automatically.
        </strong>
      </p>
      <p>
        Payment Terminal app didn't open?
        <a :href="url">Click this link to open the app manually.</a>
      </p>
      <p>
        If the app still doesn't open the intergation is not installed
        correctly. Please use the manual payment method, and contact Tuck Trucks
        support.
      </p>
    </div>
  </div>
</template>

<script>
  import OrderCell from "@/components/Orders/OrderCell.vue";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  

  export default {
    components: {
      OrderCell,
      LoadingSpinner,
    },

    data() {
      return {};
    },

    props: {
      order: {
        type: Object,
      },
      intent: {
        type: Object,
      },
      url: {
        type: String,
      },
    },
  };
</script>

<style lang="scss">
  .integration {
    margin: 1em;
    padding: 1em;
    border-radius: 8px;
    background: #e3f1ff;

    img {
      float: right;
    }

    &__header,
    &__info {
      display: flex;
      gap: 1em;
      align-items: center;

      h3 {
        margin: 0;
        flex-grow: 1;
        font-size: 1.4em;
      }

      .loading {
        margin-right: 24px;
      }
    }
  }
</style>
