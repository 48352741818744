<template>
  <div
    @click="
      amount === selected
        ? this.$emit('handleUnselectAmount')
        : this.$emit('handleSelectAmount', amount)
    "
    class="component-amounts_option"
    :class="{
      default: amount === levels.default,
      selected: amount === selected,
    }">
    <span class="amount">{{ getAmount }}</span>
    <span class="priceToAdd" v-if="amount !== levels.default && amount !== 0">
      +{{ formatMoney((amount - levels.default) * priceToAdd) }}
    </span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected: this.levels.default,
      };
    },
    props: {
      selectedAmount: {
        type: Number,
      },
      amount: {
        type: Number,
      },
      levels: {
        type: Object,
      },
      priceToAdd: {
        type: Number,
      },
      extras: {
        type: Boolean,
      },
    },
    watch: {
      selectedAmount: function () {
        this.selected = this.selectedAmount;
      },
    },
    emits: ["handleSelectAmount", "handleUnselectAmount"],
    methods: {},
    computed: {
      getAmount() {
        const defaultValue = this.levels.default;
        const amount = this.amount;

        if (amount === 0) {
          return "None";
        }

        if (amount === defaultValue) {
          return "Regular";
        }

        if (amount !== defaultValue) {
          const diff = amount - defaultValue;
          return "+" + diff;
        }
        return "+" + amount;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .component-amounts {
    &_option {
      width: 80px;
      padding: 2px 0;
      border-radius: 0.25rem;
      border: 1px solid #ddd;
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 33px;
      @media screen and (max-width: 768px) {
        display: inline-flex;
        margin-right: 0.5rem;
      }
      .amount {
        padding: 0 0 0 5px;
        font-weight: 300;
        font-size: 0.7rem;
      }
      .priceToAdd {
        font-size: 0.7rem;
        color: #999;
      }
      &.default {
        color: $col_alpha;
        display: flex;
        align-items: center;
        justify-content: center;
        .amount {
          font-weight: 500;
          padding: 0;
        }
      }
      &.selected {
        color: #fff !important;
        background: $col_beta-darker;
        border-color: $col_beta-darker;
        .priceToAdd {
          color: #fff !important;
        }
      }

      &.hide {
        border: none;
        padding: 0;
        pointer-events: none;
        @media screen and (max-width: 768px) {
          display: none;
        }
        span,
        select {
          display: none;
        }
      }
    }
  }
</style>
