<template>
  <div class="cart-display">
    <div class="cart-title-wrap">
      <h4><Icon :path="mdiShoppingOutline" :size="16" />Cart</h4>
      <h4 v-if="getCartItemsCount != 0" class="total">
        Total
        <span>{{ formatMoney(getCartSummary.total) }}</span>
      </h4>
      <button
        :disabled="
          getCartItemsCount === 0 ||
          !getQueueIsEnd ||
          getCartLockByPaymentStatus
        "
        style="float: right"
        class="btn_clear"
        @click="clear">
        Clear
      </button>
    </div>
    <template v-if="tabsExpanded">
      <CartSummary :serviceId="parseInt(serviceId)" />
      <CheckoutButton
        v-if="getCartItemsCount != 0"
        @checkoutScreen="$emit('checkoutScreen')" />
    </template>
    <CartSummaryMini :serviceId="parseInt(serviceId)" v-else>
      <template v-slot:button>
        <CheckoutButton
          v-if="getCartItemsCount != 0"
          @checkoutScreen="$emit('checkoutScreen')" />
      </template>
    </CartSummaryMini>

    <div class="cart-checkout pinned-bottom-buttons">
      <button
        v-if="serviceOptions?.quickCheckout"
        :disabled="
          getCartItemsCount === 0 ||
          !getQueueIsEnd ||
          getCartLockByPaymentStatus
        "
        class="btn_checkout"
        @click="handlePayNow">
        <Icon :path="mdiCreditCard" />
        Pay Now
      </button>

      <button
        :disabled="
          getCartItemsCount === 0 ||
          !getQueueIsEnd ||
          getCartLockByPaymentStatus
        "
        class="btn_checkout"
        @click="$emit('checkoutScreen')">
        Checkout<Icon :path="mdiChevronRight" />
      </button>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import router from "@/router";
  import { mapGetters } from "vuex";
  import CartSummary from "@/components/CartSummary";
  import CartSummaryMini from "@/components/CartSummaryMini";
  import { useToast } from "vue-toastification";
  import * as yup from "yup";
  import { mdiCreditCard, mdiChevronRight, mdiShoppingOutline } from "@mdi/js";
  import CheckoutButton from "@/components/Walkups/CheckoutButton";

  export default {
    data() {
      return {
        takingPayment: false,
        donation: false,
        eventId: this.$route.params.eventId,
        serviceId: this.$route.params.serviceId,
        showModal: null,
        modalMessage: "Please wait...",
        modalStatus: "Pending",
        cashTaken: "",
        customerName: null,
        customerEmail: null,
        nameRules: yup
          .string()
          .nullable()
          .matches(
            /^[aA-zZ\s]+$/,
            "- Only standard characters are allowed in this field"
          ),
        emailRules: yup
          .string()
          .nullable()
          .email("- Please enter a valid email"),
        currentIntent: null,
        currentIntentUrl: null,
        mdiCreditCard,
        mdiChevronRight,
        mdiShoppingOutline,
        job: null,
      };
    },

    props: {
      tabsExpanded: {
        type: Boolean,
      },
      serviceOptions: {
        type: Object,
      },
    },

    emits: ["checkoutScreen"],

    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getCartOrderId: "cart/getOrderId",
        getCartItems: "cart/getItems",
        getCartItemsCount: "cart/getItemsCount",
        getCartSummary: "cart/getSummary",
        getTotal: "cart/getTotal",
        getCartLockByPaymentStatus: "payment/getLock",
        getQueueIsEnd: "cart/getQueueIsEnd",
      }),

      getCashTaken() {
        var number = parseInt(this.cashTaken);

        if (isNaN(number) || number == null) {
          return 0;
        }

        return number * 0.01;
      },

      getCashChange() {
        return this.getCashTaken - this.getCartSummary.total;
      },

      getCashChangeDisplay() {
        const change = this.getCashChange;

        if (change < 0) {
          return "£-.--";
        }

        return this.formatMoney(change);
      },

      getItemCount() {
        return this.itemsCount;
      },

      getDishesCount() {
        return this.dishesCount;
      },

      getCartTotal() {
        return this.formatMoney(this.cartTotal);
      },

      getCartSubTotal() {
        return this.formatMoney(this.cartSubTotal);
      },

      getName(id) {
        return store.getters["cart/getName"](id);
      },

      hasOrder() {
        return store.state.cart.cart.hasOrder();
      },
    },

    methods: {
      checkout() {
        const eventId = this.eventId;
        const serviceId = this.serviceId;
        const orderId = this.getCartOrderId;

        router.push({
          name: "checkout",
          params: { eventId: eventId, serviceId: serviceId, orderId: orderId },
        });
      },

      clear() {
        store.dispatch("cart/clear");
      },

      updateCustomer() {
        const payload = {
          name: this.customerName,
          email: this.customerEmail,
          phone: null,
        };

        store
          .dispatch("cart/updateCustomer", payload)
          .then((response) => {
            if (
              response != null &&
              response.status >= 200 &&
              response.status <= 204
            ) {
              this.customerName = "";
              this.customerEmail = "";
            }
          })
          .catch((error) => {
            window.log.error(error);

            const toast = useToast();
            toast.error("Could not update customer details.");
          });
      },

      handlePayNow() {
        this.takingPayment = true;

        // By setting current intent, we hide the payments and show the zettle integration control
        this.currentIntent = { loading: true };

        store.dispatch("payment/createCartZettlePayment", {
          amount: this.getTotal,
          id: this.getCart.cartId,
          key: this.getCart.cartKey,
        });
      },
    },

    components: {
      CartSummary,
      CartSummaryMini,
      CheckoutButton,
    },

    mounted() {
      this.cartItems = this.items;
    },

    unmounted() {
      store.dispatch("jobZettleIntent/stopTask");
    },
  };
</script>

<style lang="scss">
  h1,
  h2 {
    &.alpha {
      color: $col_alpha-darker;
    }

    &.beta {
      color: $col_beta-darker;
    }
  }

  h1.massive {
    font-size: 4rem;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 900;

    small {
      color: $col_softblack;
      display: block;
      font-size: 1.2rem;
    }
  }

  h2.massive {
    font-size: 2rem;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 900;

    small {
      color: $col_softblack;
      display: block;
      font-size: 0.9rem;
    }
  }

  .cart-display {
    display: block;
    background: $col_white;
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 4.5rem; // the space for checkout buttons
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 210px;
    padding-bottom: calc($button_height + 1rem);

    .tabs-expanded & {
      max-height: 100%;
      position: absolute;
      height: calc(100vh - 158px);
      position: relative;

      @media screen and (max-width: 1024px) {
        height: calc(100vh - 60px);
      }
    }

    .input-wrapper {
      width: 100%;
      display: table;

      label {
        width: 25%;
        display: table-cell;
      }

      field {
        width: 75%;
        display: table-cell;
      }
    }

    @media only screen and (min-width: $tablet_min_width) {
      box-shadow: 2px -5px 10px #00000028;
    }
  }

  .payment-methods {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .alt-payment-methods {
    margin-top: 10px;
  }

  .alt-payment-methods > button,
  .payment-methods > button {
    width: 49%;
    background: $col_beta;
    border: 0;
    border-radius: 5px;
    padding: 0.5rem;
  }

  .modal-zettle {
    img {
      float: left;
      width: 72px;
      margin-right: 20px;
    }

    .status {
      display: block;
      width: 100%;
      height: 32px;
      margin: 20px 0;
      text-align: center;

      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
      }
    }
  }

  .modal-cash {
    span.image-icon {
      float: left;
      margin-right: 20px;
    }

    .quick-buttons {
      display: table;
      width: 100%;

      span {
        display: table-cell;
        width: 25%;
        padding: 5px;

        button {
          width: 100%;
          color: $col_offwhite;
        }

        button.add {
          background: $col_alpha;
        }

        button.reset {
          background: $col_beta-darker;
        }
      }
    }

    table.numbers {
      width: 280px;
      margin: 0 auto;
      margin-bottom: 20px;

      td {
        width: 33.33%;

        button {
          color: $col_offwhite;
          font-size: 2rem;
          border: 0;
          border-radius: 5px;
          width: 100%;
          font-weight: 900;

          &.add {
            background: $col_alpha;
          }

          &.clear {
            background: $col_beta;
          }
        }
      }
    }

    .take-payment {
      width: 240px;
      border: 0;
      border-radius: 24px;
      background: $col_alpha;
      color: $col_offwhite;
    }

    .take-payment:hover {
      background: $col_alpha-lighter;
    }

    .take-payment:disabled {
      background: $col_faded !important;
    }
  }

  .closeCart {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  @media only screen and (min-width: 961px) {
    .closeCart {
      display: none;
    }
  }

  .empty-cart-message {
    padding: 5rem 0;
    text-align: center;
  }

  .cart-displaylist {
    display: flex;
    justify-content: space-between;
  }

  .cart-displaylist_item {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 0;
  }

  .btn_checkout {
    @include contained-button;
    width: 100%;

    &:hover {
      background: $col_beta-darker;
    }
  }

  .cart-title-wrap {
    @include flex($jc: space-between);
    border-bottom: 1px solid #ddd;
    h4 {
      margin: 0;
      @include flex;

      &.total {
        font-weight: lighter;
        justify-content: flex-end;
        color: gray;
        flex: 1;
        text-align: right;
        padding-right: 0;
        span {
          color: black;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }
  }
  .btn_clear {
    padding: 0.1rem;
    cursor: pointer;
    font-size: 0.85rem;
    border: 0;
    width: auto;
    text-decoration: underline;
    color: #999;

    &:hover {
      color: #000;
      background: none;
    }
  }

  .cart_price {
    width: 3rem;
  }

  .cart-item-component-alterations {
    color: $col_faded;
    font-size: 0.8rem;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .pay-now {
    .modal-container {
      padding: 3rem 2rem;
      height: auto;
    }
  }
</style>
