<template>
  <div class="order-container">
    <div class="order-container__top-tabs">
      <button
        :disabled="activeSection == 0"
        @click="activeSection = 0"
        :class="{ active: activeSection == 0 }">
        Order Details
      </button>
      <button
        :disabled="activeSection == 1"
        @click="activeSection = 1"
        :class="{ active: activeSection == 1 }">
        <div class="unpaid" v-if="order.paymentPending">
          <span class="anim-tag-pulse">£ DUE</span>
        </div>
        Payments
      </button>
      <button
        :disabled="activeSection == 2"
        @click="activeSection = 2"
        :class="{ active: activeSection == 2 }">
        Refunds
      </button>
      <span class="close"></span>
    </div>

    <DelayedBar
      v-if="order.timings.delayedBy != null"
      :duration="order.timings.delayedBy"
      :orderId="order.id" />

    <section id="order-details" v-if="activeSection == 0">
      <OrderHeader :order="order" :orderIsCart="false" class="section" />
      <OrderCustomer
        class="section"
        :order="order"
        :orderIsCart="false"
        :clubPointRewardLevel="clubPointRewardLevel" />
      <OrderNote class="section" :order="order" />
      <OrderItems :order="order" :orderIsCart="false" class="section" />
    </section>
    <section id="order-payments" v-if="activeSection == 1">
      <Payments
        :order="order"
        :orderIsCart="false"
        :activePayment="activePayment" />
    </section>
    <section id="order-refunds" v-if="activeSection == 2">
      <Refunds :order="order" :orderIsCart="false" />
    </section>
  </div>
</template>

<script>
  import OrderHeader from "@/components/Orders/Details/OrderHeader.vue";
  import OrderCustomer from "@/components/Orders/Details/OrderCustomer.vue";
  import OrderItems from "@/components/Orders/Details/OrderItems.vue";
  import Payments from "@/components/Orders/Payments/Payments.vue";
  import Refunds from "@/components/Orders/Refunds/Refunds.vue";
  import DelayedBar from "@/components/Orders/DelayedBar.vue";
  import { mapState } from "vuex";
  import OrderNote from "@/components/Orders/Details/OrderNote.vue";

  export default {
    components: {
      OrderHeader,
      OrderCustomer,
      OrderItems,
      DelayedBar,
      Payments,
      Refunds,
      OrderNote,
    },

    computed: {
      ...mapState({ ifOrderPaid: (state) => state.payment.phoneOrderingPaid }),
    },

    watch: {
      ifOrderPaid(newValue) {
        if (newValue) {
          this.$emit("close");
        }
      },
    },

    data() {
      return {
        activeSection: 0,
        activePayment: "none",
      };
    },

    props: {
      order: {
        type: Object,
      },
      clubPointRewardLevel: { type: Number },
    },

    emits: ["close"],

    methods: {
      close() {
        this.$emit("close");
      },

      paymentSelected(paymentType) {
        switch (paymentType) {
          default: {
            this.activePayment = "none";
            break;
          }
          case "card":
          case "card-manual":
          case "cash": {
            this.activePayment = paymentType;
            break;
          }
        }
      },
    },

    mounted() {
      this.activeSection = 0;
    },
  };
</script>

<style lang="scss">
  .modal-container {
    padding: 0;

    height: 95%;
    max-height: 840px;
  }

  .order-container {
    &__top-tabs {
      display: flex;

      button {
        display: inline-block;
        border-radius: 0;
        flex-grow: 1;
        background: #fff;
        border: 0;
        border-bottom: 2px solid #ddd;
        color: $col_faded;

        .unpaid {
          position: absolute;
          width: 150px; // Large ernough to fit the label in before repoisitioning

          span {
            color: $col_black;
            background: #f9ca24;
            border-radius: 16px;
            font-size: 1em;
            position: relative;
            display: inline-block;
            top: -8px;
            left: -32px;
            padding: 0.3em 1em;
            height: 30px;
            transform: rotate(-35deg);
            font-weight: 900;
          }
        }
      }

      button:disabled {
        background: #fff !important;
        border-bottom-color: $col_beta !important;
        color: $col_black !important;
      }

      button:hover:not([disabled]) {
        color: $col_beta-lighter !important;
        background: #fff;
        border-bottom-color: $col_beta-lightest !important;
      }

      span.close {
        width: 72px;
      }
    }

    section {
      margin: 0 0;
      padding: 2em;
    }

    .section {
      border-bottom: 2px solid #ddd;
    }
  }
</style>
W
