<template>
  <div
    class="sub-header-item customer club"
    :class="{
      new: orderCounts === 0,
      reward: orderCounts % clubPointRewardLevel == 0 && orderCounts !== 0,
    }">
    <span class="counts" v-if="orderCounts > 0">{{ orderCounts }}</span>
    <Icon :path="mdiNewBox" :size="24" v-if="orderCounts === 0" />
    <Icon
      :path="mdiGiftOpen"
      :size="20"
      v-if="orderCounts % clubPointRewardLevel == 0 && orderCounts !== 0" />
    <span class="ellipsis" v-if="customer">{{ customer.name }}</span>
  </div>
</template>

<script>
  import { mdiNewBox, mdiGiftOpen } from "@mdi/js";

  export default {
    data() {
      return { mdiNewBox, mdiGiftOpen };
    },

    props: {
      customer: {
        type: Object,
      },
      orderCounts: { type: Number },
      clubPointRewardLevel: { type: Number },
    },
  };
</script>

<style lang="scss">
  .sub-header-item.club {
    @include flex($wrap: nowrap);

    .counts {
      padding: 0.25rem;
      line-height: 1em;
      color: $col_alpha-lighter;
      background: $col_alpha-lightest-2;
      border-radius: 5px;
      font-weight: bold;
    }

    &.reward {
      svg {
        color: $col_beta;
      }
      .counts {
        color: $col_beta;
        background: $col_beta-lightest;
      }
    }

    &.new {
      font-weight: bold;

      svg {
        color: #ffc400;
      }
    }
  }
</style>
