<template>
  <div class="allergen-dietary-section row">
    <p>Dietaries:</p>

    <div v-if="dietariesProp.length > 0" class="dietaries_list">
      <div
        v-for="(dietary, index) of this.dietariesOrderedByName"
        :key="index"
        class="dietary row align-center show">
        <p class="row">
          <Icon :path="mdiCheck" :size="12" />
          {{ dietary }}
          <!-- TODO: Use display name somehow -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Enumerable from "linq";
  import { mdiCheck } from "@mdi/js";

  export default {
    data() {
      return {
        dietaries: [],
        mdiCheck,
      };
    },
    props: {
      dietariesProp: {
        type: Array,
        default: null,
      },
    },
    computed: {
      dietariesOrderedByName() {
        return Enumerable.from(this.dietariesProp)
          .orderBy((x) => x.displayValue)
          .toArray();
      },
    },
    methods: {},
    mounted() {},
  };
</script>

<style lang="scss">
  .allergen-dietary-section {
    border-radius: 10px;
    background: #fafafa;
    padding: 0.5rem;
    gap: 10px;
    align-items: baseline;
    > .title,
    p {
      font-size: 0.8rem;
      margin-top: 0;
    }
  }

  .dietaries {
    &_list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .dietary {
    &:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #aaa;
      margin: 0 5px;
    }
    & > p {
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
      color: #aaaaaa;
      align-items: center;
      gap: 0.2rem;
      span.material-icons {
        color: #ccc;
        font-weight: bold;
        font-size: 0.7rem;
      }
    }

    &.show {
      p {
        color: #000;
      }
      span.material-icons {
        color: $col_alpha;
      }
    }
  }
</style>
