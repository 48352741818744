<template>
  <div v-if="loading" class="loading-spinner_container" style="padding: 5rem 0">
    <LoadingSpinner />
  </div>
  <Chart
    v-if="serviceSummary"
    :data="chartData"
    :type="'doughnut'"
    :legend="{ show: true, position: 'right' }" />
  <table class="table" v-if="serviceSummary">
    <tr>
      <th>Total Orders</th>
      <th>
        {{ serviceSummary.totalOrders }}
      </th>
    </tr>
    <tr>
      <td>Online Revenue</td>
      <td>
        {{ formatMoney(serviceSummary.grossOnlineRevenue) }}
      </td>
    </tr>
    <tr>
      <td>Walk Up Revenue</td>
      <td>
        <small
          ><b>Card</b>:
          {{ formatMoney(serviceSummary.grossWalkUpCardRevenue) }}</small
        ><br />
        <small
          ><b>Cash</b>:
          {{ formatMoney(serviceSummary.grossWalkUpCashRevenue) }}</small
        ><br />
        <small
          ><b>Promo</b>:
          {{ formatMoney(serviceSummary.grossWalkUpPromotionRevenue) }}</small
        ><br />
        <b>Total</b>:
        {{ formatMoney(serviceSummary.grossWalkUpRevenue) }}
      </td>
    </tr>
    <tr v-if="serviceSummary.grossDeliveryRevenue">
      <td>Delivery Revenue</td>
      <td>{{ formatMoney(serviceSummary.grossDeliveryRevenue) }}</td>
    </tr>
    <tr v-if="serviceSummary.grossPhoneRevenue">
      <td>Phone Revenue</td>
      <td>{{ formatMoney(serviceSummary.grossPhoneRevenue) }}</td>
    </tr>
    <tr>
      <th>Gross Revenue</th>
      <th>{{ formatMoney(serviceSummary.grossRevenue) }}</th>
    </tr>
    <tr>
      <td>Cancellations</td>
      <td>{{ formatMoney(serviceSummary.totalCancellationAmount) }}</td>
    </tr>
    <tr>
      <td>Refunds</td>
      <td>{{ formatMoney(serviceSummary.totalRefundAmount) }}</td>
    </tr>
    <tr>
      <th>Net Revenue</th>
      <th>{{ formatMoney(serviceSummary.netRevenue) }}</th>
    </tr>
  </table>
</template>

<script>
  import store from "@/store";
  import Chart from "@/components/charts/Chart.vue";

  export default {
    data() {
      return {
        loading: false,
        serviceSummary: null,
        chartData: {},
        serviceId: this.$route.params.serviceId,
        service: null,
      };
    },

    components: {
      Chart,
    },

    methods: {
      downloadServiceRevenue() {
        this.loading = true;

        store.state.apiPrivate.client.endpoints.serviceReporting
          .getServiceRevenue(this.serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject("Failed to download service revenue data."); // 🔻
          })
          .then((data) => {
            this.serviceSummary = data;

            this.chartData = {
              labels: ["Online", "Walk Up"],
              datasets: [
                {
                  label: "Today",
                  data: [
                    this.serviceSummary.grossOnlineRevenue,
                    this.serviceSummary.grossWalkUpCardRevenue +
                      this.serviceSummary.grossWalkUpCashRevenue,
                  ],
                  backgroundColor: ["#e59192", "#375cd8"],
                },
              ],
            };

            if (this.serviceSummary.grossDeliveryRevenue) {
              this.chartData.labels.push("Delivery");
              this.chartData.datasets[0].backgroundColor.push("#498e7b");
              this.chartData.datasets[0].data.push(
                this.serviceSummary.grossDeliveryRevenue
              );
            }

            if (this.serviceSummary.grossPhoneRevenue) {
              this.chartData.labels.push("Phone");
              this.chartData.datasets[0].backgroundColor.push("#f0932b");
              this.chartData.datasets[0].data.push(
                this.serviceSummary.grossPhoneRevenue
              );
            }
          })
          .catch((error) => {
            window.log.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },

    mounted() {
      store.getters["repoServices/getById"](this.serviceId).then((service) => {
        this.service = service;

        this.downloadServiceRevenue();
      });
    },
  };
</script>

<style lang="scss" scoped>
  .chart {
    padding: 1em;
  }

  table {
    tr {
      td:first-child,
      th:first-child {
        text-align: left;
        padding-left: 1em;
      }
      td:last-child,
      th:last-child {
        text-align: right;
        padding-right: 1em;
      }
    }
  }
</style>
