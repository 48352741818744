<template>
  <div class="allergen-dietary-section row">
    <p class="title">Allergens:</p>
    <div class="allergens_list" v-if="allergens.length > 0">
      <div
        v-for="(allergen, index) of this.allergensOrderedByName"
        :key="index"
        class="allergen row align-center"
        :class="{ gray: this.grayedOutAllergensProp.includes(allergen.id) }">
        <p>{{ allergen.name }}</p>
      </div>
    </div>
    <p v-else>No allergens</p>
  </div>
</template>

<script>
  import Enumerable from "linq";

  export default {
    data() {
      return {
        allergens: [],
      };
    },

    props: {
      allergensProp: {
        type: Array,
        default: () => [],
      },
      grayedOutAllergensProp: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      allergensProp: {
        immediate: false,
        deep: true,
        handler: function () {
          this.getAllergenInfo();
        },
      },
    },
    computed: {
      allergensOrderedByName() {
        return Enumerable.from(this.allergens)
          .orderBy((x) => x.name)
          .toArray();
      },
    },
    methods: {
      getAllergenInfo() {
        this.allergens.length = 0;

        if (this.allergensProp.length > 0) {
          const combined = Enumerable.from(this.allergensProp)
            .union(Enumerable.from(this.grayedOutAllergensProp))
            .distinct()
            .toArray();

          for (const allergen of combined) {
            this.$store.getters["repoAllergens/getById"](allergen).then((a) => {
              this.allergens.push(a);
            });
          }
        }

        this.loading = false;
      },
    },

    created() {
      this.loading = true;
      this.getAllergenInfo();
    },
  };
</script>

<style lang="scss">
  .allergen-dietary-section {
    border-radius: 10px;
    background: #fafafa;
    padding: 0.5rem;
    gap: 10px;
    align-items: baseline;
    > .title,
    p {
      font-size: 0.75rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .allergens {
    &_list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .allergen {
    &:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #aaa;
      margin: 0 5px;
    }
    & > p {
      margin: 0;
      padding: 0;
      font-size: 0.75rem;
    }

    &.gray {
      p,
      svg {
        color: #6f6f6f;
      }
      background: #f4f4f4;
    }
  }
</style>
