<template>
  <div class="refund-type-container" ref="container">
    <div class="refund-type_option">
      <div
        class="refund-type_icon"
        v-bind:class="[this.view == this.refundViews.PARTIAL ? 'active' : '']"
        @click="changeType(this.refundViews.PARTIAL)">
        <Icon :path="mdiBasketUnfill" :size="56" title="Partial" />
      </div>
      <div class="refund-type_label">Partial</div>
    </div>
    <div class="refund-type_option">
      <div
        class="refund-type_icon"
        v-bind:class="[this.view == this.refundViews.FULL ? 'active' : '']"
        @click="changeType(this.refundViews.FULL)">
        <Icon :path="mdiBasket" :size="56" title="Full" />
      </div>
      <div class="refund-type_label">Full</div>
    </div>
  </div>
  <div class="refund-type-fields" v-if="this.showPartial()">
    <Form @submit="refund">
      <div class="input-wrapper">
        <section>
          <label for="amount"><h3>Amount (£)</h3></label>
          <ErrorMessage class="error-message block" name="amount" />
          <Field
            name="amount"
            id="amount"
            :rules="amountRules"
            v-model="partialAmount">
          </Field>
          <label for="reason"><h3>Reason</h3></label>
          <Field name="reason" type="text" id="amount" v-model="reason" />
        </section>
        <section>
          <div class="refund-disclaimer">
            By clicking 'submit' you are confirming you want to process a refund
            for this order. Any amount over the total order amount will result
            in a 'full' refund to the value of the order.
          </div>
          <br />
          <button type="submit" class="btn_checkout" :disabled="loading">
            <LoadingSpinner v-if="loading" />
            <span v-else>Submit</span>
          </button>
        </section>
      </div>
    </Form>
  </div>
  <div class="refund-type-fields" v-if="this.showFull()">
    <Form @submit="refund">
      <div class="input-wrapper">
        <section>
          <label for="reason"><h2>Reason</h2></label>
          <Field name="reason" type="text" id="amount" v-model="reason" />
        </section>
        <section>
          <div class="refund-disclaimer">
            By clicking 'submit' you are confirming you want to process a full
            refund for this order.
          </div>
          <br />
          <button type="submit" class="btn_checkout" :disabled="loading">
            <LoadingSpinner v-if="loading" />
            <span v-else>Submit</span>
          </button>
        </section>
      </div>
    </Form>
  </div>
</template>

<script>
  import * as yup from "yup";
  import store from "@/store";
  import ApiOrder from "@tucktrucks/platform-base-public";
  import { mdiBasketUnfill, mdiBasket } from "@mdi/js";
  import { useToast } from "vue-toastification";

  export default {
    props: {
      order: {
        type: ApiOrder,
      },
    },
    emits: ["close"],

    data() {
      return {
        refundViews: {
          HIDE: "Hide",
          PARTIAL: "Partial",
          FULL: "Full",
        },
        amountRules: yup
          .string()
          .matches(
            /^[+]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
            "Not a valid currency value"
          )
          .required("This is a required field"),
        view: "",
        partialAmount: "",
        reason: "",
        loading: false,
        mdiBasketUnfill,
        mdiBasket,
      };
    },

    methods: {
      showPartial() {
        return this.view == this.refundViews.PARTIAL;
      },

      showFull() {
        return this.view == this.refundViews.FULL;
      },

      changeType(type) {
        this.reason = "";
        this.view = type;
      },

      async refund() {
        try {
          this.loading = true;
          const refundAmount = this.showPartial() ? this.partialAmount : 0;
          const refundReason = this.reason;
          const refundType = this.showPartial()
            ? "Partial"
            : this.showFull
            ? "Full"
            : "";

          await store.state.apiPrivate.client.endpoints.orders.refund(
            {
              requestType: refundType,
              amount: refundAmount,
              reason: refundReason,
            },
            this.order.id
          );

          this.view = this.refundViews.HIDE;

          const toast = useToast();
          toast.success(refundType + " Order Refunded");
        } catch (e) {
          window.log.error(e);
          const toast = useToast();
          toast.error("Problem refunding order");
        } finally {
          this.loading = false;
          this.$emit("close");
        }
      },
    },

    mounted() {
      this.view = this.refundViews.HIDE;
    },
  };
</script>

<style lang="scss" scoped>
  h1 {
    margin: 2rem auto 0;
    max-width: 500px;
  }

  .refund-type-container {
    display: flex;
    justify-content: space-around;
    margin: 2rem auto 0;
    max-width: 500px;

    .refund-type_icon {
      padding: 1rem;
      background: #efefef;

      &.active {
        background-color: $col_beta-lighter;
      }
    }

    .refund-type_label {
      display: block;
      margin-top: 1rem;
      font-size: 1rem;
      text-align: center;
    }

    .refund-type_option {
      cursor: pointer;
      display: flex;
      align-items: center;
      align-self: stretch;
      flex-direction: column;
      color: black;
      justify-content: space-evenly;
    }
  }

  .refund-type-fields {
    max-width: 500px;
    margin: 0 auto;

    section {
      margin: 0.5rem 0;
    }

    h3 {
      margin-bottom: 0.5rem;
    }
  }
</style>
