<template>
  <table class="table stock-control-table" v-if="!loading">
    <thead>
      <tr>
        <th colspan="2">
          <StockIconToolTip itemOrComponent="item" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in stockItems"
        :key="item.id"
        class="item-row"
        :class="{ 'out-of-stock': item.stockControl != 'Available' }">
        <td
          class="item-name"
          :class="{ 'out-of-stock': item.stockControl != 'Available' }">
          {{ item.name }}
        </td>
        <td>
          <div class="stock-control-buttons" v-if="!item.saving">
            <IconButtonWithTip
              :active="item.stockControl === 'Available'"
              className="in-stock"
              :iconCode="mdiCheckCircle"
              tipText="Available"
              :onclick="() => this.updateStockControl(item, 'Available')"
              :show="item.show === 'Available'" />
            <IconButtonWithTip
              :active="item.stockControl === 'ServiceBlocked'"
              className="out-of-stock"
              :iconCode="mdiCalendarRemove"
              :show="item.show === 'ServiceBlocked'"
              tipText="Service Blocked"
              :onclick="
                () => this.updateStockControl(item, 'ServiceBlocked')
              " />
            <IconButtonWithTip
              :active="item.stockControl === 'ItemBlocked'"
              className="out-of-stock"
              :iconCode="mdiMinusCircle"
              :show="item.show === 'ItemBlocked'"
              tipText="Item Blocked"
              :onclick="() => this.updateStockControl(item, 'ItemBlocked')" />
          </div>
          <div v-else class="center saving-indicator"><LoadingSpinner /></div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no-data-wrapper" v-if="!loading && stockItems.length < 1">
    <p v-if="errorText">{{ errorText }}</p>
    <NoDataText v-else />
  </div>
  <div v-if="loading" class="center loading-wrapper">
    <LoadingSpinner />
  </div>
</template>

<script>
  import store from "@/store";
  import IconButtonWithTip from "@/components/IconButtonWithTip";
  import LoadingSpinner from "../LoadingSpinner.vue";
  import StockIconToolTip from "./StockIconToolTip.vue";
  import NoDataText from "../NoDataText.vue";
  import { mdiCheckCircle, mdiCalendarRemove, mdiMinusCircle } from "@mdi/js";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        stockItems: [],
        loading: true,
        errorText: "",
        mdiCheckCircle,
        mdiCalendarRemove,
        mdiMinusCircle,
      };
    },
    components: {
      IconButtonWithTip,
      LoadingSpinner,
      StockIconToolTip,
      NoDataText,
    },
    methods: {
      getList() {
        this.loading = true;
        this.stockItems = [];

        store.state.apiPrivate.client.endpoints.kitchenStockItems
          .getAll(this.serviceId)
          .then((response) => {
            if (response.status === 200) {
              response.data.data.forEach((item) => {
                this.stockItems.push({ ...item, saving: false });
              });
            }

            if (response.status === 500) {
              this.errorText = "Something went wrong, Please contact Support.";
            }

            this.loading = false;
          });
      },

      updateStockControl(item, selectedValue) {
        item.saving = true;
        window.log.info(
          `[📦] Stock control for item ${item.name} [${item.id}] set to ${selectedValue}.`
        );

        store.state.apiPrivate.client.endpoints.kitchenStockItems
          .update(selectedValue, this.serviceId, item.id)
          .then((response) => {
            item.saving = false;
            item["show"] = selectedValue;

            if (response.status >= 200 && response.status <= 204) {
              return;
            }

            return Promise.reject("Failed to update stock.");
          })
          .then(() => {
            item.stockControl = selectedValue;
          })
          .catch((err) => {
            window.log.error(err);

            this.getList();
          });
      },
    },
    mounted() {
      this.getList();
    },
    unmounted() {
      store.dispatch("kitchenStock/stopTask");
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    padding: 0 10px;
  }
  .product-name {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      background: #eee;
    }
  }

  .edit-table {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
  }

  .editable-value {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .item-row {
    cursor: pointer;
    &.out-of-stock {
      background: #fff4f4;
    }
  }
  .item-name {
    width: 60%;
    min-width: 100px;
    font-size: 1rem;
    cursor: default;

    &.out-of-stock {
      color: rgb(146, 22, 49);
    }
  }

  /* Toggle switch styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 34px;
  }

  /* Hide HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle {
    position: absolute;
    cursor: pointer;
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .toggle:before {
    position: absolute;
    content: "";
    border-radius: 5px;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .toggle {
    background-color: $col_beta-darker;
  }

  input:focus + .toggle {
    box-shadow: 0 0 1px black;
  }

  input:checked + .toggle:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .stock-control-table {
    thead th {
      background: #fff;
      padding: 0.45rem 0.25rem;
    }

    th,
    td {
      font-weight: 400;
    }

    th:first-child,
    td:first-child {
      width: 60%;
      min-width: 100px;
      padding-left: 15px;
    }
    th:last-child {
      padding-left: 10px;
    }
    td:last-child {
      padding-right: 15px;
    }
  }

  .stock-control-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .saving-indicator {
    color: $col_beta;
    height: 40px;
    display: inline-flex;
    align-items: center;
    .loading-spinner {
      margin-right: 3px;
      border-width: 3px;
      width: 1rem;
      height: 1rem;
    }
  }

  .loading-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
  }

  .no-data-wrapper {
    min-height: 200px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
</style>
