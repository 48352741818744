<template>
  <div class="order-cell" :class="classes">
    <label>{{ getLabel }}</label>
    <p class="value">
      <Icon :path="path" :size="18" v-if="path" />
      <span>{{ getValue }}</span>
    </p>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },

    props: {
      label: {
        type: String,
      },
      value: {
        // type: Object,
      },
      path: { type: String },
      classes: { type: String },
    },

    computed: {
      getLabel() {
        return this.label;
      },

      getValue() {
        return this.value;
      },
    },
  };
</script>

<style lang="scss">
  .order-cell {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 0.66em;

    label {
      font-weight: 400;
      font-size: 0.875rem;
      color: #333;
    }

    .value {
      padding: 0;
      margin: 0;
      @include flex;
    }

    span {
      font-size: 1.2rem;
      text-align: center;
    }
  }
</style>
