<template>
  <div class="payment-cash">
    <h4>Take cash from the customer and give change</h4>

    <div class="payment-cash__controls payment-controls">
      <div class="payment-cash__totals">
        <h1 class="massive beta">
          <small>Payment Amount:</small>
          {{ formatMoney(amount) }}
        </h1>

        <h2 class="massive alpha" v-if="changeDue > 0">
          <small>Change Due:</small>
          {{ formatMoney(changeDue) }}
        </h2>

        <button
          class="add"
          @click="takePayment()"
          :disabled="amount <= 0 || !getQueueIsEnd || getCartLockByPaymentStatus">
          Take Payment
        </button>
      </div>

      <PaymentPicker v-model="amount" />
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import PaymentPicker from "@/components/Orders/Payments/PaymentPicker.vue";

  export default {
    components: {
      PaymentPicker,
    },

    data() {
      return {
        amount: 0,
        buttonDisabled: false,
      };
    },

    props: {
      order: {
        type: Object,
      },
      orderIsCart: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["paymentCreated"],

    computed: {
      ...mapGetters({
        getQueueIsEnd: "cart/getQueueIsEnd",
        getCartLockByPaymentStatus: "payment/getLock",
        getPaymentPending: "payment/getPaymentPending"
      }),

      changeDue() {
        return this.amount - this.fundingDue;
      },

      fundingDue() {
        return this.orderIsCart
          ? this.order.summary.paymentPending
          : this.order.paymentPending;
      },
    },

    methods: {
      async takePayment() {

        const payment = this.orderIsCart
          ? 'payment/createCartPaymentCash'
          : 'payment/createOrderPaymentCash';
        const id = this.orderIsCart ? this.order.cartId : this.order.id;
        const key = this.orderIsCart ? this.order.cartKey : this.order.key;
        const amount =
          this.amount > this.fundingDue ? this.fundingDue : this.amount;

        const status = await store.dispatch(payment, { amount, id, key });
        if(status === 200) this.amount = this.getPaymentPending;
      },
    },

    mounted() {
      this.amount = 0;
    },
  };
</script>

<style lang="scss">
  .payment-cash {
    border-top: 2px solid $col_beta;

    h4 {
      width: 100%;
      text-align: center;
    }

    &__controls {
      display: flex;
      gap: 1em;
    }

    &__totals {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      .space {
        flex-grow: 1;
      }

      button {
        background: $col_beta;
        border: 0;
        border-radius: 8px;
      }

      button.full-amount {
        font-size: 0.9em;
        padding: 0.25em 0.75em;
      }

      button.add {
        width: 66%;
        font-size: 1.4em;
        @include contained-button;
      }

      h1.massive {
        font-size: 3rem;
      }
      h2.massive {
        font-size: 3rem;

        small {
          font-size: 1.2rem;
        }
      }
    }
  }
</style>
