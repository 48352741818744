<template>
  <div class="order-item">
    <div class="summary">
      <p class="name">
        <span class="amount">×{{ item.amount }}</span>
        {{ item.name }}
      </p>
      <div class="alterations-wrapper">
        <p
          v-for="componentAlteration of item.componentAlterations"
          :key="`${item.id}.${componentAlteration.id}`"
          class="alterations"
          :class="getComponentAlterationClass(componentAlteration)">
          {{ renderComponentAlterationLabel(componentAlteration) }}
        </p>
      </div>

      <span class="note-button" @click="ifShowNote = !ifShowNote">
        <Icon :path="mdiNoteAlertOutline" :size="16" />
      </span>

      <p class="price">
        {{ formatMoney(item.itemPriceConfigured * item.amount) }}
      </p>
    </div>

    <ItemNoteForm :item="item" v-if="ifShowNote" />
  </div>
</template>

<script>
  import ItemNoteForm from "@/components/Ticket/ItemNoteForm.vue";
  import { mdiNoteAlertOutline } from "@mdi/js";

  export default {
    components: {
      ItemNoteForm,
    },

    data() {
      return {
        mdiNoteAlertOutline,
        loading: false,
        subTotal: 0,
        includeServiceCharge: false,
        ifShowNote: false,
        serviceCharge: 0,
        total: 0,
      };
    },

    props: {
      item: {
        default: {},
      },
    },

    methods: {
      getComponentAlterationClass(componentAlteration) {
        if (componentAlteration.levelDelta > 0) {
          return componentAlteration.levelDelta ==
            componentAlteration.levelAbsolute
            ? "new"
            : "extra";
        } else {
          return "removal";
        }
      },

      renderComponentAlterationLabel(componentAlteration) {
        return `${componentAlteration.name} × ${Math.abs(
          componentAlteration.levelDelta
        )}`;
      },
    },
  };
</script>
<style lang="scss">
  .order-item {
    width: 100%;
    .summary {
      width: 100%;
      @include flex($jc: space-between, $wrap: nowrap, $ai: flex-start);

      p {
        margin: unset;
      }

      .name {
        width: 200px;
        flex-shrink: 0;
        @include flex($jc: flex-start, $wrap: nowrap, $ai: flex-start);
        .amount {
          flex-shrink: 0;
          width: 30px;
        }
      }

      .alterations-wrapper {
        width: 50%;
        flex: 1;
      }

      .note-button {
        @include flex($g: 0.15rem);
        cursor: pointer;

        color: $col_alpha;
        background: $col_alpha-lightest-2;
        border-radius: 50%;
        padding: 0.25rem;
      }
    }
  }
</style>
