<template>
  <ul class="component-alterations-list component-alterations-scroll">
    <template
      v-for="(componentGroup, group) in sortedComponentList"
      :key="group">
      <div class="component-alterations-list_group">
        <p
          v-if="group != this.ungroupedIemsName"
          class="component-alterations-list_group-name">
          {{ group }}
        </p>
        <ItemComponentsListItem
          class="component-alterations-list_item"
          v-for="component in sortedComponentList[group]"
          @modifyComponentAmount="handleSelectAmount"
          v-bind:key="component.id"
          :extras="extras"
          :component="component" />
      </div>
    </template>
  </ul>
</template>

<script>
  import ItemComponentsListItem from "./ItemComponentsListItem.vue";
  export default {
    data() {
      return { sortedComponentList: [], ungroupedIemsName: "no-group" };
    },
    props: {
      componentList: {
        type: Array,
      },
      extras: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["modifyComponentAmount"],
    methods: {
      handleSelectAmount(data) {
        this.$emit("modifyComponentAmount", data);
      },
      groupBy(arr, property) {
        return arr.reduce((acc, cur) => {
          const groupName = cur[property] || this.ungroupedIemsName;
          acc[groupName] = [...(acc[groupName] || []), cur];
          return acc;
        }, {});
      },
    },
    beforeMount() {
      const groupedComponentList = this.groupBy(
        this.componentList,
        "groupName"
      );
      // sort alphabetically
      this.sortedComponentList = Object.keys(groupedComponentList)
        .sort()
        .reduce(function (acc, key) {
          acc[key] = groupedComponentList[key];
          return acc;
        }, {});
    },
    components: { ItemComponentsListItem },
  };
</script>

<style lang="scss" scoped>
  .component-amount_alter.flex-center {
    cursor: pointer;
    color: $col_beta-darker;
    margin: 0 0.25rem;
  }
  .component-alterations-list {
    &_group {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $col_alpha;
    }
    &_group-name {
      padding: 0.5rem 0 0.25rem;
      font-weight: 600;
      color: $col_alpha;
      position: sticky;
      top: 0;
      z-index: 3;
      background: #ffffff;
      border-bottom: 1px solid $col_alpha-lighter;
      margin-top: 0;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    &_item {
    }
  }
</style>
