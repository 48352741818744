import Enumerable from "linq";

export const resolveDietary = (item, components) => {
  const includedBase = Enumerable.from(item.dietaryType).distinct().toArray();

  const includedComponentAllDietary = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default > 0)
    .select((x) => x.dietaryType)
    .toArray();

  let includedComponents =
    includedComponentAllDietary.length > 0
      ? includedComponentAllDietary.reduce((a, b) =>
          a.filter((c) => b.includes(c))
        )
      : [];

  const addedArrays = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default == 0)
    .select((x) => x.dietaryType)
    .toArray();

  let added =
    addedArrays.length > 0
      ? addedArrays.reduce((a, b) => a.filter((c) => b.includes(c)))
      : [];

  let all = Enumerable.from(includedBase).intersect(includedComponents);

  if (added.length > 0) {
    all = all.intersect(added);
  }

  all = all.distinct().toArray();

  return all;
};
