<template>
  <div class="status-delay">
    <h2>
      <Icon :path="mdiClockPlusOutline" :size="16" />
      <span class="text">Delay</span>
    </h2>
    <div class="status-delay__options">
      <button :disabled="updatingStatus" @click="$emit('delay', '0:00:05:00')">
        5<span class="unit">min</span>
      </button>
      <button :disabled="updatingStatus" @click="$emit('delay', '0:00:10:00')">
        10<span class="unit">min</span>
      </button>
    </div>
  </div>
</template>
<script>
  import { mdiClockPlusOutline } from "@mdi/js";
  export default {
    data() {
      return {
        mdiClockPlusOutline,
      };
    },
    props: {
      updatingStatus: {
        type: Boolean,
      },
    },
    emits: ["delay"],
  };
</script>

<style lang="scss">
  .status-delay {
    @include flex($dir: column, $jc: center);
    padding: 0.5rem 1rem;
    min-width: 130px;
    background: $col_offwhite;
    height: 100%;

    h2 {
      @include flex;
      font-size: 0.875rem;
      margin: 0.25rem 0;
    }

    &__options {
      @include flex($g: 0.25rem, $wrap: nowrap);
      width: 100%;
    }
    button {
      flex: 1;
      @include contained-button(
        $fs: 1rem,
        $h: 80px,
        $p: 0.5rem,
        $w: 100%,
        $bg: white
      );

      box-shadow: 0 0 0.5rem $col_disabled;
      gap: 0;
      min-width: 60px;
      span.unit {
        font-size: 0.75rem;
      }
    }

    @media screen and (max-width: $mobile_max_width) {
      max-width: inherit;
      width: 100%;
      padding: 0.5rem 1rem;
      &__options {
        button {
          height: 60px !important;
        }
      }
    }
  }
</style>
