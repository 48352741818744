<template>
  <div class="order-items">
    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>
    <div v-else>
      <h4>Order Items</h4>
      <table>
        <tr v-for="item in orderItems" :key="item.id">
          <td colspan="2">
            <OrderItem :item="item" />
          </td>
        </tr>
        <tr v-if="includeServiceCharge">
          <td class="sub-totals right">Sub-Total</td>
          <td class="sub-totals right">
            {{ formatMoney(this.subTotal) }}
          </td>
        </tr>
        <tr v-if="includeServiceCharge">
          <td class="service-charge right">Service Charge</td>
          <td class="service-charge right">
            {{ formatMoney(this.serviceCharge) }}
          </td>
        </tr>
        <tr>
          <td class="totals right">Total</td>
          <td class="totals right">
            {{ formatMoney(this.total) }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import { useToast } from "vue-toastification";
  import store from "@/store";
  import OrderItem from "@/components/Orders/Details/OrderItem";

  export default {
    components: {
      OrderItem,
    },

    data() {
      return {
        loading: false,
        orderItems: [],
        subTotal: 0,
        includeServiceCharge: false,
        ifShowNote: false,
        serviceCharge: 0,
        total: 0,
      };
    },

    props: {
      order: {
        type: Object,
      },
    },

    methods: {
      getComponentAlterationClass(componentAlteration) {
        if (componentAlteration.levelDelta > 0) {
          return componentAlteration.levelDelta ==
            componentAlteration.levelAbsolute
            ? "new"
            : "extra";
        } else {
          return "removal";
        }
      },

      renderComponentAlterationLabel(componentAlteration) {
        return `${componentAlteration.name} × ${Math.abs(
          componentAlteration.levelDelta
        )}`;
      },
    },

    mounted() {
      this.loading = true;

      store.state.apiPublic.client.endpoints.orders
        .getPricing(this.order.id, this.order.key)
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          }

          return Promise.reject();
        })
        .then((data) => {
          this.orderItems = data.items;
          this.subTotal = data.subTotal;
          this.includeServiceCharge = data.includeServiceCharge;
          this.serviceCharge = data.serviceCharge?.amount ?? 0;
          this.subTotal = data.subTotal;
          this.total = data.total;

          this.loading = false;
        })
        .catch((error) => {
          window.log.error(error);

          useToast().error("Could not update order.");

          this.loading = false;
        });
    },
  };
</script>

<style lang="scss">
  .order-items {
    h4 {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }

    table {
      width: 100%;
      max-height: 180px;
      overflow-y: scroll;
      overflow-x: hidden;
      border-spacing: 0;
      border-collapse: collapse;

      tr {
        width: 100%;

        td {
          border-bottom: 1px solid #ddd;
          padding: 0.3em;
        }

        .alterations {
          display: inline-block;
          color: $col_faded;
          font-size: 0.8rem;

          background: #eee;
          border-radius: 8px;
          padding: 0.3em 0.8em;
          margin: 0.3em;

          &:before {
            content: "+";
            display: inline-block;
            padding-right: 0.25rem;
          }

          &.removal {
            color: $col_delta-darker;
            font-weight: 600;

            &:before {
              content: "-";
              display: inline-block;
              padding-right: 0.25rem;
            }
          }

          &.extra {
            color: $col_gamma;
            font-weight: 600;
          }

          &.new {
            color: $col_alpha-lighter-2;
            font-weight: 600;
          }
        }

        .sub-totals {
          font-weight: bold;
          font-size: 1.1rem;
        }

        .totals {
          font-weight: bold;
          font-size: 1.3rem;
          border-bottom-width: 0;
        }

        .right {
          text-align: right;
        }
      }
    }

    .note-button {
      @include flex;
    }
  }
</style>
