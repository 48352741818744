<template>
  <div :id="`chart_${chartId}`" class="chart" :class="chartData.type">
    <canvas :id="`canvas_${chartId}`"></canvas>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";

  export default {
    data() {
      return {
        chartId: null,
        chartData: {},
      };
    },

    props: {
      id: {
        type: String,
      },
      data: {
        type: Object,
      },
      type: {
        type: String,
      },
      legend: {
        type: Object,
      },
    },

    created() {
      if (this.id == null) {
        this.chartId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      } else {
        this.chartId = this.id;
      }

      this.chartData = {
        type: this.type ?? "bar",
        data: this.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0.2,
          scales: {
            //   y: {
            //     ticks: {
            //       beginAtZero: true,
            //       padding: 25,
            //     },
            //   },
          },
          plugins: {
            legend: {
              display: this.legend.show,
              position: this.legend.position,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  return `${tooltipItem.label}: ${this.formatMoney(
                    tooltipItem.raw
                  )}`;
                },
              },
            },
          },
        },
      };
    },

    mounted() {
      const ctx = document.getElementById(`canvas_${this.chartId}`);
      new Chart(ctx, this.chartData);
    },
  };
</script>
