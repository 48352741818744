<template>
  <div v-if="!loaded" class="loading-dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loaded: false,
      };
    },
  };
</script>
