<template>
  <div
    class="ticket"
    :class="{
      small: this.viewSize == 'small',
      normal: this.viewSize == 'normal',
      placed: this.entry.order.status === 'Placed',
      producing: this.entry.order.status === 'Producing',
      'ready-for-collection': this.entry.order.status === 'ReadyForCollection',
      'non-related': ticketNotRelated,
    }">
    <NoteBar :order="entry.order" v-if="entry.order.note" />
    <DelayedBar
      v-if="entry.order.timings.delayedBy != null"
      :duration="entry.order.timings.delayedBy"
      :orderId="entry.order.id" />
    <div class="unpaid" v-if="this.entry.order.paymentPending">
      <span>£ DUE</span>
    </div>
    <div
      class="header"
      v-if="viewSize == 'normal'"
      :class="{
        delivery: this.entry.order.channel === 'Delivery',
        walkup: this.entry.order.channel === 'WalkUp',
      }">
      <TicketChannel
        v-if="!ifWalkUpOrderView"
        :order="entry.order"
        @click="showItemsModal = true" />

      <div class="sub-header-item due-section">
        <div
          class="time-sorting"
          :class="{
            'by-placed': ifByPlacedAt,
            'for-walkup-orders': ifWalkUpOrderView,
          }">
          <DueForHeader
            :order="entry.order"
            :ifHighlight="!ifByPlacedAt && ifWalkUpOrderView" />
          <PlacedAtHeader
            :order="entry.order"
            v-if="ifWalkUpOrderView"
            :ifHighlight="ifByPlacedAt" />
        </div>

        <template v-if="getTimeToStart() != null">
          <div class="due-in row align-center">
            <Icon :path="mdiTimerOutline" :size="20" />
            <div class="due-in__time-left" :class="dueTimeStatus">
              {{ getDueTimeWording }}
            </div>
          </div>
        </template>
      </div>

      <div class="ticket-checkbox">
        <div class="checkbox">
          <input
            @click="ticketClicked"
            type="checkbox"
            name=""
            :id="entry.order.id"
            :disabled="
              ticketNotRelated ||
              updatingStatus ||
              (![null, entry.order.status].includes(selectedStatus) &&
                selectedTickets.length > 0)
            "
            :checked="ifSelected"
            v-if="!(ifSelected && updatingStatus)" />
          <LoadingSpinner v-if="ifSelected && updatingStatus" />
        </div>
      </div>
    </div>

    <div
      class="sub-header"
      v-if="viewSize == 'normal'"
      @click="showItemsModal = true">
      <div
        class="sub-header-item order-status"
        :class="entry.order.status"
        v-if="ifWalkUpOrderView">
        {{ entry.order.status }}
      </div>
      <div class="sub-header-item order-info">
        <div class="contents" v-if="entry.order.channel === 'TableService'">
          <span class="table-number">
            <Icon :path="mdiTablePicnic" :size="12" />{{
              entry.order.tableNumber
            }}
          </span>
        </div>
        <div class="contents" v-else>
          <span class="order-number">
            <Icon :path="mdiPound" :size="12" />{{ this.entry.order.number }}
          </span>
        </div>
      </div>
      <div class="eat-in-take-out" v-if="this.allowEatOnPremises">
        <span class="pill eat-in" v-if="this.entry.order.eatOnPremises"
          >Eat In</span
        >
        <span class="pill take-out" v-else>Take Out</span>
      </div>
      <div class="sub-header-item timings">
        <span
          style="display: flex; gap: 0.2em"
          v-if="this.entry?.estimatedRange != null">
          <Icon :path="mdiTimelapse" :size="16" />
          {{ this.getTimings(this.entry.estimatedRange) }}
        </span>
      </div>
      <ClubAccount
        v-if="entry.order.customer.id !== 0"
        :customer="entry.order.customer"
        :clubPointRewardLevel="clubPointRewardLevel"
        :orderCounts="entry.order.customerOrdersOutlet" />
      <div class="sub-header-item customer club" v-else>
        <Icon :path="mdiAccount" :size="16" />
        <span class="ellipsis">{{ entry.order.customer.name }}</span>
      </div>
    </div>

    <div
      class="details"
      :class="[{ expanded: showAllDetails }, { shrink: ifDetailsOverflow }]"
      v-if="viewSize == 'normal'">
      <template v-if="this.entry.order.items == null">
        <span class="error">This order contains no items.</span>
      </template>

      <template v-else>
        <!-- <div
          ref="details-orders"
          v-for="section in this.getSections()"
          :key="this.entry.order.id + '/' + section.id"> -->
        <!-- <div class="section-header">
            {{ section.name }}
          </div> -->

        <div
          class="item highlight"
          v-for="item in getHighlightedItems"
          :key="item.id">

          <ItemName
            :item="item"
            :orderId="this.entry.order.id"
            @change="changeItemStatus" />
          <ul
            class="component-alterations"
            v-for="componentAlteration in item.componentAlterations"
            :key="componentAlteration.id">
            <li
              class="component-alteration"
              :class="[
                componentAlteration.levelAbsolute >
                componentAlteration.levelDelta
                  ? 'extra'
                  : 'new',
              ]"
              v-if="componentAlteration.levelDelta > 0">
              {{ componentAlteration.name }} x
              {{ componentAlteration.levelDelta }}
            </li>
            <li
              class="component-alteration removal"
              v-else-if="componentAlteration.levelDelta < 0">
              No {{ componentAlteration.name }}
            </li>
          </ul>
        </div>
        <div
          class="item non-related"
          v-for="item in getNonHighlightedItems"
          :key="item.id">
          <ItemName
            :item="item"
            :orderId="this.entry.order.id"
            :disabled="true" />
          <!-- <div
            v-for="componentAlteration in item.componentAlterations"
            :key="componentAlteration.id">
            <div v-if="componentAlteration.levelDelta > 0">
              {{ componentAlteration.name }} x
              {{ componentAlteration.levelDelta }}
            </div>
            <div v-else-if="componentAlteration.levelDelta < 0">
              No {{ componentAlteration.name }}
            </div>
          </div> -->
        </div>
        <!-- </div> -->
      </template>
    </div>

    <div
      class="toggle-details"
      v-if="
        this.entry.order.status === 'ReadyForCollection' && ifDetailsOverflow
      "
      @click="toggleShowDetails">
      {{
        showAllDetails ? "Show less" : `Show all (${entry.order.items.length})`
      }}
    </div>

    <!-- <div
      class="status"
      @click="this.$emit('fireOrderStatusModal', this.entry.order)"
      v-if="viewSize == 'normal'">
      Update Status
    </div> -->

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showItemsModal"
          class="modal"
          :maxWidth="'900'"
          @close="showItemsModal = false">
          <OrderDetails
            :order="entry.order"
            @close="showItemsModal = false"
            :clubPointRewardLevel="clubPointRewardLevel"
            :orderCounts="entry.order.customerOrdersOutlet" />
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import ApiKitchenScheduleEntry from "@tucktrucks/platform-base-private";
  // import Enumerable from "linq";
  import { LocalDateTime, LocalTime, ChronoUnit } from "@js-joda/core";
  import {
    mdiTablePicnic,
    mdiTimerOutline,
    mdiTimelapse,
    mdiAccount,
    mdiNoteAlertOutline,
    mdiPound,
  } from "@mdi/js";
  import ModalDialog from "@/components/ModalDialog";
  import OrderDetails from "@/components/Orders/OrderDetails";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import TicketChannel from "@/components/Ticket/TicketChannel.vue";
  import DueForHeader from "@/components/Ticket/DueForHeader.vue";
  import PlacedAtHeader from "@/components/Ticket/PlacedAtHeader.vue";
  import DelayedBar from "@/components/Orders/DelayedBar.vue";
  import ClubAccount from "@/components/Club/ClubAccount.vue";
  import ItemName from "@/components/Ticket/ItemName.vue";
  import NoteBar from "@/components/Ticket/NoteBar.vue";

  export default {
    components: {
      ModalDialog,
      OrderDetails,
      TicketChannel,
      DueForHeader,
      PlacedAtHeader,
      DelayedBar,
      ClubAccount,
      ItemName,
      NoteBar,
    },
    props: {
      entry: {
        type: ApiKitchenScheduleEntry,
      },

      group: {
        type: Object,
      },

      groupkey: {
        type: String,
      },

      viewMode: {
        type: Number,
        default: 1,
      },

      viewSize: {
        type: String,
        default: "normal",
      },

      updatingStatus: {
        type: Boolean,
        default: false,
      },

      allowEatOnPremises: {
        type: Boolean,
        default: false,
      },

      ifWalkUpOrderView: { type: Boolean, default: false },
      ifByPlacedAt: { type: Boolean, default: false },

      clubPointRewardLevel: { type: Number },
    },

    data() {
      return {
        mdiTablePicnic,
        mdiTimerOutline,
        mdiTimelapse,
        mdiAccount,
        mdiNoteAlertOutline,
        mdiPound,

        showItemsModal: false,
        showAllDetails: false,
        timeToStartInMins: null,
        timeToStartInHrsAndMins: { hour: null, min: null },
        ifDetailsOverflow: false,
      };
    },

    computed: {
      ...mapGetters({
        selectedStatus: "ticketSelect/selectedStatus",
        selectedTickets: "ticketSelect/selectedTickets",
        displayedMenuSectionItems: "filters/getMenuSectionItems",
        ifShowAllMenuSections: "filters/getIfShowAllMenuSections",
      }),

      ifSelected() {
        return this.selectedTickets.includes(this.entry.order.id);
      },

      dueTimeStatus() {
        const timeToStart = this.getTimeToStart();
        if (timeToStart >= 1 && timeToStart <= 10) {
          return "soon";
        }
        if (timeToStart === 0) {
          return "now";
        }
        if (timeToStart <= -1) {
          return "overrun";
        }
        return "";
      },

      getDueTimeWording() {
        const timeToStart = this.getTimeToStart();
        let { hour, min } = this.timeToStartInHrsAndMins;

        if (hour > 23) {
          const days = parseInt(hour / 24);

          return `${days} days`;
        }

        const minUnit = hour ? "m" : "min";
        const hourUnit = min ? "h" : "hr";
        hour = hour ? hour + hourUnit : "";
        min = min ? min + minUnit : "";

        const separator = hour && min ? " " : "";

        if (timeToStart === 0) {
          return "now";
        }
        if (timeToStart <= -1) {
          return "-" + hour + separator + min;
        }
        return hour + separator + min;
      },

      getViewSize() {
        return this.viewSize;
      },

      getHighlightedItems() {
        if (this.ifShowAllMenuSections || this.ifWalkUpOrderView)
          return this.entry.order.items;
        return this.entry.order.items.filter(({ item }) =>
          this.displayedMenuSectionItems.includes(item.id)
        );
      },

      getNonHighlightedItems() {
        if (this.ifShowAllMenuSections || this.ifWalkUpOrderView) return;
        return this.entry.order.items.filter(
          ({ item }) => !this.displayedMenuSectionItems.includes(item.id)
        );
      },

      ticketNotRelated() {
        return (
          this.entry.order.items.length === this.getNonHighlightedItems?.length
        );
      },
    },

    watch: {
      timeToStartInMins: {
        handler: function () {
          if (this.timeToStartInMins === null) return;

          const mins = Math.abs(this.timeToStartInMins);
          this.timeToStartInHrsAndMins = {
            hour: Math.floor(mins / 60),
            min: mins % 60,
          };
        },
        deep: true,
        immediate: true,
      },

      showItemsModal(newValue) {
        if (newValue) store.dispatch("ticketSelect/reset");
      },
    },

    methods: {
      changeItemStatus(payload) {
        let item = this.entry.order.items.find((x) => x.id == payload.item.id);

        if (item != null) {
          item.status = payload.status;
        }
      },

      ticketClicked() {
        store.dispatch("ticketSelect/handleTicketSelection", {
          entry: this.entry,
          group: this.group,
          groupkey: this.groupkey,
        });
      },

      getTimings(estimatedRange) {
        try {
          let start = LocalDateTime.parse(estimatedRange.start);
          let end = LocalDateTime.parse(estimatedRange.end);
          let seconds = ChronoUnit.SECONDS.between(start, end);

          if (seconds <= 120) {
            return seconds + "s";
          }

          return Math.round(seconds / 6) / 10 + "m";
        } catch (ex) {
          window.log.error(ex);
          return "";
        }
      },

      getTimeToStart() {
        try {
          // hacks to allow us to use the local time in a date time sense, api should be changed really
          const kitchenEnd = LocalDateTime.parse(
            this.entry.order.timings.kitchenEndDateTime
          );

          const selectedSlot = LocalTime.parse(
            this.entry.order.channel == "Delivery"
              ? this.entry.order.timings.advertised.collectionTime
              : this.entry.order.timings.selectedSlot
          );

          const selectedSlotDate = kitchenEnd
            .toLocalDate()
            .atTime(selectedSlot);
          const now = LocalDateTime.now();

          const minutes = now.until(selectedSlotDate, ChronoUnit.MINUTES);

          this.timeToStartInMins = minutes;

          return minutes;
        } catch {
          return null;
        }
      },

      toggleShowDetails() {
        this.showAllDetails = !this.showAllDetails;
      },
    },

    mounted() {
      if (this.$refs["details-orders"]) {
        const detailsHeight = this.$refs["details-orders"][0].clientHeight;
        this.ifDetailsOverflow = detailsHeight > 90;
      }
    },
  };
</script>

<style lang="scss">
  .ticket {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background: $col_white;
    margin: 0.5rem;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.25);

    &.non-related {
      filter: grayscale(1);
      color: $col_disabled !important;

      .sub-header-item {
        color: $col_disabled !important;
      }
      .channel {
        background: $col_disabled;
      }
      .item {
        color: $col_disabled !important;
      }
    }

    .walkup-orders & {
      width: calc(100% - 1rem) !important;
      min-width: 300px !important;
    }

    &.small {
      border-radius: 1.5rem 5px 5px 1.5rem;
      font-size: 1.4rem;
      height: 3rem;

      .channel {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        font-size: 2.2rem;
        vertical-align: baseline;
        padding: auto;
      }
    }

    .tabs-expanded &,
    .mobile-layout:not(.col-view) & {
      width: calc($current-orders-tab-min-width - 1rem);
      margin: 0.5rem 0;
    }

    .header {
      @include flex($wrap: nowrap, $jc: space-around, $g: 0.1rem);
      padding: 0.5rem 0.25rem;
      border-radius: 0;
      border-bottom: 1px solid #b9b9b9;
      cursor: pointer;

      &.disabled {
        cursor: initial;
        opacity: 0.5;
      }
      .loading-spinner {
        border-width: 0.25em;
        width: 1.5rem;
        margin-top: -12px;
        height: 1.5rem;
      }
    }

    .sub-header-item {
      @include flex($wrap: nowrap);

      .walkup-orders & {
        justify-content: flex-start;
        padding-left: 0.5rem;
      }

      &.order-status {
        padding: 0.25rem 0.5rem;
        &.Placed {
          background: $col_gamma;
          color: white;
        }
        &.Producing {
          background: $col_faded;
          color: white;
        }
      }

      &.due-section {
        flex-grow: 1;

        > div {
          gap: 0.25rem;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .time-sorting {
          @include flex($wrap: nowrap);

          &.for-walkup-orders {
            .due-time {
              width: auto;
            }
            span.label {
              font-size: 0.65rem;
              padding-right: 0.75rem;
              width: min-content;
              color: $col_gray;
            }
            span.mdi-icon {
              display: none;
            }
          }

          &.by-placed {
            flex-direction: row-reverse;
          }

          &:not(.for-walkup-orders) {
            span.label {
              display: none;
            }
          }
        }
      }
    }
    .order-info {
      @include flex($jc: flex-start);
      cursor: pointer;
      padding: 0 0.35rem;

      .contents {
        position: absolute;
        font-size: 1.2rem;
        font-weight: 600;

        span {
          display: inline-block;
        }

        .table-number .mdi-icon {
          padding-right: 0.2em;
        }
      }
    }

    .due-time {
      width: 80px;

      .walkup-orders & {
        span {
          font-size: 0.7rem;
          line-height: 1.1em;
        }
      }

      &.highlight {
        font-weight: bold !important;
      }
    }

    .due-in {
      &__time-left {
        letter-spacing: 0.03em;
        &.overrun {
          color: $col_urgent;
        }
        &.soon {
          color: $col_soon;
        }
        &.now {
          color: $col_alpha-lighter;
          font-size: 1.25rem;
          text-transform: capitalize;
          letter-spacing: unset;
        }
      }
    }

    .unpaid {
      position: absolute;
      width: 100%;

      span {
        color: $col_black;
        background: #f9ca24;
        border-radius: 8px;
        font-size: 0.7em;
        position: relative;
        display: inline-block;
        left: -8px;
        padding: 0.2em 0.5em;
        line-height: 20px;
        height: 20px;
        transform: rotate(-35deg);
      }
    }

    .sub-header {
      @include flex($wrap: nowrap, $g: 0.25rem, $jc: flex-start);
      font-size: 0.75rem;
      padding: 0.25rem 0;

      .order-info,
      .eat-in-take-out,
      .timings {
        flex-shrink: 0;
        width: 60px;
        white-space: nowrap;
      }

      .customer {
        flex-shrink: 1;
      }

      .eat-in-take-out {
        margin-right: 0.75em;

        .pill {
          border-radius: 0 0 0.25rem 0.25rem;
          padding: 0.25rem 0.5rem;
          color: #fff;
          font-weight: 900;
        }

        .eat-in {
          background: $col_gamma;
        }

        .take-out {
          background: $col_beta;
        }
      }

      .sub-header-item {
        display: flex;
        align-items: center;
        color: $col_faded-darker;

        &.timings {
          justify-content: center;
          gap: 0.15rem;
          font-weight: 400;
        }

        &.customer {
          justify-content: end;
          gap: 0.15rem;

          font-weight: 400;
          min-width: 60px;
          padding-right: 0.25rem;
          flex-grow: 1;
        }
      }
    }

    .details {
      overflow: hidden;
    }

    .section-header {
      background: #ececec;
      color: #444;
      font-weight: 600;
      height: 3px;
    }

    .item {
      position: relative;
      margin: 0.5rem 0.5rem 0rem;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 0.25rem;

      &.highlight {
        font-weight: bold;
        filter: unset;
        font-size: 1rem;
        color: black;
      }

      &.non-related {
        color: $col_faded;
        font-size: 0.85rem;
        margin: 0 0.5rem;
        padding-bottom: 0;
      }
    }

    .amount {
      margin-left: -1.5rem;
      min-width: 25px;
      display: inline-block;

      .highlight & {
        font-weight: bold;
      }
    }

    .name {
      margin-left: 1.5rem;

      .highlight & {
        margin-left: 1.75rem;
      }
    }

    .component-alterations {
      margin: 0;
      padding: 0.15rem 0 0 2rem;
      font-size: 0.85rem;
    }

    .component-alteration {
      &:before {
        content: "+";
        display: inline-block;
        padding-right: 0.25rem;
      }

      &.removal {
        color: $col_delta-darker;
        font-weight: 600;

        &:before {
          content: "-";
          display: inline-block;
          padding-right: 0.25rem;
        }
      }

      &.extra {
        color: $col_gamma;
        font-weight: 600;
      }

      &.new {
        color: $col_alpha-lighter-2;
        font-weight: 600;
      }
    }

    .status {
      width: 100%;
      margin: 0 auto;
      padding: 1rem;
      font-size: 0.875rem;
      text-align: center;
      border-top: 1px solid #555;
      cursor: pointer;
      &:hover {
        color: $col_beta-darker;
      }
    }

    &.ready-for-collection {
      .details {
        max-height: 90px;
        overflow-y: scroll;
        &.expanded {
          max-height: none;
        }
      }

      .toggle-details {
        text-align: center;
        border-top: 1px solid $col_faded;
        padding: 1rem 0;
        font-size: 0.8rem;
        line-height: 1.2rem;
        color: $col_faded;
      }
    }
  }

  .ticket-checkbox {
    display: flex;
    align-items: center;
    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }
  }
</style>
