<template>
  <div v-if="!loading && timeline.length === 0" class="no-orders">
    <p>No orders found.</p>
    <LoadingDots />
  </div>

  <h3
    v-if="!loading && showGroupTitle"
    class="group-title"
    @click="manualToggleGroup">
    <Icon
      :path="getStatusIconPath"
      :title="getStatusTitle"
      class="section-icon" />
    <span
      class="count"
      :class="[
        { updated: countChanged },
        { added: countChanged > 0 },
        { reduced: countChanged < 0 },
      ]">
      {{ getStatusOrderCount }}
    </span>
    {{ getStatusTitle }}
    <GroupToggle :ifExpand="ifExpand" />
  </h3>

  <div
    v-if="!loading && statusTimeline.length === 0 && ifExpand"
    class="no-orders tight">
    <p v-if="!ifWalkUpOrderView">No orders.</p>
    <p v-else>No walk-up orders to be produced</p>
  </div>

  <div
    v-if="!loading && getStatusTilelineSlots.length > 0 && ifExpand"
    class="ticket-groups">
    <div class="timing-mode-split">
      <div
        v-if="
          service?.options?.schedulingMode == 'Dynamic' &&
          getStatusTilelineAsap.length > 0
        "
        class="timing-mode-item">
        <TicketAsapQueue :tickets="getStatusTilelineAsap" />
      </div>
      <div
        v-if="
          (service?.options?.schedulingMode == 'Dynamic'
            ? getStatusTilelineSlots
            : getStatusTileline
          ).length > 0
        "
        class="timing-mode-item">
        <div
          v-for="group in service?.options?.schedulingMode == 'Dynamic'
            ? getStatusTilelineSlots
            : getStatusTileline"
          :key="group.key() + status"
          class="ticket-group-container_outer"
          :class="status">
          <div
            class="ticket-group-container"
            :class="{
              active: group
                .getSource()
                .every((entry) =>
                  this.selectedTickets.includes(entry.order.id)
                ),
            }">
            <TicketGroupSelectBox
              v-if="!ifWalkUpOrderView"
              :groupLength="group.getSource().length"
              :group="group.getSource()"
              groupType="Due for"
              :time="formatTime(group.key())"
              :loading="
                selectedGroups.includes(group.key() + status) && updatingStatus
              "
              @click="
                handleGroupSelection({
                  group,
                  groupkey: group.key() + status,
                })
              "
              :disabled="selectedStatus !== null && selectedStatus !== status"
              :checked="
                group
                  .getSource()
                  .every((entry) =>
                    this.selectedTickets.includes(entry.order.id)
                  )
              " />

            <div class="ticket-container" v-if="ifDueForExpand">
              <OrderTicket
                v-for="entry in group.getSource()"
                :clubPointRewardLevel="clubPointRewardLevel"
                :key="entry.order.id"
                :entry="entry"
                :group="group.getSource()"
                :groupkey="group.key() + status"
                :viewMode="viewMode"
                :ifWalkUpOrderView="ifWalkUpOrderView"
                :viewSize="'normal'"
                :updatingStatus="updatingStatus"
                :allowEatOnPremises="this.allowEatOnPremises"
                @fireOrderStatusModal="showOrderStatus(entry.order)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingDots from "@/components/LoadingDots.vue";
  import OrderTicket from "@/components/OrderTicket.vue";
  import {
    mdiAccountClockOutline,
    mdiStove,
    mdiClipboardTextOutline,
  } from "@mdi/js";
  import { mapGetters } from "vuex";
  import Enumerable from "linq";
  import store from "@/store";
  import TicketGroupSelectBox from "@/components/Ticket/TicketGroupSelectBox.vue";
  import GroupToggle from "@/components/Ticket/GroupToggle.vue";
  import TicketAsapQueue from "@/components/Ticket/TicketAsapQueue.vue";

  export default {
    data() {
      return {
        ifExpand: true,
        ifDueForExpand: true,
        countChanged: 0,
        ifManualToggle: false,
        mdiAccountClockOutline,
        mdiStove,
        mdiClipboardTextOutline,
      };
    },

    computed: {
      ...mapGetters({
        selectedStatus: "ticketSelect/selectedStatus",
        selectedTickets: "ticketSelect/selectedTickets",
        selectedGroups: "ticketSelect/selectedGroups",
      }),

      getStatusTileline() {
        return this.statusTimeline;
      },

      getStatusTilelineSlots() {
        return Enumerable.from(this.statusTimeline)
          .where((x) => x.key() != null) // This is awful, relies on bits of data being missing
          .toArray();
      },

      getStatusTilelineAsap() {
        return Enumerable.from(this.statusTimeline)
          .where((x) => x.key() == null) // This is awful, relies on bits of data being missing
          .selectMany((x) => x.getSource())
          .where((x) => x?.order?.timings?.timingMode == "Asap")
          .toArray();
      },

      getStatusTitle() {
        switch (this.status) {
          case "ReadyForCollection":
            return "Ready to Collect";
          default:
            return this.status;
        }
      },

      getStatusIconPath() {
        switch (this.status) {
          case "ReadyForCollection":
            return mdiAccountClockOutline;
          case "Producing":
            return mdiStove;
          default:
          case "Placed":
            return mdiClipboardTextOutline;
        }
      },

      getStatusOrderCount() {
        return this.statusTimeline.map((g) => g.getSource().flat()).flat()
          .length;
      },
    },

    props: {
      status: {
        type: String,
      },
      loading: {
        type: Boolean,
      },
      timeline: {
        type: Object,
      },
      service: {
        type: Object,
      },
      statusTimeline: {
        type: Array,
      },
      updatingStatus: {
        type: Boolean,
      },
      viewMode: { type: String },
      allowEatOnPremises: {
        type: Boolean,
        default: false,
      },
      showGroupTitle: {
        type: Boolean,
        default: true,
      },
      ifWalkUpOrderView: {
        type: Boolean,
        default: false,
      },
      clubPointRewardLevel: { type: Number },
    },

    watch: {
      statusTimeline(newValue, preValue) {
        const totalOrderCount = (t) =>
          t.map((g) => g.getSource().flat()).flat().length;

        if (totalOrderCount(newValue) !== totalOrderCount(preValue))
          this.countChanged =
            totalOrderCount(newValue) - totalOrderCount(preValue);

        // Don't auto collapse, nothing opens it back up again
        //if (totalOrderCount(newValue) === 0) this.ifExpand = false;

        setTimeout(() => {
          this.countChanged = 0;
        }, 2000);
      },
    },

    methods: {
      handleTicketSelection(payload) {
        store.dispatch("ticketSelect/handleTicketSelection", payload);
      },
      handleGroupSelection(payload) {
        store.dispatch("ticketSelect/handleGroupSelection", payload);
      },
      manualToggleGroup() {
        this.ifExpand = !this.ifExpand;
      },
      toggleDueForGroup() {
        this.ifDueForExpand = !this.ifDueForExpand;
      },
    },

    components: {
      LoadingDots,
      OrderTicket,
      TicketGroupSelectBox,
      GroupToggle,
      TicketAsapQueue,
    },
  };
</script>

<style lang="scss" scoped>
  .no-orders {
    @include no-orders;
  }
  .ticket-groups {
    @include ticket-group;

    .timing-mode-split {
      display: flex;
      flex-direction: row;

      .timing-mode-item {
        flex: 50%;
      }
    }
  }

  .ticket-group-container_outer {
    &.ReadyForCollection .ticket-group-container {
      border-color: $col_beta;
    }

    &.Producing .ticket-group-container {
      border-color: $col_faded;
    }
  }

  h3.group-title {
    @include flex($jc: flex-start);
    padding-left: 1rem;
    line-height: 3rem;
    border-bottom: 1px solid $table_border_color;
    border-top: 1px solid $table_border_color;
    font-size: 1.2rem;
    margin: 0;
    position: relative;
    .section-icon {
      width: fit-content;
    }

    .count {
      font-size: 1rem;
      padding-right: 0.05rem;
      background: black;
      color: white;
      line-height: normal;
      padding: 0 0.25rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      text-align: center;
      min-width: 1.5rem;

      &.updated {
        transition: 0.2s;
      }

      &.added {
        background: $col_beta;
      }
      &.reduced {
        background: $col_alpha;
      }
    }
  }
</style>
