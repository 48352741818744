<template>
  <div class="due-time row align-center" :class="{ highlight: ifHighlight }">
    <Icon :path="mdiClockOutline" :size="20" />
    <span class="label">Placed time</span>
    {{ this.getPlacedAt(order) }}
  </div>
</template>
<script>
  import { mdiClockOutline } from "@mdi/js";

  export default {
    data() {
      return {
        mdiClockOutline,
      };
    },
    props: {
      order: {
        type: Object,
      },
      ifHighlight: {
        type: Boolean,
      },
    },
    methods: {
      // [⌚] Format timings for final display. This controls how different channels are approached, as a single unified variable doesn't always make sense.
      getPlacedAt(order) {
        return this.formatDateTime(order.timings.placedAt).slice(-6);
      },
    },
  };
</script>
