<template>
  <div class="item-note">
    <textarea
      v-model="note"
      placeholder="Item Note"
      :disabled="(!ifEditing && item.note) || ifUpdating"
      @input="(e) => updateNote(e.target.value)"
      rows="6">
    </textarea>
    <div class="actions">
      <button
        class="send"
        :disabled="ifUpdating"
        @click="ifEditing = !ifEditing"
        v-if="ifShowCloseButton">
        Close
      </button>
      <button
        class="send"
        :disabled="ifUpdating"
        @click="ifEditing = !ifEditing"
        v-if="!ifEditing && item.note">
        <Icon :path="mdiPencil" :size="12" />Edit
      </button>
      <button
        class="send"
        @click="sendNote"
        :disabled="note === item.note || ifUpdating">
        <template v-if="ifUpdating">Updating Note...</template>
        <template v-else>
          <Icon :path="mdiCheckBold" :size="12" /> Send Note</template
        >
      </button>
    </div>
  </div>
</template>

<script>
  import { mdiCheckBold, mdiPencil } from "@mdi/js";
  import store from "@/store";
  import { useToast } from "vue-toastification";

  export default {
    data() {
      return {
        mdiPencil,
        mdiCheckBold,
        note: null,
        ifEditing: false,
        ifUpdating: false,
      };
    },
    props: {
      item: {
        type: Object,
      },
      ifShowCloseButton: {
        type: Boolean,
      },
    },
    methods: {
      updateNote(note) {
        this.note = note;
      },

      async sendNote() {
        this.ifEditing = false;
        this.ifUpdating = true;

        try {
          // Update Note To Order
          const response =
            await store.state.apiPrivate.client.endpoints.orderItems.updateNote(
              this.note,
              this.item.id
            );
          if (response.status === 200) {
            this.ifUpdating = false;
            const toast = useToast();
            return toast.success("Successfully updated the order item note");
          } else {
            return Promise.reject("Failed to update the order item note");
          }
        } catch (error) {
          this.ifUpdating = false;
          const toast = useToast();
          toast.error(error);
        }
      },

      async getNote() {
        try {
          const response =
            await store.state.apiPrivate.client.endpoints.orderItems.getNote(
              this.item.id
            );
          if (response.status === 200) {
            this.note = response.data.data;
          }
          this.ifUpdating = false;
        } catch (error) {
          this.ifUpdating = false;
        }
      },
    },
    mounted() {
      this.getNote();
    },
  };
</script>

<style lang="scss">
  .item-note {
    width: 100%;
    @include flex($ai: flex-start);
    padding: 0.5rem;

    textarea {
      flex: 1;
      min-width: 200px;
      height: 100px;
      width: 100%;
      padding: 0.5rem;
      border: 1px solid $col_gray;
      border-radius: $button_radius;
      font-size: 1rem;
    }

    .actions {
      padding: 0.25rem;

      @include flex($ai: flex-start, $g: 0.25rem);
      button {
        flex-shrink: 0;
        height: 4rem;
        padding: 1rem 0.5rem;
        font-size: 0.75rem;
        background: white !important;

        &:disabled {
          background: white !important;
        }
      }
    }
  }
</style>
