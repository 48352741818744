<template>
  <div
    class="app-container"
    ref="mainApp"
    :class="{
      'mobile-layout': mobileLayout,
      'condensed-layout': condensedLayout,
      'orders-view': ordersView,
      'tabs-view': tabsView,
      'cart-view': cartView,
      'tabs-expanded': tabsExpanded,
      'tabs-hidden': tabsHidden,
      'col-view': viewMode == 1,
      'row-view': viewMode == 2,
    }">
    <TopBar
      class="topbar"
      :key="$route.params.serviceId"
      :mobileLayout="mobileLayout"
      :disableWalkups="disableWalkups"
      @swapView="swapView"
      @expandTabs="expandTabs"
      @selectTab="selectTab"
      @swapToTabView="swapToTabView"
      @toggleFullscreen="toggleFullscreen" />

    <div class="main-content-container" id="main-content-container">
      <div class="current-orders">
        <div
          class="panel-control"
          v-if="!isNaN(serviceId)"
          :class="{ expanded: tabsExpanded }">
          <span @click="togglePanels('left')">
            <Icon
              :path="mdiArrowLeftBold"
              :size="28"
              title="Swap View"
              class="panel-control_btn"
              v-if="!tabsExpanded" />
          </span>
          <span @click="togglePanels('right')">
            <Icon
              :path="mdiArrowRightBold"
              :size="28"
              title="Swap View"
              class="panel-control_btn"
              v-if="tabsExpanded || (!tabsExpanded && !tabsHidden)" />
          </span>
        </div>

        <div
          class="current-orders_content"
          :class="{ 'update-mode': selectedTickets.length > 0 }">
          <div class="warning" v-if="showServiceEndedWarning">
            <Icon :path="mdiAlertCircleOutline" />
            <p>
              This service was in operation on
              {{ formatDate(selected.service.date) }}, and has now ended.

              <!-- <span v-if="currentServiceGetDate && currentServiceGetTodayId">
                <a href="#" v-on:click="goToToday()">Click here</a> to view
                today's schedule.</span
              >
              <span v-else>No service today</span> -->
            </p>
          </div>

          <div
            v-if="loading"
            class="loading-spinner_container"
            style="padding: 5rem 0">
            <LoadingSpinner />
          </div>

          <TicketGroupsByStatus
            :clubPointRewardLevel="service?.service?.clubPointRewardLevel"
            :loading="loading"
            :timeline="timeline"
            :statusTimeline="timelineReady"
            :updatingStatus="updatingStatus"
            :allowEatOnPremises="service?.service?.allowEatOnPremises"
            ref="ReadyForCollection"
            status="ReadyForCollection" />

          <TicketGroupsByStatus
            :clubPointRewardLevel="service?.service?.clubPointRewardLevel"
            :loading="loading"
            :timeline="timeline"
            :statusTimeline="timelineProducing"
            :updatingStatus="updatingStatus"
            :allowEatOnPremises="service?.service?.allowEatOnPremises"
            ref="Producing"
            status="Producing" />

          <TicketGroupsByStatus
            :clubPointRewardLevel="service?.service?.clubPointRewardLevel"
            :loading="loading"
            :timeline="timeline"
            :statusTimeline="timelinePlaced"
            :updatingStatus="updatingStatus"
            :service="service?.service"
            :allowEatOnPremises="service?.service?.allowEatOnPremises"
            ref="Placed"
            status="Placed" />
        </div>
      </div>

      <div class="tabbed-section" ref="tabSection" v-if="!isNaN(serviceId)">
        <PaymentProcessLoading
          v-if="getCartLockByPaymentStatus && getZettleIntent" />
        <div class="tabs">
          <div
            class="tab-btn"
            v-on:click="changeTab(1)"
            v-bind:class="[activeTab === 1 ? 'active' : '']">
            Service
          </div>
          <div
            class="tab-btn walk-ups"
            v-if="this.allowWalkUps"
            v-on:click="changeTab(2)"
            v-bind:class="[activeTab === 2 ? 'active' : '']">
            Walk Ups
          </div>
          <div
            class="tab-btn"
            v-on:click="changeTab(3)"
            v-bind:class="[activeTab === 3 ? 'active' : '']">
            Orders
          </div>
          <div
            class="tab-btn"
            v-on:click="changeTab(4)"
            v-bind:class="[activeTab === 4 ? 'active' : '']">
            Stock
          </div>
        </div>

        <div v-if="activeTab === 1" class="tab-content">
          <ServiceSummaryLock :key="$route.params.serviceId" />
        </div>

        <div
          v-if="activeTab === 2 && this.allowWalkUps"
          class="tab-content flex"
          :class="{ 'update-mode': selectedTickets.length > 0 }">
          <WalkUps
            :key="$route.params.serviceId"
            :walkupProducingCount="walkupProducingCount"
            :walkupPlacedCount="walkupPlacedCount"
            :tabsExpanded="tabsExpanded"
            :mobileLayout="mobileLayout"
            :timeline="timeline"
            :timelineOrder="walkUpTimelineByDueFor"
            :timelineOrderByPlacedOrder="walkUpTimelineByPlacedOrder"
            :updatingStatus="updatingStatus"
            :allowEatOnPremises="service?.service?.allowEatOnPremises"
            :productionTime="productionTime"
            :showWalkUpOrderView="showWalkUpOrderView"
            @swapWalkUpView="showWalkUpOrderView = !showWalkUpOrderView"
            @swapView="swapView"
            ref="walkups" />
        </div>

        <div v-if="activeTab === 3" class="tab-content">
          <OrdersTab :key="$route.params.serviceId" />
        </div>

        <div v-if="activeTab === 4" class="tab-content">
          <StockControlTab :key="$route.params.serviceId" />
        </div>
      </div>
    </div>

    <transition name="slide">
      <TaskBar
        v-if="selectedTickets.length > 0"
        :tickets="getSelectedTickets"
        :currentStatus="selectedStatus"
        :updatingStatus="updatingStatus"
        @delay="delayOrders"
        @updateStatus="confirmOrders"
        @hideTaskBar="hideTaskBar" />
    </transition>
    <OrderConfirmationDialog
      :orders="getSelectedTickets"
      ref="orderConfirmationDialog"></OrderConfirmationDialog>
    <transition name="fade">
      <NetworkError v-if="this.hasNetworkError()" />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { LocalDate, LocalTime, ChronoUnit } from "@js-joda/core";
  import { useToast } from "vue-toastification";
  import TopBar from "@/components/TopBar/TopBar.vue";
  import WalkUps from "@/views/WalkUps.vue";
  import StockControlTab from "@/components/StockControl/StockControlTab.vue";
  import OrdersTab from "@/components/OrdersTab.vue";
  import store from "@/store";
  import Enumerable from "linq";
  import NetworkError from "@/components/Network/NetworkError.vue";
  import ServiceSummaryLock from "../components/ServiceSummary/ServiceSummaryLock.vue";
  import {
    mdiArrowLeftBold,
    mdiArrowRightBold,
    mdiAlertCircleOutline,
    mdiCheckboxBlankOutline,
  } from "@mdi/js";
  import TaskBar from "@/components/TaskBar.vue";
  import TicketGroupsByStatus from "@/components/TicketGroupsByStatus.vue";
  import OrderConfirmationDialog from "../components/Orders/OrderConfirmationDialog.vue";
  import PaymentProcessLoading from "@/components/Orders/Payments/PaymentProcessLoading.vue";

  export default {
    components: {
      //TestHub,
      TopBar,
      WalkUps,
      StockControlTab,
      OrdersTab,
      NetworkError,
      ServiceSummaryLock,
      TaskBar,
      TicketGroupsByStatus,
      OrderConfirmationDialog,
      PaymentProcessLoading,
    },

    data() {
      return {
        fullscreen: false,
        firstLoad: true,
        loading: false,
        activeTab: -1,
        job: null,
        timelineLookup: {},
        condensedLayout: null,
        mobileLayout: null,
        ordersView: true,
        tabsView: null,
        cartView: null,
        tabsExpanded: null,
        tabsHidden: false,
        viewMode: 1,
        selectedOrder: null,
        serviceId: parseInt(this.$route.params?.serviceId),
        service: null,
        disableWalkups: false,
        newlyArrivedItems: [],
        updatingStatus: false,
        showModal: null,
        showWalkUpOrderView: false,
        productionTime: null,
        walkupProducingCount: null,
        walkupPlacedCount: null,
        statusToShowOnWalkUpOrderView: ["Placed", "Producing"],
        pollingForOrders: false,

        mdiArrowLeftBold,
        mdiArrowRightBold,
        mdiAlertCircleOutline,
        mdiCheckboxBlankOutline,
      };
    },
    computed: {
      ...mapGetters({
        hasNetworkError: "network/networkError",
        getEventById: "repoEvents/getById",
        selected: "authentication/selected",
        settingsNotificationSounds: "settings/getNotificationSounds",
        getCartLockByPaymentStatus: "payment/getLock",
        getZettleIntent: "payment/getIntent",
        selectedStatus: "ticketSelect/selectedStatus",
        selectedTickets: "ticketSelect/selectedTickets",
        selectedGroups: "ticketSelect/selectedGroups",
        displayedChannels: "filters/getChannels",
        getIfShowAllChannels: "filters/getIfShowAllChannels",
        getMenuSectionIds: "filters/getMenuSectionIds",
        getEatIn: "filters/getEatIn",
        getTakeOut: "filters/getTakeOut",
      }),

      getSelectedTickets() {
        return (
          Object.values(this.timelineLookup)
            .filter((x) => this.selectedTickets?.includes(x.order.id))
            ?.map((x) => x.order) ?? []
        );
      },

      allowWalkUps() {
        if (this.disableWalkups === true || !this.selected?.service) {
          return false;
        }

        let status = this.selected?.service?.status ?? "Unknown";
        let date = LocalDate.parse(this.selected?.service?.date);

        return (
          status == "Planned" || // enable future walk ups, useful for weekend debugging 😭
          status == "Running" ||
          date.isEqual(this.today) // this is poor but lets walk ups start/end early
        );
      },

      today() {
        return LocalDate.now();
      },

      timeline() {
        return Enumerable.from(Object.values(this.timelineLookup)).orderBy(
          (x) => x.estimatedRange.start
        );
      },
      timelineReady() {
        return this.assembleOrdersByStatus("ReadyForCollection");
      },
      timelineProducing() {
        return this.assembleOrdersByStatus("Producing");
      },
      timelinePlaced() {
        return this.assembleOrdersByStatus("Placed");
      },
      timelinePlacedSlots() {
        return this.assembleOrdersByStatus("Placed", "SelectedSlot");
      },
      walkUpTimelineByPlacedOrder() {
        return this.assembleWalkUpOrdersByPlacedAt("Placed");
      },
      walkUpTimelineByDueFor() {
        return this.assembleWalkUpOrdersByDueFor("Placed");
      },

      showServiceEndedWarning() {
        if (this.selected.service) {
          const date = this.selected.service.date;
          const status = this.selected.service.status;
          return (
            LocalDate.parse(date).isBefore(this.today) || status === "Finished"
          );
        }
        return false;
      },
    },
    watch: {
      "$route.params.serviceId": {
        handler: function (newId, oldId) {
          if (newId === oldId) return;
          this.loading = true;
        },
        deep: true,
        immediate: true,
      },
      "$route.meta.cartView": {
        handler: function (cartView) {
          this.cartView = cartView;
        },
        deep: true,
        immediate: true,
      },
      timelineReady() {
        this.getTotalProductionTime();
        this.getWalkUpOrdersCount();
      },

      timelineProducing() {
        this.getTotalProductionTime();
        this.getWalkUpOrdersCount();
      },

      timelinePlaced(newValue) {
        this.getTotalProductionTime();
        this.getWalkUpOrdersCount();
        this.$nextTick(function () {
          for (const entry of newValue) {
            const group = entry.getSource();

            for (const ticket of group) {
              if (this.newlyArrivedItems.includes(ticket.order.id)) {
                let groupKey = entry.key() + "Placed";
                store.dispatch("ticketSelect/deselectGroup", groupKey);
              }
            }
          }
        });
      },
    },
    methods: {
      getTotalProductionTime() {
        const queuingOrders = Enumerable.from(this.timeline)
          .where(
            (x) =>
              this.statusToShowOnWalkUpOrderView.includes(x.order.status) &&
              x.order.channel === "WalkUp"
          )
          .toArray();

        if (queuingOrders.length === 0) return (this.productionTime = null);

        const totalSeconds = queuingOrders
          .map(({ order }) => {
            let start = LocalTime.parse(
              order.timings.advertised.preparationStartTime
            );
            let end = LocalTime.parse(
              order.timings.advertised.preparationEndTime
            );
            let seconds = ChronoUnit.SECONDS.between(start, end);
            return seconds;
          })
          .reduce((prev, next) => prev + next);

        this.productionTime = Math.round(totalSeconds / 6) / 10 + "m";
      },
      swapWalkUpView(view) {
        this.showWalkUpOrderView = view === "orders" ? true : false;
      },
      getDueFor(order) {
        if (order.channel == "WalkUp") {
          return order.timings.selectedSlot;
          //return order.timings.advertised.preparationStartTime;
        }

        if (order.channel == "Delivery") {
          return order.timings.advertised.collectionTime;
        }

        return order.timings.selectedSlot;
      },

      getPlacedAt(order) {
        return order.timings.placedAt;
      },

      toggleFullscreen() {
        const doc = window.document;
        const docEl = doc.documentElement;

        const requestFullScreen =
          docEl.requestFullscreen ||
          docEl.mozRequestFullScreen ||
          docEl.webkitRequestFullScreen ||
          docEl.msRequestFullscreen;

        const cancelFullScreen =
          doc.exitFullscreen ||
          doc.mozCancelFullScreen ||
          doc.webkitExitFullscreen ||
          doc.msExitFullscreen;

        if (
          !doc.fullscreenElement &&
          !doc.mozFullScreenElement &&
          !doc.webkitFullscreenElement &&
          !doc.msFullscreenElement
        ) {
          requestFullScreen.call(docEl);
        } else {
          cancelFullScreen.call(doc);
        }
      },

      pollForOrders() {
        if (this.pollingForOrders) {
          return;
        }
        this.getKitchenSchedule();
      },

      getKitchenSchedule() {
        if (this.hasNetworkError()) {
          return;
        }

        const apiPrivate = store.state.apiPrivate.client;
        const serviceId = this.$route.params.serviceId;

        this.pollForOrders = true;
        apiPrivate.endpoints.kitchen
          .getSchedule(serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            } else if (response.status == 204) {
              return [];
            } else if (response.status == 404) {
              return null;
            } else {
              return Promise.reject({
                serviceId: this.serviceId,
                response,
              });
            }
          })
          .then((data) => {
            if (data != null) {
              const itemsOld = Object.values(this.timelineLookup);
              const itemsNew = Enumerable.from(data)
                // We apply the filters again here purely for the ding. This needs a tidy up.
                .where((x) => {
                  const filterSections = this.getMenuSectionIds;

                  // If the filter isn't active, drop out
                  if (filterSections.length == 0) {
                    return true;
                  }

                  // All of the sections on the items on this order. Contains duplicates, minor performance issue.
                  const sectionIds = x.order.items.map(
                    (item) => item.section.id
                  );

                  // There are filter sections, and some of them have at least one of the section ids contained within.
                  // If not, it's removed from the timeline.
                  return filterSections.some((filterSections) =>
                    sectionIds.includes(filterSections)
                  );
                })
                .where((x) => {
                  if (this.getEatIn && this.getTakeOut) {
                    return true;
                  }

                  if (this.getEatIn) {
                    return !x.order.eatOnPremises;
                  }
                  if (this.getTakeOut) {
                    return x.order.eatOnPremises;
                  }

                  return true;
                })

                .toArray();
              const itemsNewKeys = Enumerable.from(itemsNew).select(
                (x) => x.order.id
              );

              var newItems = false;
              this.newlyArrivedItems = [];

              for (const item of itemsOld) {
                const id = item.order.id;
                if (!itemsNewKeys.contains(id)) {
                  delete this.timelineLookup[id];
                }
              }

              for (const item of itemsNew) {
                const id = item.order.id;

                if (this.timelineLookup[id] == null) {
                  newItems = true;
                  this.newlyArrivedItems.push(id);
                }

                this.timelineLookup[id] = item;
              }

              if (newItems) {
                if (
                  this.settingsNotificationSounds &&
                  process.env.VUE_APP_PLAY_SOUNDS == "true"
                ) {
                  // Uses the audio subsystem which attempts to work around apples required interaction limitation.
                  //window.audio.playOnce(this.getCdnFile("/sounds/new-order.mp3"));

                  let audioFile = this.getCdnFile("/sounds/new-order.mp3");
                  new Audio(audioFile).play();
                }
              }
            }
          })
          .catch((error) => {
            window.log.error("[🛍️] Poll failed.", error);
          })
          .finally(() => {
            this.loading = false;
            this.firstLoad = false;
            this.pollingForOrders = false;
          });
      },
      checkScreen() {
        this.windowWidth = window.innerWidth;

        if (this.windowWidth > 768) {
          this.mobileLayout = false;
        } else {
          this.mobileLayout = true;
          this.retractTabs();
        }
      },
      swapView() {
        this.ordersView = !this.ordersView;
        this.tabsView = !this.tabsView;
      },
      changeTab(tabNumber) {
        window.localStorage.setItem("t", tabNumber);
        this.activeTab = tabNumber;
        if (this.$refs.walkups) {
          this.$refs.walkups.hideCheckout();
        }
      },
      togglePanels(direction) {
        if (this.tabsHidden) {
          this.tabsHidden = false;
        } else if (this.tabsExpanded) {
          this.tabsExpanded = false;
        } else {
          direction === "right" ? this.hideTabs() : this.expandTabs();
        }
      },
      expandTabs() {
        if (!this.mobileLayout) {
          this.tabsExpanded = true;
        }
      },
      retractTabs() {
        this.tabsExpanded = false;
      },
      hideTabs() {
        this.tabsHidden = true;
      },
      showOrderStatus(order) {
        this.selectedOrder = order;
      },
      viewOrdersCol() {
        this.viewMode = 1;
      },
      viewOrdersRow() {
        this.viewMode = 2;
      },
      selectTab(tab) {
        this.activeTab = tab;
      },
      swapToTabView() {
        this.ordersView = false;
        this.tabsView = true;
        this.showWalkUpOrderView = false;
      },

      // This controls how different channels are approached, as a single unified variable doesn't always make sense.
      getDueTime(order) {
        if (order.channel == "WalkUp") {
          return order.timings.selectedSlot;
          //return order.timings.advertised.preparationStartTime;
        }

        if (order.channel == "Delivery") {
          return order.timings.advertised.collectionTime;
        }

        return order.timings.selectedSlot;
      },

      getOrderTimingKeyByStatus() {
        return "selectedSlot";
      },

      assembleOrdersByStatus(status, mode = null) {
        const timingKey = this.getOrderTimingKeyByStatus(status);

        const channelNames = (this.displayedChannels ?? []).map(
          ({ name }) => name
        );

        const orders = this.timeline
          .where((x) => {
            // Filter down mixed mode
            switch (mode) {
              case "SelectedSlot": {
                return x.order.timings.timingMode == "SelectedSlot";
              }
              case "Asap": {
                return x.order.timings.timingMode == "Asap";
              }
              default:
                return x;
            }
          })
          .where((x) => {
            // Apply filters
            if (this.getIfShowAllChannels) return x.order.status == status;
            return (
              x.order.status == status && channelNames.includes(x.order.channel)
            );
          })
          .where((x) => {
            const filterSections = this.getMenuSectionIds;

            // If the filter isn't active, drop out
            if (filterSections.length == 0) {
              return true;
            }

            // All of the sections on the items on this order. Contains duplicates, minor performance issue.
            const sectionIds = x.order.items.map((item) => item.section.id);

            // There are filter sections, and some of them have at least one of the section ids contained within.
            // If not, it's removed from the timeline.
            return filterSections.some((filterSections) =>
              sectionIds.includes(filterSections)
            );
          })
          .where((x) => {
            if (this.getEatIn && this.getTakeOut) {
              return true;
            }

            if (this.getEatIn) {
              return !x.order.eatOnPremises;
            }
            if (this.getTakeOut) {
              return x.order.eatOnPremises;
            }

            return true;
          })
          .orderBy((x) => this.getDueFor(x.order))
          .thenBy((x) => x.order.id)
          .thenBy((x) => x.order.timings.actual[timingKey])
          .groupBy(
            (k) => this.getDueFor(k.order),
            (v) => v
          )
          .toArray();

        return orders;
      },

      assembleWalkUpOrdersByDueFor(status) {
        const timingKey = this.getOrderTimingKeyByStatus(status);

        const orders = Enumerable.from(this.timeline)
          .where(
            (x) =>
              this.statusToShowOnWalkUpOrderView.includes(x.order.status) &&
              x.order.channel == "WalkUp"
          )
          .orderBy((x) => this.getDueFor(x.order))
          .thenBy((x) => x.order.id)
          .thenBy((x) => x.order.timings.actual[timingKey])
          .groupBy(
            (k) => this.getDueFor(k.order),
            (v) => v
          )
          .toArray();

        return orders;
      },

      assembleWalkUpOrdersByPlacedAt(status) {
        const timingKey = this.getOrderTimingKeyByStatus(status);

        const orders = Enumerable.from(this.timeline)
          .where(
            (x) =>
              this.statusToShowOnWalkUpOrderView.includes(x.order.status) &&
              x.order.channel == "WalkUp"
          )
          .orderBy((x) => this.getPlacedAt(x.order))
          .thenBy((x) => x.order.id)
          .thenBy((x) => x.order.timings.actual[timingKey])
          .groupBy(
            (k) => this.getPlacedAt(k.order),
            (v) => v
          )
          .toArray();

        return orders;
      },

      getWalkUpOrdersCount() {
        const producing = Enumerable.from(this.timeline)
          .where(
            (x) => x.order.status == "Producing" && x.order.channel == "WalkUp"
          )
          .toArray();
        const placed = Enumerable.from(this.timeline)
          .where(
            (x) => x.order.status == "Placed" && x.order.channel == "WalkUp"
          )
          .toArray();

        this.walkupProducingCount = producing.length;
        this.walkupPlacedCount = placed.length;
      },
      async confirmOrders(newStatus) {
        let result = await this.$refs.orderConfirmationDialog.show(newStatus);

        if (result) {
          this.updateStatus(newStatus);
        }
      },

      async updateStatus(newStatus) {
        this.updatingStatus = true;

        let removedSpaceStatus = newStatus.replaceAll(" ", "");

        if (removedSpaceStatus == "ReadytoCollect") {
          removedSpaceStatus = "ReadyForCollection";
        }

        const safeToMove =
          newStatus == "Completed"
            ? this.getSelectedTickets
                .filter((x) => !x.paymentPending)
                .map((x) => x.id)
            : this.selectedTickets;

        const response =
          await store.state.apiPrivate.client.endpoints.orders.bulkChangeStatus(
            {
              status: removedSpaceStatus,
              identifiers: safeToMove,
            }
          );

        if (response.status === 200) {
          for (const id of this.selectedTickets) {
            store.dispatch("repoOrders/updateStatus", {
              id,
              status: removedSpaceStatus,
            });
          }

          const affectedOrdersIds =
            response.data.meta["affected-orders"].$values;

          // update timelineLookUp
          for (const id of affectedOrdersIds) {
            if (Object.keys(this.timelineLookup).includes(id + "")) {
              this.timelineLookup[id].order.status = removedSpaceStatus;
            }
          }

          this.assembleOrdersByStatus(removedSpaceStatus);
          this.assembleOrdersByStatus(this.selectedStatus);

          this.updatingStatus = false;
          this.hideTaskBar();

          if (newStatus == "Completed") {
            const unpaidPhoneOrders = this.getSelectedTickets.filter(
              (x) => x.channel == "Phone" && x.paymentPending
            );

            if (unpaidPhoneOrders.length > 1) {
              useToast().error(
                "Could not move multiple unpaid phone orders to Completed."
              );
            } else if (unpaidPhoneOrders.length == 1) {
              window.log.info("show pay");
            }
          }
        }
      },

      hideTaskBar() {
        store.dispatch("ticketSelect/reset");
      },

      async delayOrders(delayedBy) {
        this.updatingStatus = true;

        const payload = {
          ids: this.selectedTickets,
          delayedBy,
        };

        try {
          const response =
            await store.state.apiPrivate.client.endpoints.orderDelays.update(
              payload
            );
          if (response.status === 200) {
            this.hideTaskBar();
            this.updatingStatus = false;
          }
        } catch (error) {
          this.updatingStatus = false;
          const errorMsg = `Failed to delay orders ${this.selectedTickets}  by: ${delayedBy}`;
          if (error.status >= 400 && error.status < 500) {
            window.log.info(errorMsg, error);
          } else {
            window.log.error(errorMsg, error);
          }
        }
      },
    },

    async created() {
      window.addEventListener("resize", this.checkScreen);
      this.checkScreen();

      await store.getters["repoAllergens/getAll"]();
    },

    async mounted() {
      this.serviceId = parseInt(this.$route.params.serviceId);

      if (this.serviceId) {
        this.service = await store.getters["repoServices/getByIdWithQuery"](
          this.serviceId,
          {
            includeVendor: true,
          }
        );

        const { vendor } = this.service;
        this.disableWalkups = vendor.disableWalkups;
        if (this.firstLoad === true) {
          this.loading = true;
        }
        try {
          this.activeTab = parseInt(window.localStorage.getItem("t"));
        } catch {
          // empty
        }
        if (this.activeTab == null || isNaN(this.activeTab)) {
          this.activeTab = 1;
        }
        const rate = process.env.VUE_APP_SCHEDULE_POLL_RATE;
        window.log.info(
          "[🛍️] Starting poll for new Orders. Rate: " + rate + "ms"
        );
        this.job = window.setIntervalAndExecute(this.pollForOrders, rate);
      }
    },

    unmounted() {
      if (this.job != null) {
        window.clearInterval(this.job);
        this.job = null;
      }

      this.$refs["tabSection"]?.removeEventListener(this.hideTaskBar());
    },
  };
</script>

<style lang="scss">
  .loading-spinner_container {
    width: 100%;
    text-align: center;
  }

  .app-container {
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .topbar {
    height: 3.95rem;
    z-index: 99;
  }

  .main-content-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    width: 100%;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
    background: $col_offwhite;
    overflow: hidden;
  }

  .panel-control {
    position: absolute;
    top: 0;
    right: 0;
    background: $col_faded;
    border-radius: 0 0 0 5px;
    color: white;
    height: 48px;
    text-align: center;
    cursor: pointer;
    z-index: 20;
    display: flex;
    align-items: center;

    &_btn {
      cursor: pointer;
      height: 100%;
      padding: 0 5px;
    }

    .mobile-layout & {
      display: none;
    }

    &.expanded {
      width: 40px;
    }

    .tabs-hidden & {
      width: 40px;
    }
  }

  .current-orders {
    position: relative;
    width: 100%;
    transition: width 0.4s, transform 0.25s;

    .mobile-layout.orders-view & {
      position: absolute;
      transform: translateX(0);
      height: 100%;
    }

    .mobile-layout.tabs-view & {
      transform: translateX(-150%);
    }

    .mobile-layout.orders-view & {
      left: 0;
      width: 100%;
      right: 0;
    }

    .mobile-layout.tabs-expanded & {
      position: absolute;
      width: 100%;
    }

    .tabs-expanded & {
      width: 400px;
      min-width: $current-orders-tab-min-width;
    }

    &_content {
      position: absolute;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;
      background: white;
      border-right: 1px solid $table_border_color;
      //top: 6rem; - reactivate once Order Headers element is in.
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      //display: flex;
      display: block;
      flex-direction: row;
      align-items: flex-start;

      &.update-mode {
        padding-bottom: 300px;
      }

      .warning {
        @include flex($ai: flex-start, $wrap: nowrap, $jc: flex-start);
        background: $col_beta-lighter;
        padding: 0.5rem 3rem 0.5rem 1.5rem;
        font-size: 0.9rem;

        .image-icon {
          margin: 0 0.5rem 0 -0.5rem;
          display: flex;
          justify-content: center;
        }

        p {
          margin: 0;
          span {
            display: block;
          }
        }
      }

      .tabs-expanded & {
        flex-direction: column;
        top: 0;
      }

      .row-view & {
        flex-direction: row;
      }

      .col-view & {
        flex-direction: column;
      }

      .mobile-layout & {
        flex-direction: column;
        //top: 5rem;
        top: 0;
      }
    }

    &_header {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      z-index: 10;
      border-bottom: 1px solid black;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
    }
  }

  .tabbed-section {
    width: $tab_width;
    flex-shrink: 0;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0px;
    transition: width 0.4s, transform 0.25s;

    .mobile-layout & {
      width: 100%;
    }

    .mobile-layout.orders-view & {
      transform: translateX(150%);
    }

    .mobile-layout.tabs-view & {
      transform: translateX(0);
      position: absolute;
      left: 0;
    }

    .pinned-bottom-buttons {
      @include flex($wrap: nowrap);
      background: white;
      padding: 0.5rem;
      transition: all 0.05s;
      left: unset;
      right: 0;
      bottom: 0;
      position: fixed;
      width: 100%;

      > button {
        @include contained-button($fs: 0.875rem, $p: 0.5rem);
        gap: 0.25rem;
      }

      @media only screen and (min-width: $mobileLayout_breakpoint_width) {
        width: $tab-width;
      }
    }

    .tabs-expanded & {
      width: calc(100% - $current-orders-tab-min-width);

      .pinned-bottom-buttons {
        width: calc((100% - $current-orders-tab-min-width) / 2 + 0.5rem);

        &.payment-methods {
          width: calc(100% - $current-orders-tab-min-width);
        }

        > button {
          flex: 1;
        }
      }

      @media screen and (max-width: $mobileLayout_breakpoint_width) and (orientation: portrait) {
        .menu-sections {
          margin-bottom: calc($button_height + 1rem);
        }

        .pinned-bottom-buttons {
          width: calc(100% - $current-orders-tab-min-width);
        }
      }
    }

    .tabs-hidden & {
      width: 0;
      overflow: hidden;

      .pinned-bottom-buttons {
        display: none;
      }
    }

    .tabs-hidden.mobile-layout & {
      width: 100%;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-evenly;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #ddd;

    // .tab-content & {
    //   margin: 0 -1px;
    // }
  }

  .tab-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 4px 1rem 0px;
    background-color: #ddd;
    border-right: 1px solid #fff;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    line-height: 42px;
    border-bottom: 2px solid #ddd;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: #aaa;
      border-bottom: 2px solid #aaa;
      color: #fff;
    }

    &.active {
      background-color: #fff;
      color: #484848;
      border-bottom: 2px solid #ff7b7d;
      cursor: default;
    }

    &.walk-ups.active {
      cursor: pointer;
    }
  }

  .tab-content {
    padding: 0.05rem 0;
    position: absolute;
    top: 3rem;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: $col_white;
    transition: 0.3s;
    display: flex;
    flex-direction: column;

    &.flex {
      display: flex;
      padding: 0.05rem 0;
      flex-direction: column;
    }

    .tab-btn {
      background: #fff;
      color: #666;
      &:not(.active):hover {
        border-bottom-color: #ddd;
        background: #f3f3f3;
      }
    }

    &.update-mode .walkup-orders .ticket-groups {
      padding-bottom: 300px;
    }

    .tab-btn.active {
      color: #000;
    }
  }

  .cart-controls {
    display: flex;
    justify-content: space-evenly;

    &_btn {
      padding: 0.5rem;
      cursor: pointer;
      border: 1px solid black;

      &:hover {
        background: #eee;
      }
    }
  }

  .filter_btn {
    background: none;
    border: 1px solid #333;
    padding: 0.75rem;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }

  /* List Item transitions */
  .slide-enter {
    opacity: 0;
  }

  .slide-move {
    // transition: transform 1s;
  }

  .slide-enter-active {
    // animation: slide-in 0.5s ease-out forwards;
    transition: opacity 0.5s;
  }

  .slide-leave-active {
    position: absolute;
    width: 100%;
    // animation: slide-out 0.5s ease-out forwards;
    // transition: opacity 0.5s;
    opacity: 0;
  }

  @keyframes slide-in {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(350px);
    }
  }

  /* Route transitions */
  .route-enter-from {
    opacity: 0;
  }
  .route-enter-active {
    transition: all 0.1s ease-out;
  }
  .route-leave-to {
    opacity: 0;
  }
  .route-leave-active {
    transition: all 0.1s ease-in;
  }

  .slide-enter-active {
    transition: all 0.1s ease-out;
  }

  .slide-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-leave-to,
  .slide-enter-from {
    transform: translateY(100%);
    opacity: 0;
  }
</style>
