<template>
  <div class="payment-card-manual">
    <h4>Take a card payment manually, then confirm the amount here</h4>

    <div class="payment-card-manual__controls payment-controls">
      <div class="payment-card-manual__totals">
        <button
          class="full-amount"
          :disabled="amount >= this.fundingDue"
          @click="amount = this.fundingDue">
          Full Amount
        </button>

        <h1 class="massive beta">
          <small>Payment Amount:</small>
          {{ formatMoney(amount) }}
        </h1>

        <button
          class="add"
          @click="takePayment()"
          :disabled="amount <= 0 || getCartLockByPaymentStatus || !getQueueIsEnd">
          Take Payment
        </button>
      </div>

      <PaymentPicker v-model="amount" :maxValue="this.fundingDue" />
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import PaymentPicker from "@/components/Orders/Payments/PaymentPicker.vue";

  export default {
    components: {
      PaymentPicker,
    },

    data() {
      return {
        amount: this.fundingDue,
      };
    },

    props: {
      order: {
        type: Object,
      },
      orderIsCart: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["paymentCreated"],

    watch: {
      order: {
        handler: function () {
          if (this.amount > this.fundingDue) {
            this.amount = this.fundingDue;
          }
        },
        deep: true,
        immediate: true,
      },
    },

    computed: {
      ...mapGetters({
        getQueueIsEnd: "cart/getQueueIsEnd",
        getCartLockByPaymentStatus: "payment/getLock",
        getPaymentPending: "payment/getPaymentPending"
      }),

      fundingDue() {
        return this.orderIsCart
          ? this.order.summary.paymentPending
          : this.order.paymentPending;
      },
    },

    methods: {
      async takePayment() {
        const payment = this.orderIsCart
          ? 'payment/createCartPaymentCardManual'
          : 'payment/createOrderPaymentCardManual';
        const id = this.orderIsCart ? this.order.cartId : this.order.id;
        const key = this.orderIsCart ? this.order.cartKey : this.order.key;
        const amount =
          this.amount > this.fundingDue ? this.fundingDue : this.amount;

        const status = await store.dispatch(payment, { amount, id, key });
        if (status === 200) this.amount = this.getPaymentPending;
      },
    },

    mounted() {
      this.amount = this.fundingDue;
    },
  };
</script>

<style lang="scss">
  .payment-card-manual {
    border-top: 2px solid $col_beta;

    h4 {
      width: 100%;
      text-align: center;
    }

    &__controls {
      display: flex;
      gap: 1em;
    }

    &__totals {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      .space {
        flex-grow: 1;
      }

      button {
        background: $col_beta;
        border: 0;
        border-radius: 8px;
      }

      button.full-amount {
        @include outlined-button($h: 2.5rem, $col: $col_beta);
      }

      button.add {
        width: 66%;
        font-size: 1.4em;
        @include contained-button;
      }

      h1.massive {
        font-size: 4rem;
      }
    }
  }
</style>
