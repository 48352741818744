<template>
  <ul class="menu-sections" :class="{ 'full-height': getCartItemsCount === 0 }">
    <li v-for="section in availableSections" :key="section.id" :id="section.id">
      <div :id="`section-${section.id}`" class="section-anchor"></div>
      <h4 class="menu-section_heading">{{ section.name }}</h4>
      <ul
        class="menu-items"
        :class="{ simple: !showDescription && !showImage }">
        <li
          v-for="item in section.itemData"
          :key="item.id"
          class="menu-item"
          :class="{ outOfStock: item.outOfStock || item.isServiceBlocked }"
          @click="$emit('selectItem', item, section)">
          <!-- <p style="float:right"><AllergensDisplay :allergens=dish.allergens /></p> -->
          <div
            v-if="item.thumbnails.length && showImage"
            class="thumb"
            :style="getCssThumbnail(item.thumbnails[0])"></div>
          <div class="detail">
            <h4>{{ item.name }}</h4>
            <p v-if="showDescription" class="description">
              {{ item.description }}
            </p>
            <p
              v-if="item.outOfStock || item.isServiceBlocked"
              class="outOfStock">
              <span class="material-icons material-icons-round">
                <Icon :path="mdiMinusCircle" :size="13" />
              </span>
              {{ item.outOfStock.text }}
            </p>
            <p
              v-if="!item.outOfStock && !item.isServiceBlocked"
              class="menu-item_price">
              {{ this.formatMoney(item.price) }}
            </p>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import { mdiMinusCircle } from "@mdi/js";
  import { getThumbnailUrl, getCssThumbnail } from "@/helpers/images";

  export default {
    data() {
      return {
        mdiMinusCircle,
      };
    },

    emits: ["selectItem"],
    props: {
      availableSections: { type: Array },
      getCartItemsCount: {
        type: Number,
      },
      showDescription: {
        type: Boolean,
      },
      showImage: {
        type: Boolean,
      },
    },

    methods: { getThumbnailUrl, getCssThumbnail },
  };
</script>

<style lang="scss">
  .menu-sections {
    max-height: 65%;
    overflow-y: scroll;
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin: 0;
    transition: all 0.2s linear;

    &.full-height {
      max-height: 100%;
    }
    @media only screen and (max-height: 740px) {
      max-height: 50%;
    }
  }
  .menu-items {
    padding: 0 0.5rem 0.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }

  .menu-item {
    border-radius: $card_radius;
    margin-bottom: 0;
    cursor: pointer;
    background: #fff;
    color: #666;
    position: relative;
    border: 1px solid rgb(232, 232, 232);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    height: 120px;
    min-width: 300px;
    @include flex($dir: row, $ai: stretch, $wrap: nowrap, $g: 0);

    @media screen and (max-width: $tablet_max_width) {
      .expanded & {
        min-width: unset;
        height: auto;
      }
    }

    .simple & {
      height: auto;
      min-height: 60px;
      align-items: center;
    }

    .thumb {
      display: block;
      width: 100px;
      height: 100px;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;
      border-radius: $card_radius;
      margin: 10px 0 10px 10px;
    }

    .detail {
      padding: 0.75rem;
      width: 100%;
      flex: 1;
      @include flex($dir: column, $ai: flex-start, $jc: space-between, $wrap: nowrap);

      h4 {
        padding: 0;
        margin: 0;
        font-weight: bold;
        flex: 1;
      }

      p.description {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 0.85rem;
        line-height: 1.2em;
        margin-top: 0.25rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    h4 {
      color: #000;
      margin: 0 0 0.5rem 0;
      font-size: 1.2rem;
      line-height: 1.1em;
      font-weight: 500;
    }

    p {
      margin: 0 0 0.5rem 0;
      font-weight: 400;
      font-size: 0.85rem;
    }

    &:not(.outOfStock):hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &.outOfStock {
      box-shadow: none;
      cursor: not-allowed;
      padding-bottom: 10px;

      h4,
      p {
        color: rgb(173, 173, 173);
      }
    }

    p.outOfStock {
      color: $col_delta-lightest;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      padding: 0;

      span.material-icons {
        font-size: 0.8rem;
      }
    }

    &_price {
      font-weight: bold;
      text-align: right;
      color: #000;
      font-size: 1.2rem !important;
      margin-bottom: 0 !important;
      width: inherit;
    }
  }
</style>
