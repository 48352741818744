<template>
  <div class="walkup-order-status">
    <p class="status">
      <span class="title">
        <Icon :path="mdiTimelapse" :size="12" />
        <span class="text">Walk Up Queue</span>
      </span>
      {{ productionTime || 0 }}
    </p>
    <p class="status">
      <span class="title">
        <span class="text">Orders / Producing</span>
      </span>
      {{ timelineOrderCount || 0 }} /
      {{ walkupProducingCount || 0 }}
    </p>
  </div>
</template>
<script>
  import { mdiTimelapse } from "@mdi/js";

  export default {
    data() {
      return { mdiTimelapse };
    },
    props: {
      productionTime: {
        type: String,
      },
      timelineOrderCount: {
        type: Number,
        default: 0,
      },
      walkupProducingCount: { type: Number, default: 0 },
    },
  };
</script>

<style lang="scss">
  .walkup-order-status {
    background: $col_beta-lightest;
    @include flex($wrap: nowrap, $g: 2rem);
    padding: 0.5rem 1rem;
  }
  .status {
    margin: 0;
    color: $col_softblack;
    font-size: 1.25rem;
    @include flex($g: 0, $wrap: nowrap, $jc: space-between);
    flex: 1;

    .title {
      font-size: 0.7rem;
      width: 80px;
      line-height: 1.1em;
      @include flex($g: 0.5rem, $wrap: nowrap);

      @media screen and (max-width: $tablet_min_width) {
        width: 50px;
      }
      .text {
        word-break: break-word;
      }
    }
  }
</style>
