<template>
  <div class="panel-features row">
    <ViewSwitchButton @click="$emit('swapWalkUpView')" />
    <button
      v-for="action in actions"
      :key="action.actionName"
      type="button"
      @click="action.function"
      :class="action.className">
      {{ action.actionName }}
    </button>
  </div>
</template>

<script>
  import ViewSwitchButton from "@/components/Walkups/ViewSwitchButton";

  export default {
    props: {
      actions: Array,
    },
    emits: ["swapWalkUpView"],
    components: { ViewSwitchButton },
  };
</script>

<style lang="scss">
  .panel-features {
    @include walk-up-panel-actions;
  }
</style>
