<template>
  <div class="payments-list">
    <AgGridVue
      style="width: 100%"
      :style="this.fundingDue ? 'height: 140px' : 'height: 500px'"
      class="ag-theme-alpine"
      :class="{ 'no-data': !rowData }"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData" />
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";

  export default {
    components: {
      AgGridVue,
    },

    data() {
      return {
        job: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: [],
      };
    },

    props: {
      order: {
        type: Object,
      },
      orderIsCart: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      columnDefs() {
        return [
          {
            headerName: "Amount",
            field: "receivedAmount",
            resizable: true,
            sortable: true,
            filter: true,
            width: 110,
            pinned: "left",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Received",
            field: "receivedOn",
            resizable: true,
            sortable: true,
            sort: "desc",
            filter: true,
            width: 150,
            valueFormatter: (params) => {
              return this.formatDateTime(params.value);
            },
          },
          {
            headerName: "Type",
            field: "type",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
            valueFormatter: (params) => {
              return this.formatType(params.value);
            },
          },
          {
            headerName: "Details",
            field: "type",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
            cellRenderer: (params) =>
              `<img width=32 src="/images/cards/${this.getCardIcon(
                params
              )}.svg" /><span class='type'>${
                this.formatCardType(params.node.data.cardType) ?? ""
              }</span><span class='code'>${
                this.formatCard4(params.node.data.card4) ?? ""
              }</span>`,
          },
          {
            headerName: "Processor Id",
            field: "processorId",
            resizable: true,
            sortable: true,
            filter: true,
            cellRenderer: (params) =>
              `<span class='code'>${params.value ?? ""}</span>`,
          },
        ];
      },

      fundingDue() {
        return this.orderIsCart
          ? this.order.summary.paymentPending
          : this.order.paymentPending;
      },
    },

    emits: ["payments"],

    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
        this.gridApi?.showLoadingOverlay();
        this.gridApi?.sizeColumnsToFit();
        this.gridApi?.hideOverlay();
      },

      setUpData() {
        const query = this.orderIsCart
          ? {
              carts: [this.order.cartId],
            }
          : {
              orders: [this.order.id],
            };

        return this.$store.state.apiPrivate.client.endpoints.payments
          .query(query)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
          })
          .then((payments) => {
            this.rowData = payments;

            this.$emit("payments", payments);
          });
      },

      formatType(type) {
        switch (type) {
          default:
            return type;
          case "PromotionVoucher":
            return "Voucher";
          case "GiftCard":
            return "Gift Card";
          case "ExternalCard":
            return "Manual Card";
        }
      },

      formatCardType(cardType) {
        if (
          cardType == null ||
          cardType.length <= 0 ||
          cardType == "payment_intent"
        ) {
          return "";
        }

        return cardType;
      },

      formatCard4(card4) {
        if (card4 == null || card4.length <= 0) {
          return null;
        }

        return `<span class='num'>${card4}<span>`;
      },

      getCardIcon(payment) {
        if (payment.value == "Cash") {
          return "cash";
        }

        switch (payment.node.data.cardType) {
          case "American Express":
            return "amex";
          case "Visa":
            return "visa";
          default:
            return "generic";
        }
      },
    },

    mounted() {
      this.job = window.setInterval(() => {
        this.setUpData();
      }, 5000);
    },

    unmounted() {
      window.clearInterval(this.job);
    },
  };
</script>

<style lang="scss">
  .payments-list {
    h4 {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: 0.5rem;
    }

    .ag-row .ag-cell {
      display: flex;
      justify-content: left;
      align-items: center;

      > span {
        display: flex;
        justify-content: left;
        align-items: center;

        .type {
          margin: 0 0.5em;
        }

        .code {
          font-family: monospace;
          font-weight: 700;

          .num {
            background: #ddd;
            padding: 0.25em;
            border-radius: 8px;
          }
        }
      }
    }
  }
</style>
