<template>
  <div class="service-settings">
    <section class="tab-section">
      <h3>Kitchen Capacity</h3>
      <div class="service-control">
        <span class="service-control_btn" @click="decreaseCapacity"
          ><Icon :path="mdiArrowLeftBold" :size="28" title="Decrease Capacity"
        /></span>
        <div class="value-display">{{ getServiceCapacity }}</div>
        <span class="service-control_btn" @click="increaseCapacity"
          ><Icon :path="mdiArrowRightBold" :size="28" title="Increase Capacity"
        /></span>
      </div>
    </section>

    <section class="tab-section">
      <h3>Open Hours</h3>
      <h5>Start Time</h5>
      <div class="service-control">
        <span @click="updateStartTime" class="disabled service-control_btn"
          ><Icon
            :path="mdiArrowLeftBold"
            :size="28"
            title="Decrease Start"
            class="service-control_btn"
        /></span>
        <div class="value-display">
          {{ formatTime(getServiceStart, "HH:mm", "--:--") }}
        </div>
        <span @click="updateStartTime" class="disabled service-control_btn"
          ><Icon
            :path="mdiArrowRightBold"
            :size="28"
            title="Increase Start"
            class="service-control_btn"
        /></span>
      </div>

      <h5>End Time</h5>
      <div class="service-control">
        <span @click="updateStartTime" class="disabled service-control_btn"
          ><Icon
            :path="mdiArrowLeftBold"
            :size="28"
            title="Decrease End"
            class="service-control_btn"
        /></span>
        <div class="value-display">
          {{ formatTime(getServiceEnd, "HH:mm", "--:--") }}
        </div>
        <span @click="updateStartTime" class="disabled service-control_btn"
          ><Icon
            :path="mdiArrowRightBold"
            :size="28"
            title="Increase End"
            class="service-control_btn"
        /></span>
      </div>
    </section>
    <section class="tab-section">
      <div class="service-external-link">
        <a :href="adminAppUrl">View in admin app</a>
      </div>
    </section>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { mdiArrowLeftBold, mdiArrowRightBold } from "@mdi/js";

  export default {
    data() {
      return {
        capacity: null,
        serviceStart: null,
        serviceEnd: null,
        serviceId: parseInt(this.$route.params.serviceId),
        vendorId: null,
        mdiArrowLeftBold,
        mdiArrowRightBold,
      };
    },
    computed: {
      ...mapGetters({
        getServiceCapacity: "kitchenService/getServiceCapacity",
        getServiceStart: "kitchenService/getServiceStart",
        getServiceEnd: "kitchenService/getServiceEnd",
      }),
      adminAppUrl() {
        return `${process.env.VUE_APP_ADMIN_WEB_DOMAIN}/vendor/${this.vendorId}/dashboard`;
        //return `https://admin.tucktrucks.co.uk/vendor/${this.vendorId}/dashboard`;
      },
    },
    methods: {
      getKitchenService() {
        return store.state.kitchenService;
      },
      async decreaseCapacity() {
        const newCapacity = Math.max(this.getServiceCapacity - 1, 1);
        const response =
          await store.state.apiPrivate.client.endpoints.services.updateCapacity(
            newCapacity,
            this.serviceId
          );
        this.capacity = response.data.data;
        store.dispatch("kitchenService/setCapacity", this.capacity);
      },
      async increaseCapacity() {
        const newCapacity = this.getServiceCapacity + 1;
        const response =
          await store.state.apiPrivate.client.endpoints.services.updateCapacity(
            newCapacity,
            this.serviceId
          );
        this.capacity = response.data.data;
        store.dispatch("kitchenService/setCapacity", this.capacity);
      },
      async updateStartTime() {
        // const request = {
        //     timeStart: '11:30:00',
        //     timeEnd: '22:10:00'
        // }
        // await store.state.apiPrivate.client.endpoints.services.updateOpenHours(request, this.serviceId)
      },
      async setVendorId() {
        const service = await store.getters["repoServices/getById"](
          this.serviceId
        );
        this.vendorId = service?.vendor?.id;
      },
    },
    async beforeMount() {
      await this.setVendorId();
    },
    mounted() {
      store.dispatch("kitchenService/startTask", this.serviceId);

      // const toast = useToast()
      // toast.info("I'm an info toast!")
    },
    unmounted() {
      store.dispatch("kitchenService/stopTask");
    },
  };
</script>

<style lang="scss" scoped>
  .service-settings {
    max-width: 600px;
    margin: 0 auto;
  }

  .service-control {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;

    &_btn {
      display: flex;
      align-items: center;
      align-self: stretch;
      justify-content: space-evenly;
      width: 100%;
      height: 3rem;
      text-align: center;
      cursor: pointer;
      background: #f2efef;
      color: $col_gamma;
      font-size: 0;

      &.disabled {
        display: none;
      }
    }
  }

  .value-display {
    padding: 0 2rem;
    height: 3rem;
    line-height: 3rem;
    border: 1px solid #999;
    background: white;
  }

  .service-external-link {
    text-align: center;
  }
</style>
