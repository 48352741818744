<template>
  <!-- :ifForceClose="ifUpdated" -->
  <BaseDropdown
    class="section-filters"
    :width="50"
    dropdownStyle="text"
    :optionsViewWidth="optionsViewWidth"
    @on-expand="getMenuSection">
    <template #field>
      <Icon :path="mdiFilterVariant" />
      <span class="applied-count" v-if="getAppliedCount">
        {{ getAppliedCount }}
      </span>
    </template>
    <template #options>
      <p v-if="loading">Loading available filters...</p>
      <template v-else>
        <div class="section-filter" v-if="availableMenuSections">
          <h4>Menu Sections</h4>
          <ul class="filters">
            <li
              v-for="section of availableMenuSections"
              :key="section.id"
              :class="{
                selected: (selectedSections ?? [])
                  .map((s) => s.id)
                  .includes(section.id),
              }"
              @click="selectSection(section)">
              <Icon :path="section.icon" :size="14" v-if="section.icon" />
              {{ section.name }}
            </li>
          </ul>
        </div>

        <div class="section-filter" v-if="availableChannels">
          <h4>Channels</h4>
          <ul class="filters">
            <li
              v-for="channel of availableChannels"
              :key="channel.id"
              :class="{
                selected: selectedChannels
                  .map((c) => c.id)
                  .includes(channel.id),
              }"
              @click="selectChannel(channel)">
              <Icon :path="channel.icon" :size="14" v-if="channel.icon" />
              {{ formatChannel(channel.name) }}
            </li>
          </ul>
        </div>

        <div class="section-filter">
          <h4>Eat In / Take Out</h4>
          <ul class="filters">
            <li
              :class="{ selected: this.getEatIn }"
              @click="this.toggleEatIn()">
              <Icon :path="mdiFoodForkDrink" :size="14" />
              Eat In
            </li>
            <li
              :class="{ selected: this.getTakeOut }"
              @click="this.toggleTakeOut()">
              <Icon :path="mdiFoodTakeoutBox" :size="14" />
              Take Out
            </li>
          </ul>
        </div>

        <!-- <button type="button" class="apply" @click="update">
          Apply
          <template v-if="getSelectedFilterCount">
            ({{ getSelectedFilterCount }})
          </template>
        </button> -->
      </template>
    </template>
  </BaseDropdown>
</template>

<script>
  import BaseDropdown from "@/components/Inputs/BaseDropdown";
  import {
    mdiFilterVariant,
    mdiFoodForkDrink,
    mdiFoodTakeoutBox,
  } from "@mdi/js";
  import store from "@/store";
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import { mapGetters } from "vuex";
  import { getSectionIcon, getChannelIcon } from "../../helpers/iconGetters";

  export default {
    data() {
      return {
        loading: false,
        ifExpand: true,
        ifUpdated: false,
        serviceId: parseInt(this.$route.params.serviceId),
        availableMenuSections: null,
        availableChannels: null,
        optionsViewWidth: 400,
        selectedChannels: [],
        selectedSections: [],

        mdiFilterVariant,
        mdiFoodForkDrink,
        mdiFoodTakeoutBox,
      };
    },

    computed: {
      ...mapGetters({
        getAppliedCount: "filters/getAppliedCount",
        getEatIn: "filters/getEatIn",
        getTakeOut: "filters/getTakeOut",
      }),

      getSelectedFilterCount() {
        return (
          (this.selectedChannels ?? []).length +
          (this.selectedSections ?? []).length
        );
      },
    },

    props: {
      service: { default: {} },
    },

    watch: {
      "$route.params.serviceId": {
        handler: function () {
          this.clear();
        },
      },
    },

    methods: {
      formatChannel,

      async getMenuSection() {
        this.loading = true;
        this.ifUpdated = false;

        const serviceId = parseInt(this.$route.params.serviceId);

        if (!serviceId) return (this.loading = false);

        this.availableMenuSections = this.service.menu.sections
          .filter(({ availableVendor }) => availableVendor)
          .map((s) => ({ ...s, icon: getSectionIcon(s.name) }));

        this.availableChannels = this.service.channels.map((c) => ({
          ...c,
          icon: getChannelIcon(c.name),
        }));
        this.loading = false;
      },

      selectSection(s) {
        this.selectedSections ??= [];

        const index = this.selectedSections?.map(({ id }) => id).indexOf(s.id);

        if (index >= 0) {
          this.selectedSections.splice(index, 1);
        } else {
          this.selectedSections.push(s);
        }

        this.update();
      },

      selectChannel(c) {
        const index = this.selectedChannels?.map(({ id }) => id).indexOf(c.id);

        if (index >= 0) {
          this.selectedChannels.splice(index, 1);
        } else {
          this.selectedChannels.push(c);
        }

        this.update();
      },

      toggleEatIn() {
        store.dispatch("filters/toggleEatIn");
      },

      toggleTakeOut() {
        store.dispatch("filters/toggleTakeOut");
      },

      update() {
        store.dispatch("filters/update", {
          menuSections: this.selectedSections,
          channels: this.selectedChannels,
          eatIn: this.eatIn,
          takeOut: this.takeOut,
        });

        this.ifUpdated = true;
      },

      load() {
        try {
          const storedFilterOptions =
            window.localStorage.getItem("viewFilters");

          if (storedFilterOptions) {
            const options = JSON.parse(storedFilterOptions);

            this.selectedChannels = options.channels ?? [];
            this.selectedSections = options.menuSections ?? [];
            this.eatIn = options.eatIn ?? true;
            this.takeOut = options.takeOut ?? true;

            store.dispatch("filters/update", {
              menuSections: options.menuSections,
              channels: options.channels,
              eatIn: options.eatIn,
              takeOut: options.takeOut,
            });

            this.update();
          } else {
            this.clear();
          }
        } catch {
          this.clear();
        }
      },

      clear() {
        this.selectedChannels = [];
        this.selectedSections = [];

        store.dispatch("filters/update", {
          menuSections: [],
          channels: [],
          eatIn: true,
          takeOut: true,
        });
      },
    },

    mounted() {
      this.load();
    },

    components: { BaseDropdown },
  };
</script>

<style lang="scss">
  .section-filters {
    > .field {
      p {
        @include flex($jc: flex-start, $g: 0.25rem);
        padding: 0.5rem 0 !important;
        position: relative;
        overflow: visible !important;

        .applied-count {
          position: absolute;
          background: $col_alpha;
          color: white;
          font-size: 0.65rem;
          display: block;
          line-height: 1rem;
          min-width: 1rem;
          top: 0.15rem;
          right: 0;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
    > .options {
      right: calc(50% - v-bind("`${optionsViewWidth}px`") / 4);
      padding: 0.5rem !important;

      h4 {
        margin: unset;
        padding-left: 0.25rem;
      }
    }

    ul.filters {
      @include flex($jc: flex-start);
      li {
        @include flex($g: 0.15rem);
        background: $col_disabled_gray;
        padding: $input_padding;
        border-radius: $button_radius;
        font-size: 0.785rem;

        &.selected {
          background: black;
          color: white;
        }
      }
    }

    button.apply {
      width: 100%;
    }
  }
</style>
