<template>
  <div class="payment-picker">
    <div class="payment-picker__row">
      <button class="add" @click="addDigit(7)" :disabled="atMax || getCartLockByPaymentStatus">7</button>
      <button class="add" @click="addDigit(8)" :disabled="atMax || getCartLockByPaymentStatus">8</button>
      <button class="add" @click="addDigit(9)" :disabled="atMax || getCartLockByPaymentStatus">9</button>
    </div>
    <div class="payment-picker__row">
      <button class="add" @click="addDigit(4)" :disabled="atMax || getCartLockByPaymentStatus">4</button>
      <button class="add" @click="addDigit(5)" :disabled="atMax || getCartLockByPaymentStatus">5</button>
      <button class="add" @click="addDigit(6)" :disabled="atMax || getCartLockByPaymentStatus">6</button>
    </div>
    <div class="payment-picker__row">
      <button class="add" @click="addDigit(1)" :disabled="atMax || getCartLockByPaymentStatus">1</button>
      <button class="add" @click="addDigit(2)" :disabled="atMax || getCartLockByPaymentStatus">2</button>
      <button class="add" @click="addDigit(3)" :disabled="atMax || getCartLockByPaymentStatus">3</button>
    </div>
    <div class="payment-picker__row">
      <button class="clear" @click="clearDigits()" :disabled="getCartLockByPaymentStatus">C</button>
      <button class="add" @click="addDigit(0)" :disabled="atMax || getCartLockByPaymentStatus">0</button>
      <button class="add00" @click="addDigitDoubleO()" :disabled="atMax || getCartLockByPaymentStatus">
        00
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        value: 0,
      };
    },

    props: {
      modelValue: {
        type: Number,
      },
      maxValue: {
        type: Number,
      },
    },

    emits: ["update:modelValue"],

    watch: {
      modelValue: {
        handler: function () {
          let incoming = this.modelValue * 100;

          if (this.value != incoming) {
            this.value = incoming;
          }
        },
        deep: true,
        immediate: true,
      },
    },

    computed: {
      ...mapGetters({
        getCartLockByPaymentStatus: "payment/getLock",
      }),
      
      amount() {
        return this.value / 100;
      },

      atMax() {
        return this.maxValue != null && this.amount >= this.maxValue;
      },
    },

    methods: {
      clearDigits() {
        this.value = 0;

        this.emitAmount();
      },

      addDigit(digit) {
        this.value *= 10;
        this.value += digit;

        this.limitAmount();
        this.emitAmount();
      },

      addDigitDoubleO() {
        this.value *= 100;

        this.limitAmount();
        this.emitAmount();
      },

      limitAmount() {
        if (this.value < 0) {
          this.value = 0;
        }

        if (this.maxValue != null && this.amount > this.maxValue) {
          this.value = this.maxValue * 100;
        }
      },

      emitAmount() {
        this.$emit("update:modelValue", this.amount);
      },
    },

    mounted() {
      this.value = this.modelValue * 100;
    },
  };
</script>

<style lang="scss">
  .payment-picker {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &__row {
      display: flex;
      gap: 0.5em;

      button {
        @include contained-button;
        width: 72px;
        height: 72px;

        border: 0;
        border-radius: 8px;

        font-size: 1.4em;
      }

      button:active {
        background: $col_beta-lightest !important;
      }

      button.add {
        background: $col_beta !important;
         &:hover:not(:disabled) {
          background: $col_beta !important;
          color: black !important;
        }
      }

      button.clear,
      button.add00 {
        background: $col_beta-lighter !important;

        &:hover {
          background: $col_beta !important;
          color: black !important;
        }
      }
    }
  }
</style>
