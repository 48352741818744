<template>
  <p
    class="allergen allergen-label row"
    @click="this.toggleAllergenSection"
    v-if="this.amountToShown === 0">
    Allergens ({{ allergens.length || "-" }})
    <span
      class="allergens-toggle row"
      v-if="allergens?.length > this.amountToShown">
      <span class="material-icons expand-allergen">
        {{ !ifAllergenSectionExpanded ? "expand_more" : "expand_less" }}
      </span>
    </span>
  </p>
  <div
    class="row align-baseline allergens-wrap"
    @click="this.toggleAllergenSection">
    <p
      class="allergens row flex-wrap"
      :class="{ expanded: ifAllergenSectionExpanded }"
      :style="{ width: this.width }">
      <span
        class="row align-center"
        v-for="(allergen, index) in allergensOrderedByName"
        :class="{
          hide: index >= this.amountToShown && !ifAllergenSectionExpanded,
        }"
        v-bind:key="allergen?.id">
        <!-- this should be temp, id and name are not returned atm -->
        {{ allergen?.name }}
      </span>
    </p>

    <span
      class="allergens-toggle row"
      v-if="allergens?.length > this.amountToShown && this.amountToShown !== 0">
      <Icon
        :path="!ifAllergenSectionExpanded ? mdiChevronDown : mdiChevronUp"
        :size="20" />
    </span>
  </div>
</template>

<script>
  import store from "@/store";
  import Enumerable from "linq";
  import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

  export default {
    data() {
      return {
        ifAllergenSectionExpanded: false,
        allergens: [],
        mdiChevronDown,
        mdiChevronUp,
      };
    },
    props: {
      allergensProp: {
        type: Array,
      },
      amountToShown: {
        type: Number,
        default: 2,
      },
      width: {
        type: String,
        default: "140px",
      },
    },
    watch: {
      allergensProp: function () {
        this.getAllergensInfo();
      },
    },
    computed: {
      allergensOrderedByName() {
        const orderedArray = Enumerable.from(this.allergens)
          .orderBy((x) => x?.name) // ?. this should be fixed, because no data is returned from the current repo
          .toArray();

        return orderedArray;
      },
    },
    methods: {
      toggleAllergenSection() {
        if (this.allergens.length < this.amountToShown) return;

        this.ifAllergenSectionExpanded = !this.ifAllergenSectionExpanded;
      },
      getAllergensInfo() {
        const checkIfOnlyId = this.allergensProp.every(
          (o) => typeof o === "number"
        );
        if (checkIfOnlyId)
          for (const allergenId of this.allergensProp) {
            store.getters["repoAllergens/getById"](allergenId).then(
              (allergen) => {
                this.allergens.push(allergen);
              }
            );
          }
        else {
          this.allergens = this.allergensProp;
        }
      },
    },
    mounted() {
      this.getAllergensInfo();
    },
  };
</script>

<style lang="scss">
  .allergens-wrap {
    cursor: pointer;
  }
  p.allergens {
    font-size: 0.75rem;
    color: #999;
    margin-top: 0;
    max-height: 1rem; // showing 1 row only
    overflow: hidden;
    transition: 0.2s max-height;
    text-overflow: ellipsis;
    margin-bottom: 0;

    span:not(:first-child)::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #aaa;
      margin: 0 5px;
    }
    span.hide {
      display: none;
    }
  }
  span.allergens-toggle {
    align-items: center;
    font-size: 0.7rem;
    span.mdi-icon {
      font-size: 0.75rem;
    }
  }

  p.allergens.expanded {
    max-height: 200px;
    text-overflow: initial;
  }
  p.allergens.allergen-label {
    align-items: center;
  }
</style>
