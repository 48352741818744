<template>
  <div class="task-bar">
    <div class="task-bar__status-section">
      <DelayButtons
        :updatingStatus="updatingStatus"
        @delay="(duration) => $emit('delay', duration)" />
      <div class="task-bar__status-items">
        <div
          class="task-bar__status-item"
          v-for="(value, key, index) in validStatuses"
          :key="value.status + key">
          <button
            @click="this.$emit('updateStatus', value.status)"
            :disabled="
              updatingStatus ||
              !value.valid ||
              key.replace(/\s/g, '') === currentStatus
            "
            class="outlined"
            :class="{
              current: key.replace(/\s/g, '') === currentStatus,
              next: nextStatusIndex === index,
            }">
            <Icon :path="statusesIcon[value.status]" :size="24" />
            <span class="text">{{ value.status }}</span>
            <span
              class="whisper"
              v-if="key.replace(/\s/g, '') === currentStatus"
              >Current</span
            >
          </button>
          <p v-show="expanded">
            {{ statusDescriptions[value.status] }}
            <span
              class="reason"
              v-for="(reason, key) in value.reasons"
              :key="key"
              ><Icon :path="mdiAlert" :size="14" /> {{ reason }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="task-bar__toggle">
      <button class="toggle" @click="handleToggleExpand">
        <Icon :path="mdiChevronDown" :size="20" v-show="expanded" />
        <Icon :path="mdiInformationOutline" :size="20" v-show="!expanded" />
      </button>
      <button class="task-bar__close" @click="this.$emit('hideTaskBar')">
        <Icon :path="mdiClose" :size="20" />
      </button>
    </div>
  </div>
</template>

<script>
  import {
    mdiClipboardTextOutline,
    mdiChevronDown,
    mdiStove,
    mdiAccountClockOutline,
    mdiInformationOutline,
    mdiCheckUnderline,
    mdiClose,
    mdiAlert,
    mdiClockPlusOutline,
  } from "@mdi/js";
  import {
    PLACED,
    PRODUCING,
    READYFORCOLLECTION,
    COMPLETED,
  } from "@/constant/statuses.js";
  import DelayButtons from "./Taskbar/DelayButtons.vue";

  export default {
    data() {
      return {
        mdiClipboardTextOutline,
        mdiInformationOutline,
        mdiChevronDown,
        mdiClose,
        mdiAlert,
        mdiClockPlusOutline,
        expanded: false,
        statuses: {
          ClickCollect: [PLACED, PRODUCING, READYFORCOLLECTION, COMPLETED],
          ClickCollectAsap: [PLACED, PRODUCING, READYFORCOLLECTION, COMPLETED],
          WalkUp: [PLACED, PRODUCING, READYFORCOLLECTION, COMPLETED],
          Delivery: [PLACED, PRODUCING, READYFORCOLLECTION],
          Phone: [PLACED, PRODUCING, READYFORCOLLECTION, COMPLETED],
          TableService: [PLACED, PRODUCING, READYFORCOLLECTION, COMPLETED],
        },
        statusDescriptions: {
          [PLACED]:
            "Order/s have been placed by the customer and awaits production.",
          [PRODUCING]:
            "Order/s are now being produced. The customer status page will be updated to indicate this.",
          [READYFORCOLLECTION]:
            "Order/s are now ready to be collected. Communication is sent out to customers regarding this change.",
          [COMPLETED]:
            "Order/s have now been completed and no longer part of scheduled work.",
        },
        statusesIcon: {
          [PLACED]: mdiClipboardTextOutline,
          [PRODUCING]: mdiStove,
          [READYFORCOLLECTION]: mdiAccountClockOutline,
          [COMPLETED]: mdiCheckUnderline,
        },
        statusButtonMinWidth: "90px",
      };
    },
    props: {
      currentStatus: {
        type: String,
        default: "Placed",
      },
      updatingStatus: {
        type: Boolean,
      },
      tickets: { type: Array },
    },
    computed: {
      channels() {
        return [...new Set(this.tickets?.map((x) => x.channel))];
      },
      nextStatusIndex() {
        return (
          Object.keys(this.validStatuses)
            .map((s) => s.replace(/\s/g, ""))
            .indexOf(this.currentStatus) + 1
        );
      },
      containsMultipleUnpaid() {
        return (
          this.tickets.filter((x) => x.channel == "Phone" && x.paymentPending)
            .length > 1
        );
      },
      validStatuses() {
        if (!this.channels.length) {
          return [];
        }
        // If therer are multiple channels involved, firstly get a list of all possible statuses.
        let allStatuses = [];
        for (const channel of this.channels) {
          allStatuses.push(...this.statuses[channel]);
        }
        allStatuses = [...new Set(allStatuses)];
        // Now prep a lookyp of all statuses, specifying they are valid
        let statusValidLookup = {};
        for (const status of allStatuses) {
          statusValidLookup[status] = {
            status: status,
            valid: true,
            reasons: [],
          };
        }
        // Loop through each channel, if any of them do not include this status, block them off.
        for (const channel of this.channels) {
          const channelStatuses = this.statuses[channel];
          for (const status of allStatuses) {
            if (!channelStatuses.includes(status)) {
              statusValidLookup[status].valid = false;
              if (channel == "Delivery") {
                statusValidLookup[status].reasons.push(
                  "Contains a delivery order, which will be set to completed via the driver."
                );
              }
            }
          }
        }
        if (this.containsMultipleUnpaid) {
          statusValidLookup[COMPLETED].valid = false;
          statusValidLookup[COMPLETED].reasons.push(
            "Contains multiple unpaid phone orders. These must be moved to Completed and payment taken one by one."
          );
        }
        // Then remove the current status from the list.
        return statusValidLookup;
      },
    },
    emits: ["hideTaskBar", "updateStatus", "delay"],
    methods: {
      handleToggleExpand() {
        this.expanded = !this.expanded;
      },
    },
    components: { DelayButtons },
  };
</script>

<style lang="scss" scoped>
  .task-bar {
    @include flex(
      $dir: row,
      $g: 0.25rem,
      $jc: space-between,
      $wrap: nowrap,
      $ai: stretch
    );
    box-shadow: 0 10px 30px gray;
    z-index: 19;
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    border-top: 1px solid gray;

    &__toggle {
      @include flex($dir: column, $jc: flex-end);
      button {
        border: 0;
        transition: 0.2s transform;

        &:hover {
          background: none;
        }
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    &__close {
      border: none;
      width: auto;
    }

    &__status-section {
      @include flex;
      flex: 1;
      flex-wrap: nowrap;

      p {
        max-height: 100px;
        font-size: 0.65rem;

        .reason {
          display: block;
          color: $col_urgent;

          .mdi-icon {
            float: left;
            margin-right: 4px;
          }
        }
      }
    }

    &__status-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      flex: 1;
      padding: 0.5rem;
    }

    &__status-item {
      flex: 1;
      min-width: v-bind("statusButtonMinWidth");
      @include flex($dir: column, $jc: flex-start);

      button {
        min-width: v-bind("statusButtonMinWidth");
        position: relative;
        overflow: hidden;

        @include button-base($fs: 1.2rem, $h: 100px, $p: 0.5rem, $w: 100%);
        flex-direction: column !important;
        box-shadow: 0 0 0.5rem $col_disabled;
        border: 0;

        .text {
          font-size: 1rem;
        }

        span.whisper {
          display: inline-block;
          font-size: 0.75rem;
          position: absolute;
          bottom: 0;
          width: 100%;
          color: white;
          background: rgba($color: $col_alpha-lighter, $alpha: 1);
        }

        &.next {
          @include contained-button(
            $fs: 1.2rem,
            $h: 100px,
            $p: 0.5rem,
            $w: 100%,
            $bg: $col_beta,
            $col: white
          );
        }

        &.current {
          @include contained-button(
            $fs: 1.2rem,
            $h: 100px,
            $p: 0.25rem 0.5rem 1.5rem,
            $w: 100%,
            $col: black
          );
        }
      }

      @media screen and (max-width: $vertical_tablet_width) {
        gap: 0.25rem;

        button {
          height: 60px;
          font-size: 1rem;
          min-width: auto;
          max-width: initial;
          padding: 0.25rem;
        }
      }
    }

    @media screen and (max-width: $tablet_min_width) {
      &__status-items {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (max-width: $mobile_max_width) {
      &__status-section {
        flex-direction: column;
        gap: 0;
      }
    }
  }
</style>
