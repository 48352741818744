<template>
  <p>{{ text }}</p>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: "No Data",
      },
    },
  };
</script>

<style lang="scss" scoped>
  p {
    color: #aaa;
    font-style: italic;
  }
</style>
