<template>
  <div class="context-display__wrapper">
    <div
      @click="togglable ? (openDropdown = !openDropdown) : null"
      class="context-display">
      <Icon :path="iconPath" :size="32" />
    </div>
    <Dropdown
      width="260px"
      v-show="openDropdown"
      :openDropdown="openDropdown"
      @close="this.openDropdown = false">
      <slot name="dropdown"> </slot>
    </Dropdown>
  </div>
</template>

<script>
  import Dropdown from "@/components/TopBar/Dropdown.vue";
  import { mdiChevronDown } from "@mdi/js";
  export default {
    data() {
      return { openDropdown: false, mdiChevronDown };
    },
    components: { Dropdown },
    props: {
      dropdownWidth: { type: String, default: "fit-content" },
      togglable: { type: Boolean, default: true },
      iconPath: { type: String },
    },
  };
</script>

<style lang="scss">
  .context-display {
    &__wrapper {
      position: relative;
    }
    p {
      font-size: 0.85rem;
      font-weight: 500;
      @include flex($ai: center);
    }

    @include flex($ai: center);

    cursor: pointer;
  }
</style>
